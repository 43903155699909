// import React, { useEffect, useState } from "react";
// import {
//   FaBars,
//   FaSpellCheck,
//   FaBuilding,
//   FaHospitalUser,
//   FaUserFriends,
//   FaBroadcastTower,
//   FaReceipt,
//   FaBed,
//   FaUserCog,
//   FaHourglassHalf,
//   FaUser,
//   FaChartBar,
//   FaFileCode,
//   FaTachometerAlt,
//   FaAsymmetrik,
//   FaWeixin,
//   FaStopwatch,
// } from "react-icons/fa";
// import { NavLink, useLocation, useNavigate } from "react-router-dom";
// import "./sidebar.css";
// import Header from "../header";
// import { useDispatch, useSelector } from "react-redux";
// import { ListItemIcon, MenuItem } from "@mui/material";
// import { Logout } from "@mui/icons-material";
// import LogoutConfirmationModal from "../LogoutModel";
// import { handleLogout } from "../../slices/thunk";
// import { white } from "../../common/primary";

// interface SidebarProps {
//   children: any;
// }

// const ResizeHandler = ({ setIsOpen }: { setIsOpen: React.Dispatch<React.SetStateAction<boolean>> }) => {
//   useEffect(() => {
//     const handleResize = () => {
//       setIsOpen(window.innerWidth > 1068);
//     };

//     window.addEventListener("resize", handleResize);

//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   return null;
// };

// const Sidebar = (props: SidebarProps) => {
//   const [open, setOpen] = useState(false);
//   const navigate = useNavigate()
//   const dispatch = useDispatch<any>()
//   const { userType, organization, jwt } = useSelector((state: any) => state.Login);
//   const username = useSelector((state: any) => state.Login.userDetails);
//   const { orgData } = useSelector((state: any) => state.Org);
//   const [activeSubmenu, setActiveSubmenu] = useState<number | null>(null);
//   const { children } = props;
//   const [isOpen, setIsOpen] = useState(window.innerWidth > 768);
//   const location = useLocation();
//   const isLoginPage = location.pathname === '/login';
//   const isSecretKeyPage = location.pathname === '/secret-key';
//   const isForgotPage = location.pathname === '/forgot-password';
//   const ischangePage = location.pathname === '/change-password';
//   const isResetSecret = location.pathname === '/resetSecretKey';
//   const isRecreatePass = location.pathname === '/recreatePassword';
//   const isotp = location.pathname === '/verify-otp';
//   const patient = location.pathname === '/patient-view';
//   const staffView = location.pathname === '/staff-view';
//   const unblock = location.pathname === '/account-unblock';
//   const patientView = patient || staffView
//   const showSideBar = !isLoginPage && !unblock && !isSecretKeyPage && !isForgotPage && !isResetSecret && !isRecreatePass && !ischangePage && !isotp;

//   if (!showSideBar) {
//     return null;
//   }

//   const toggle = () => setIsOpen(!isOpen);

//   const baseMenu = [
//     {
//       path: "/organization-details",
//       name: "Master Organization",
//       icon: <FaBuilding />,
//       show: userType === "Super Admin",
//     },
//     (orgData?.q15Access === 'No' && orgData?.sos ?
//       ({
//         path: "/staff-view",
//         name: "Dashboard",
//         icon: <FaTachometerAlt />,
//         show: userType === "Admin" || userType === 'Receptionist-sos',
//         newTab: true,
//         fullScreen: true
//       }) :
//     ({
//       path: "/patient-view",
//       name: "Dashboard",
//       icon: <FaTachometerAlt />,
//       show: userType === "Admin" || userType === 'Receptionist-Q15',
//       newTab: true,
//       fullScreen: true
//     })),
//     {
//       path: `/organization-update/${organization}`,
//       name: "Organization Details",
//       icon: <FaBuilding />,
//       show: userType === "System Admin",
//     },
//     {
//       path: "/staff-table",
//       name: "Staff Details",
//       icon: <FaHospitalUser />,
//       show: userType === "System Admin",
//     },
//     {
//       path: "/beacon-table",
//       name: "Beacon Devices",
//       icon: <FaBroadcastTower />,
//       show: userType === "System Admin",
//     },
//   ];

//   const menu = [
    
//     {
//       path: "/staff-configuration",
//       name: "SOS Staff",
//       icon: <FaFileCode />,
//       show: userType === "Admin" || userType === "Receptionist-sos",
//     },
//     {
//       path: "/sos-Report",
//       name: "SOS Call",
//       icon: <FaFileCode />,
//       show: userType === "Admin",
//     },
//   ]

//   const q15AccessMenu = [
//     {
//       name: "Patient & Bed",
//       icon: <FaUserCog />,
//       show: userType === "Admin",
//       submenu: [
//         {
//           path: "/all-patient-table",
//           name: "All Patient",
//           icon: <FaUserFriends />,
//           show: userType === "Admin",
//         },
//         {
//           path: "/patient-table",
//           name: "Active Patient",
//           icon: <FaUser />,
//           show: userType === "Admin",
//         },
//         {
//           path: "/bed-table",
//           name: "Bed",
//           icon: <FaBed />,
//           show: userType === "Admin",
//         }
//       ],
//     },
//     {
//       name: "Staff Assignment",
//       icon: <FaAsymmetrik />,
//       show: userType === "Admin",
//       submenu: [
//         {
//           path: "/q15-staff-configuration",
//           name: "Q15 Staff Assignment",
//           icon: <FaSpellCheck />,
//           show: userType === "Admin",
//         },
//         {
//           path: "/One-To-One",
//           name: "One to One",
//           icon: <FaWeixin />,
//           show: userType === "Admin",
//         }
//       ]
//     },
//   ];
//   const menu1 = [
//     {
//       path: "/add-bed-table",
//       name: "Bed Master Configuration",
//       icon: <FaBed />,
//       show: userType === "System Admin",
//     },
//     {
//       path: "/q15-slot-assign",
//       name: "Verify Q15 Routine",
//       icon: <FaHourglassHalf />,
//       show: userType === "Admin" || userType === "Staff",
//     },
//     {
//       path: "/q15-report",
//       name: "Q15 Report",
//       icon: <FaReceipt />,
//       show: userType === "Admin" || userType === "Staff",
//     },
//     {
//       path: "/one-to-one-report",
//       name: "One To One Report",
//       icon: <FaReceipt />,
//       show: userType === "Admin" || userType === "Staff",
//     },
//     // {
//     //   path: "/restraint-seculation",
//     //   name: "Restraint/Seculation Order",
//     //   icon: <FaNewspaper />,
//     //   show: userType === "Admin" || userType === "Staff",
//     // },
//     {
//       path: "/q15-report-view",
//       name: "Q15 Summary",
//       icon: <FaChartBar />,
//       show: userType === "Admin" || userType === "Staff",
//     },
//   ]
//   const sos1 = [
//     {
//       path: "/sos-staff",
//       name: "Staff SOS Assignment",
//       icon: <FaStopwatch />,
//       show: userType === "Staff",
//     },
//   ]
//   const Receptionist = [
//     {
//       path: "/staff-view",
//       name: "Dashboard",
//       icon: <FaTachometerAlt />,
//       show: userType === "Receptionist-sos",
//       newTab: true,
//       fullScreen: true
//     },
//   ]

//   const menuItem = (orgData?.q15Access === 'Yes' && orgData?.sos || orgData?.q15Access === 'Yes' && !orgData?.sos)
//     ? [ ...Receptionist, ...baseMenu, ...q15AccessMenu, ...menu, ...menu1, ...sos1 ]
//     : (orgData?.q15Access === 'No' && orgData?.sos)
//       ? [...baseMenu, ...menu, ...sos1, ...Receptionist]
//       : [];

//   const handleSubMenuClick = (index: number) => {
//     setActiveSubmenu(prevActiveSubmenu => prevActiveSubmenu === index ? null : index);
//   };

//   const currentPageName = menuItem.find((item: any) => item.path === location.pathname)?.name;
//   const subPageName = menuItem.reduce((subname: any, item: any) => {
//     if (item.submenu) {
//       const subItem = item.submenu.find((sub: any) => sub.path === location.pathname);
//       if (subItem) {
//         subname = subItem.name;
//       }
//     }
//     return subname;
//   }, '');

//   const handleLogoutClick = () => {
//     setOpen(true);
//   };

//   const handleConfirmLogout = () => {
//     const body = { jwt, username };
//     handleLogout(body, navigate, dispatch);
//   };
//   const handleClo = () => {
//     setOpen(false)
//   }

//   return (
//     <>
//       {showSideBar && (
//         <div className="container1">
//           <div style={{ width: isOpen ? "230px" : "60px", display: patientView ? 'none' : 'flex', justifyContent: 'space-between', flexDirection: 'column' }} className="sidebar">
//            <div>
//            <div className="top_section" style={{ justifyContent: isOpen ? 'space-between' : 'center', height: '55px', borderBottom: '2px solid #0f3995' }}>
//               <h1 style={{ display: isOpen ? "block" : "none", fontSize: '18px', marginTop: '5px' }} className="logo">
//                 M H C
//               </h1>
//               <div style={{ marginLeft: isOpen ? "65px" : "0px", fontSize: '18px' }} className="bars">
//                 <FaBars onClick={toggle} style={{ cursor: 'pointer' }} />
//               </div>
//             </div>
//             {menuItem.map((item: any, index: any) =>
//             item.show ? (
//               <React.Fragment key={index}>
//                 {item.submenu ? (
//                   <div
//                     onClick={() => handleSubMenuClick(index)}
//                     className="link"
//                     style={{ justifyContent: isOpen ? 'left' : 'center' }}
//                   >
//                     <div className="icon" style={{ fontSize: '15px' }}>{item.icon}</div>
//                     <div className="link_text" style={{ display: isOpen ? "block" : "none", fontSize: '13px', position: 'relative' }}>
//                       {item.name}
//                     </div>
//                   </div>
//                 ) : (
//                   <NavLink to={item.path} key={index} className="link"
//                     target={item.newTab ? "_new" : "_self"}
//                     rel={item.newTab ? "noopener noreferrer" : ""}
//                     style={{ justifyContent: isOpen ? 'left' : 'center' }}
//                     // onClick={() =>{
//                     //   if (item.newTab) {
//                     //     localStorage.setItem('jwt', jwt)
//                     //     localStorage.setItem('authStaff', 'verified')
//                     //   }
//                     // }}
//                   >
//                     <div className="icon" style={{ fontSize: '15px' }}>{item.icon}</div>
//                     <div className="link_text" style={{ display: isOpen ? "block" : "none", fontSize: '13px', position: 'relative' }}>
//                       {item.name}
//                     </div>
//                   </NavLink>
//                 )}
//                 {
//                   activeSubmenu === index &&
//                   item.submenu &&
//                   item.submenu.length > 0 && (
//                     <div className="submenu" style={{ marginLeft: isOpen ? '30px' : '12px' }}>
//                       {item.submenu.map((subItem: any, subIndex: any) => (
//                         <NavLink
//                           to={subItem.path}
//                           key={subIndex}
//                           className="link"
//                           target={subItem.newTab ? "_new" : "_self"}
//                           rel={subItem.newTab ? "noopener noreferrer" : ""}
//                         >
//                           <div className="icon" style={{ fontSize: '15px' }}>
//                             <span>{subItem.icon}</span>
//                           </div>
//                           <div className="link_text" style={{ display: isOpen ? "block" : "none", fontSize: '13px', position: 'relative', top: '4px' }}>
//                             {subItem.name}
//                           </div>
//                         </NavLink>
//                       ))}
//                     </div>
//                   )}
//               </React.Fragment>
//             ) : null
//           )}
//            </div>
//           <div>
//             {
//               isOpen ? 
//               <MenuItem style={{justifyContent: 'center', backgroundColor: white, color: '#FF0000', margin: '20px', borderRadius: '6px'}} onClick={handleLogoutClick}>
//                 <ListItemIcon>
//                   <Logout fontSize="small" style={{color: '#FF0000'}} />
//                 </ListItemIcon>
//                 Logout
//               </MenuItem>
//               :
//               <MenuItem style={{justifyContent: 'center', backgroundColor: white, color: '#FF0000', margin: '10px', borderRadius: '6px'}} onClick={handleLogoutClick}>
//               <ListItemIcon style={{justifyContent: 'center'}}>
//                 <Logout fontSize="small" style={{color: '#FF0000'}} />
//               </ListItemIcon>
//             </MenuItem>
//             }
          
//           </div>
//           <LogoutConfirmationModal
//         open={open}
//         handleConfirm={() => handleConfirmLogout()}
//         handleClose={handleClo}
//         message="Are you sure you want to logout?"
//       />
//           </div>
//           <div
//             className="w-100"
//             style={{
//               marginLeft: patientView ? '0px' : (isOpen ? "230px" : "66px"),
//               marginTop: 0,
//               overflowY: "auto",
//             }}
//           >
//             <Header currentPage={currentPageName || ""} subPage={subPageName} isSidebarOpen={isOpen} />
//             {children}
//           </div>
//         </div>
//       )}
//       <ResizeHandler setIsOpen={setIsOpen} />
//     </>
//   );
// };

// export default Sidebar;
import React, { useEffect, useState } from "react";
import {
  FaBars,
  FaSpellCheck,
  FaBuilding,
  FaHospitalUser,
  FaUserFriends,
  FaBroadcastTower,
  FaReceipt,
  FaBed,
  FaUserCog,
  FaHourglassHalf,
  FaUser,
  FaChartBar,
  FaFileCode,
  FaTachometerAlt,
  FaAsymmetrik,
  FaWeixin,
  FaStopwatch,
} from "react-icons/fa";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import "./sidebar.css";
import Header from "../header";
import { useDispatch, useSelector } from "react-redux";
import { ListItemIcon, MenuItem } from "@mui/material";
import { Logout } from "@mui/icons-material";
import LogoutConfirmationModal from "../LogoutModel";
import { handleLogout } from "../../slices/thunk";
import { white } from "../../common/primary";

interface SidebarProps {
  children: any;
}

const ResizeHandler = ({ setIsOpen }: { setIsOpen: React.Dispatch<React.SetStateAction<boolean>> }) => {
  useEffect(() => {
    const handleResize = () => {
      setIsOpen(window.innerWidth > 1068);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return null;
};

const Sidebar = (props: SidebarProps) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate()
  const dispatch = useDispatch<any>()
  const { userType, organization, jwt } = useSelector((state: any) => state.Login);
  const username = useSelector((state: any) => state.Login.userDetails);
  const { orgData } = useSelector((state: any) => state.Org);
  const [activeSubmenu, setActiveSubmenu] = useState<number | null>(null);
  const { children } = props;
  const [isOpen, setIsOpen] = useState(window.innerWidth > 768);
  const location = useLocation();
  const isLoginPage = location.pathname === '/login';
  const isSecretKeyPage = location.pathname === '/secret-key';
  const isForgotPage = location.pathname === '/forgot-password';
  const ischangePage = location.pathname === '/change-password';
  const isResetSecret = location.pathname === '/resetSecretKey';
  const isRecreatePass = location.pathname === '/recreatePassword';
  const isotp = location.pathname === '/verify-otp';
  const patient = location.pathname === '/patient-view';
  const staffView = location.pathname === '/staff-view';
  const unblock = location.pathname === '/account-unblock';
  const patientView = patient || staffView
  const showSideBar = !isLoginPage && !unblock && !isSecretKeyPage && !isForgotPage && !isResetSecret && !isRecreatePass && !ischangePage && !isotp;

  if (!showSideBar) {
    return null;
  }

  const toggle = () => setIsOpen(!isOpen);

  const baseMenu = [
    {
      path: "/organization-details",
      name: "Master Organization",
      icon: <FaBuilding />,
      show: userType === "Super Admin",
    },
    (orgData?.q15Access === 'No' && orgData?.sos ?
      ({
        path: "/staff-view",
        name: "Dashboard",
        icon: <FaTachometerAlt />,
        show: userType === "Admin" || userType === 'Receptionist-sos',
        newTab: true,
        fullScreen: true
      }) :
    ({
      path: "/patient-view",
      name: "Dashboard",
      icon: <FaTachometerAlt />,
      show: userType === "Admin" || userType === 'Receptionist-Q15',
      newTab: true,
      fullScreen: true
    })),
    {
      path: `/organization-update/${organization}`,
      name: "Organization Details",
      icon: <FaBuilding />,
      show: userType === "System Admin",
    },
    {
      path: "/staff-table",
      name: "Staff Details",
      icon: <FaHospitalUser />,
      show: userType === "System Admin",
    },
    {
      path: "/beacon-table",
      name: "Beacon Devices",
      icon: <FaBroadcastTower />,
      show: userType === "System Admin",
    },
  ];

  const menu = [
    
    {
      path: "/staff-configuration",
      name: "SOS Staff",
      icon: <FaFileCode />,
      show: userType === "Admin" || userType === "Receptionist-sos",
    },
    {
      path: "/sos-Report",
      name: "SOS Call",
      icon: <FaFileCode />,
      show: userType === "Admin",
    },
  ]

  const q15AccessMenu = [
    {
      name: "Patient & Bed",
      icon: <FaUserCog />,
      show: userType === "Admin",
      submenu: [
        {
          path: "/all-patient-table",
          name: "All Patient",
          icon: <FaUserFriends />,
          show: userType === "Admin",
        },
        {
          path: "/patient-table",
          name: "Active Patient",
          icon: <FaUser />,
          show: userType === "Admin",
        },
        {
          path: "/bed-table",
          name: "Bed",
          icon: <FaBed />,
          show: userType === "Admin",
        }
      ],
    },
    {
      name: "Staff Assignment",
      icon: <FaAsymmetrik />,
      show: userType === "Admin",
      submenu: [
        {
          path: "/q15-staff-configuration",
          name: "Q15 Staff Assignment",
          icon: <FaSpellCheck />,
          show: userType === "Admin",
        },
        {
          path: "/One-To-One",
          name: "One to One",
          icon: <FaWeixin />,
          show: userType === "Admin",
        }
      ]
    },
  ];
  const menu1 = [
    {
      path: "/add-bed-table",
      name: "Bed Master Configuration",
      icon: <FaBed />,
      show: userType === "System Admin",
    },
    {
      path: "/q15-slot-assign",
      name: "Verify Q15 Routine",
      icon: <FaHourglassHalf />,
      show: userType === "Admin" || userType === "Staff",
    },
    {
      path: "/q15-report",
      name: "Q15 Report",
      icon: <FaReceipt />,
      show: userType === "Admin" || userType === "Staff",
    },
    {
      path: "/one-to-one-report",
      name: "One To One Report",
      icon: <FaReceipt />,
      show: userType === "Admin" || userType === "Staff",
    },
    // {
    //   path: "/restraint-seculation",
    //   name: "Restraint/Seculation Order",
    //   icon: <FaNewspaper />,
    //   show: userType === "Admin" || userType === "Staff",
    // },
    {
      path: "/q15-report-view",
      name: "Q15 Summary",
      icon: <FaChartBar />,
      show: userType === "Admin" || userType === "Staff",
    },
  ]
  const sos1 = [
    {
      path: "/sos-staff",
      name: "Staff SOS Assignment",
      icon: <FaStopwatch />,
      show: userType === "Staff",
    },
  ]
  const Receptionist = [
    {
      path: "/staff-view",
      name: "Dashboard",
      icon: <FaTachometerAlt />,
      show: userType === "Receptionist-sos" && orgData?.q15 && orgData.proximity && orgData.sos,
      newTab: true,
      fullScreen: true
    }
  ];
  
  const menuItem = (orgData?.q15Access === 'Yes' && orgData?.sos || orgData?.q15Access === 'Yes' && !orgData?.sos)
    ? [ ...Receptionist, ...baseMenu, ...q15AccessMenu, ...menu, ...menu1, ...sos1 ]
    : (orgData?.q15Access === 'No' && orgData?.sos)
      ? [...baseMenu, ...menu, ...sos1, ...Receptionist]
      : [];

  const handleSubMenuClick = (index: number) => {
    setActiveSubmenu(prevActiveSubmenu => prevActiveSubmenu === index ? null : index);
  };

  const currentPageName = menuItem.find((item: any) => item.path === location.pathname)?.name;
  const subPageName = menuItem.reduce((subname: any, item: any) => {
    if (item.submenu) {
      const subItem = item.submenu.find((sub: any) => sub.path === location.pathname);
      if (subItem) {
        subname = subItem.name;
      }
    }
    return subname;
  }, '');

  const handleLogoutClick = () => {
    setOpen(true);
  };

  const handleConfirmLogout = () => {
    const body = { jwt, username };
    handleLogout(body, navigate, dispatch);
  };
  const handleClo = () => {
    setOpen(false)
  }

  return (
    <>
      {showSideBar && (
        <div className="container1">
          <div style={{ width: isOpen ? "230px" : "60px", display: patientView ? 'none' : 'flex', justifyContent: 'space-between', flexDirection: 'column' }} className="sidebar">
           <div>
           <div className="top_section" style={{ justifyContent: isOpen ? 'space-between' : 'center', height: '55px', borderBottom: '2px solid #0f3995' }}>
              <h1 style={{ display: isOpen ? "block" : "none", fontSize: '18px', marginTop: '5px' }} className="logo">
                M H C
              </h1>
              <div style={{ marginLeft: isOpen ? "65px" : "0px", fontSize: '18px' }} className="bars">
                <FaBars onClick={toggle} style={{ cursor: 'pointer' }} />
              </div>
            </div>
            {menuItem.map((item: any, index: any) =>
            item.show ? (
              <React.Fragment key={index}>
                {item.submenu ? (
                  <div
                    onClick={() => handleSubMenuClick(index)}
                    className="link"
                    style={{ justifyContent: isOpen ? 'left' : 'center' }}
                  >
                    <div className="icon" style={{ fontSize: '15px' }}>{item.icon}</div>
                    <div className="link_text" style={{ display: isOpen ? "block" : "none", fontSize: '13px', position: 'relative' }}>
                      {item.name}
                    </div>
                  </div>
                ) : (
                  <NavLink to={item.path} key={index} className="link"
                    target={item.newTab ? "_new" : "_self"}
                    rel={item.newTab ? "noopener noreferrer" : ""}
                    style={{ justifyContent: isOpen ? 'left' : 'center' }}
                    // onClick={() =>{
                    //   if (item.newTab) {
                    //     localStorage.setItem('jwt', jwt)
                    //     localStorage.setItem('authStaff', 'verified')
                    //   }
                    // }}
                  >
                    <div className="icon" style={{ fontSize: '15px' }}>{item.icon}</div>
                    <div className="link_text" style={{ display: isOpen ? "block" : "none", fontSize: '13px', position: 'relative' }}>
                      {item.name}
                    </div>
                  </NavLink>
                )}
                {
                  activeSubmenu === index &&
                  item.submenu &&
                  item.submenu.length > 0 && (
                    <div className="submenu" style={{ marginLeft: isOpen ? '30px' : '12px' }}>
                      {item.submenu.map((subItem: any, subIndex: any) => (
                        <NavLink
                          to={subItem.path}
                          key={subIndex}
                          className="link"
                          target={subItem.newTab ? "_new" : "_self"}
                          rel={subItem.newTab ? "noopener noreferrer" : ""}
                        >
                          <div className="icon" style={{ fontSize: '15px' }}>
                            <span>{subItem.icon}</span>
                          </div>
                          <div className="link_text" style={{ display: isOpen ? "block" : "none", fontSize: '13px', position: 'relative', top: '4px' }}>
                            {subItem.name}
                          </div>
                        </NavLink>
                      ))}
                    </div>
                  )}
              </React.Fragment>
            ) : null
          )}
           </div>
          <div>
            {
              isOpen ? 
              <MenuItem style={{justifyContent: 'center', backgroundColor: white, color: '#FF0000', margin: '20px', borderRadius: '6px'}} onClick={handleLogoutClick}>
                <ListItemIcon>
                  <Logout fontSize="small" style={{color: '#FF0000'}} />
                </ListItemIcon>
                Logout
              </MenuItem>
              :
              <MenuItem style={{justifyContent: 'center', backgroundColor: white, color: '#FF0000', margin: '10px', borderRadius: '6px'}} onClick={handleLogoutClick}>
              <ListItemIcon style={{justifyContent: 'center'}}>
                <Logout fontSize="small" style={{color: '#FF0000'}} />
              </ListItemIcon>
            </MenuItem>
            }
          
          </div>
          <LogoutConfirmationModal
        open={open}
        handleConfirm={() => handleConfirmLogout()}
        handleClose={handleClo}
        message="Are you sure you want to logout?"
      />
          </div>
          <div
            className="w-100"
            style={{
              marginLeft: patientView ? '0px' : (isOpen ? "230px" : "66px"),
              marginTop: 0,
              overflowY: "auto",
            }}
          >
            <Header currentPage={currentPageName || ""} subPage={subPageName} isSidebarOpen={isOpen} />
            {children}
          </div>
        </div>
      )}
      <ResizeHandler setIsOpen={setIsOpen} />
    </>
  );
};

export default Sidebar;
