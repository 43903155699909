import React, { useEffect, useRef, useState } from "react";
import Calendar from "../../components/calendar";
import './staffconfig.css'
import { useDispatch, useSelector } from "react-redux";
import { BrowserMultiFormatReader } from "@zxing/library";
import { toast } from 'react-toastify';
import axios from 'axios';
import ErrorPopup from "../../components/errorPopup";
import { closeErrorPopup, getPatientgetSuccess } from "../../slices/staffConfiguration/reducer";
import { getOrgByID } from "../../slices/organization/thunk";
import { HttpLogin } from "../../utils/Http";
import { Button } from "primereact/button";
import Modal from 'react-bootstrap/Modal';
import { baseURL, successCode } from "../../configuration/url";
import calendarMuiImage from '../../assets/images/calendarMuiImage.svg';
import { authorize, formatDate1 } from "../../helpers/common";
import { getAllStaff, getPatientDataByDate } from "../../slices/thunk";
import { Autocomplete, Avatar, List, ListItem, MenuItem, Select, TextField, Tooltip } from "@mui/material";
import { FaQrcode } from "react-icons/fa";
import { Table } from "reactstrap";
import { primarybg, primarytext } from "../../common/primary";
import LogoutConfirmationModal from "../../components/LogoutModel";
import { Paginator } from 'primereact/paginator'
// import moment from "moment";
import moment from 'moment-timezone';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { getStaffSuccess } from "../../slices/staff/reducer";
import Loader from "../../components/loader/Loader";
import { format } from "path";

const StaffConfigure = () => {

    const dispatch = useDispatch<any>()
    const { isOpen, errorMsg, AllpatientData, totalElements, loading } = useSelector((state: any) => state.PSConfig)
    const { organization } = useSelector((state: any) => state.Login)
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [options, setOptions] = useState<any>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [deviceId, setDeviceId] = useState('')
    const [dropdownData, setDropdownData] = useState([])
    const { staffData, loading: staffLoading } = useSelector((state: any) => state.Staff);
    const [location, setLocation] = useState('');
    const formatDate = (date: any) => {
        const options = { day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    };
    let [newIndex, setNewIndex] = useState<any>('');
    let [newEvent, setNewEvent] = useState<any>('');
    let [newRegister, setNewRegister] = useState<any>('');
    const [confirmModal, setConfirmModal] = useState<boolean>(false)
    const [confirmMessage, setConfirmMessage] = useState<string>('')
    // const [currentPage, setCurrentPage] = useState(0);
    const [currentDate, setCurrentDate] = useState<any>()
    const [visitorModal, setVisitorModal] = useState<boolean>(false)
    const [visitorName, setVisitorName] = useState<any>('')
    const [startTime, setStartTime] = useState<any>('')
    const [activeModal, setActiveModal] = useState<boolean>(false)
    const [activeDetail, setActiveDetail] = useState<any>()
    const [activeMessage, setActiveMessage] = useState<any>('')
    const [ltrigger, setLtigger] = useState<string>('trigger')
    const [ltime, setLtime] = useState<any>('')
    const [ldate, setLdate] = useState<any>()
    console.log("ldate",ldate)
    const [shiftDuration, setShiftDuration] = useState<any>('')
    const [Loading, setLoading] = useState<boolean>(false)
    const {orgTimeZone} = useSelector((state: any) => state.LargeScreen)

    let addSlotRegister1 = [
        {
            staff: [
                {
                    deviceId: "",
                    registeredTime: "",
                    staffId: ""
                }
            ]
        }
    ]
    const [slotRegister, setSlotRegister1] = useState(new Array<any>(addSlotRegister1));


    const renderDateBoxes = () => {
        const dateBoxes = [];
        // const timezone = 'Pacific/Tongatapu'; 
    
        if (!selectedDate || isNaN(selectedDate?.getTime())) {
            console.error('Invalid selectedDate:', selectedDate);
            return [];
        }
    
        const selectedMoment = moment.tz(selectedDate, orgTimeZone);
    
        for (let i = -3; i < 4; i++) {
            const currentMoment = selectedMoment.clone().add(i, 'days');  
            const date = currentMoment.date(); 
            const year = currentMoment.year();
            const month = (currentMoment.month() + 1).toString().padStart(2, '0');
    
            window.localStorage.setItem("getByDate", `${year}${month}${date}`);
    
            dateBoxes.push(
                <Calendar
                    key={i}
                    day={currentMoment.format('ddd')} // Format for weekday name (e.g., 'Mon')
                    date={date} // Pass the day as a number
                    onClick={() => setSelectedDate(currentMoment.toDate())} // Set selected date
                    isSelected={selectedMoment.isSame(currentMoment, 'day')} // Compare the days
                />
            );
        }
        return dateBoxes;
    };
    
    

    const closePopup = () => {
        dispatch(closeErrorPopup())
    }

    const videoRef = useRef(null);
    const codeReader = new BrowserMultiFormatReader();
    const [scanning, setScanning] = useState(false);

    const [currentPage, setCurrentPage] = useState(0);
    const shiftDurationHour = [
        { 'id': '01:00', 'value': '01:00' },
        { 'id': '02:00', 'value': '02:00' },
        { 'id': '03:00', 'value': '03:00' },
        { 'id': '04:00', 'value': '04:00' },
        { 'id': '05:00', 'value': '05:00' },
        { 'id': '06:00', 'value': '06:00' },
        { 'id': '07:00', 'value': '07:00' },
        { 'id': '08:00', 'value': '08:00' }
    ]

    useEffect(() => {
        getAllStaff(dispatch, organization, -1, searchTerm ? searchTerm : '');
        getOrgByID(dispatch, organization)

    }, [dispatch, selectedDate, searchTerm])

    useEffect(() => {
        const handlestartTime = () => {
            const crtime: any = localStorage.getItem('LTime')
            console.log("crt currentime",crtime)
            if (crtime) {
                setStartTime(moment(crtime).format('HH:mm'));
            }
        }
        handlestartTime();
    }, [])
    const [value, setValue] = React.useState<any>();
    
    useEffect(() => {
        const handleStartTime = () => {
            const crtime: any = localStorage.getItem('LTime')
            const currentDate = new Date(crtime);
            setValue(currentDate)
            if (crtime) {
                const currentDate = new Date(crtime);
                const formattedDate = moment(currentDate).format('MM/DD/YYYY');
                setValue(currentDate);
                setLdate(formattedDate);
         
            }
        }
        const inter = setInterval(handleStartTime, 1000)
        return () => {
            dispatch(getPatientgetSuccess([]))
            dispatch(getStaffSuccess([]))
        }
    }, [])
    useEffect(() => {
        if (value) {
            setSelectedDate(value)
        }
    }, [])
    useEffect(() => {
        if (!ltime) return;
        setValue(dayjs(ltime));
    }, [ltime]);

    useEffect(() => {
        if (staffData.length === 0) {
            setVisitorModal(true)
        } else {
            setVisitorModal(false)
        }


    }, [staffData])
    const date1 = selectedDate?.getDate().toString().padStart(2, '0');
    const year = selectedDate?.getFullYear();
    const month = (selectedDate?.getMonth() + 1).toString().padStart(2, '0');
    const forDate = `${year}${month}${date1}`

    const [staff, setStaff] = useState<any>(null)
    const [filterdata, setFilterData] = useState<any>(null)

    const handlecheckSOSData = (staff: any) => {
        const filterData1 = slotRegister?.find((item: any) => staff.id === item.staffId && item.deActivateTime === null)
        if (filterData1) {
            setFilterData(filterData1)
            setDeviceId(filterData1?.deviceId)
            setLocation(filterData1?.location)
            setStartTime(filterData1?.startTime)
        } else {
            setFilterData(null)
        }
    }


    const handleOpenModal = (staff: any) => {
        handlecheckSOSData(staff)
        setOpenModal(true)
        setStaff(staff)
        triggerEffectInOtherTabs()
    }
    const closeModal = () => {
        setOpenModal(false)
        setDeviceId('')
        setLocation('')
        setVisitorModal(false)
        setNameError({})
        setVisitorName('')
        setShiftDuration('')
    }
    useEffect(() => {
        if (scanning) {
            startScanning();
        } else {
            codeReader.reset();
            setNewEvent('');
            setNewIndex('');
            setNewRegister([]);
        }

        return () => {
            codeReader.reset();
        };
    }, [scanning]);
    const handleChange = (event: any, value: any) => {
        setLocation(value);
    };
    const startScanning = async () => {
        try {
            setScanning(true);
            const videoInputDevices = await codeReader.listVideoInputDevices();
            const selectedDeviceId = videoInputDevices[0].deviceId;
            const constraints = {
                video: { deviceId: selectedDeviceId },
            };
            codeReader.decodeFromVideoDevice(
                selectedDeviceId,
                videoRef.current,
                (result: any, err: any) => {
                    if (result) {
                        let newDeviceId = "";
                        const result1 = result.getText();
                        if (result1 !== "" && result1 !== undefined && result1 !== null) {
                            if (result1?.length < 15) {
                                const resultId = result1.match(/.{2}/g).join(':');
                                newDeviceId = resultId;
                                setDeviceId(resultId)
                            } else {
                                const resultId = result1.match(/.{2}/g).join(':');
                                newDeviceId = resultId.slice(6, 23);
                                setDeviceId(newDeviceId)
                            }
                            setShow(false);
                            setScanning(false);
                        }
                        const scannedOption = options?.find((option: any) => option.deviceId === newDeviceId);

                        if (newRegister === "1") {
                            if (scannedOption) {
                                slotRegister[newIndex].deviceId = scannedOption.deviceId;
                                setSlotRegister1(slotRegister);
                            } else {
                                slotRegister[newIndex].deviceId = "";
                                setSlotRegister1(slotRegister);
                                toast.error("Scanned device ID not found in the options.");
                            }
                        }
                        const modal = document.getElementById("exampleModal");
                        if (modal) {
                            modal.classList.add("show");
                            modal.style.display = "block";
                        }

                    }
                    if (err && err.name === "NotFoundError") {
                        console.error("No QR code found in the video feed.");
                    }
                    if (err) {
                        console.error("Error during scanning:", err);
                    }

                },
            );
        } catch (error) {
            console.error("Error starting the scanner:", error);
        }
    };
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false)
        setScanning(false)
        setNewIndex('');
        setNewRegister('');
        codeReader.reset()
    };

    const closeModalAndRec = () => {
        setShow(false)
        setScanning(false)
        codeReader.reset()
    }
    const handleDateChange = (event: any) => {
        const newDate = new Date(event.target.value);
        if (isNaN(newDate.getTime())) {
            setSelectedDate(new Date());
        } else {
            setSelectedDate(newDate);
        }
    };

    useEffect(() => {
        const date = selectedDate?.getDate().toString().padStart(2, '0');
        const year = selectedDate?.getFullYear();
        const month = (selectedDate?.getMonth() + 1).toString().padStart(2, '0');
        const currDate = `${year}${month}${date}`;
        setCurrentDate(currDate)
        getPatientDataByDate(dispatch, currDate, organization, 0)
    }, [selectedDate]);
    useEffect(() => {
        setSlotRegister1(AllpatientData)
    }, [AllpatientData])
    const handleQrClick = () => {
        setShow(true);
        setScanning(!scanning);
    }

    useEffect(() => {
        const fetchDropdownData = async () => {
            const { header1 } = authorize();
            try {
                const response = await axios.get(`${baseURL}/dropdowns/getByDropdown?dropdown=Hospital Location`, { headers: header1 });
                if (response && response.data.message && response.data.message.code === successCode) {
                    setDropdownData(response.data.data[0].list);
                } else {
                    setDropdownData([]);
                    console.error(
                        "Error fetching dropdown data:",
                        response.data.message.description
                    );
                }
            } catch (error) {
                console.error("Error fetching dropdown data:", error);
            }
        };
        fetchDropdownData();
    }, []);
    const formatDate1 = (date: Date) => {
        // Format the date as DD/MM/YYYY
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-indexed
        const year = date.getFullYear();
    
        return `${day}/${month}/${year}`;
      };
    const [nameError, setNameError] = useState<{ [key: string]: boolean }>({})
    const handleConfirmModalClo = () => {
        setConfirmModal(!confirmModal)
        setConfirmMessage('')
    }

    const assignedByID = localStorage.getItem('userID')
    const triggerEffectInOtherTabs = () => {
        localStorage.setItem('ltrigger', Date.now().toString());
    };


    const handleSubmit = async (alertValue: boolean) => {
        const { header1 } = authorize();
        setLtigger('non-trigger')
        setLoading(true)
        let bodyData;
        if (visitorModal) {
            let newErrors = {
                deviceId: !deviceId,
                location: !location,
                visitorName: !visitorName,
                startTime: !startTime,
                duration: !shiftDuration
            }
            setNameError(newErrors)
            const hasErrors = Object.values(newErrors).some(error => error);
            if (hasErrors) {
                toast.error("Please Fill Required Field")
                setLoading(false)
                return;
            }
            bodyData = {
                deviceId: deviceId,
                organization: organization,
                date: forDate,
                location: location,
                assignedBy: assignedByID,
                alert: alertValue,
                singleAlert: alertValue,
                visitorName: visitorName ? visitorName : '',
                startTime: startTime,
                duration: shiftDuration
            }
        } else {
            let newErrors = {
                deviceId: !deviceId,
                location: !location,
                startTime: !startTime,
                duration: !shiftDuration
            }
            setNameError(newErrors)
            const hasErrors = Object.values(newErrors).some(error => error);
            if (hasErrors) {
                toast.error("Please Fill Required Field")
                setLoading(false)
                return;
            }
            bodyData = {
                staffId: staff.id,
                deviceId: deviceId,
                organization: organization,
                date: forDate,
                location: location,
                assignedBy: assignedByID,
                alert: alertValue,
                singleAlert: alertValue,
                visitorName: '',
                startTime: startTime,
                duration: shiftDuration
            }
        }
        try {
            const response = await axios.post(`${baseURL}/sosAlarm/register`, bodyData, { headers: header1 });
            if (response.data.message.code === successCode) {
                toast.success(response.data.message.description);
                triggerEffectInOtherTabs()
                closeModal()
                setVisitorModal(false)
                getPatientDataByDate(dispatch, currentDate, organization, 0)
                setConfirmModal(false)
                setLoading(false)
            } else if (response.data.message.code === 'MHC - 0128') {
                setConfirmMessage('The staff already has another beacon device. Do you want to remove and assign this device?')
                setConfirmModal(true)
                setLoading(false)
            } else if (response.data.message.code === 'MHC - 0117') {
                setConfirmMessage('The beacon device was already assigned to another staff member. Do you want to confirm this device to assign it to you?')
                setConfirmModal(true)
                setLoading(false)
            } else {
                toast.error("Request failed: " + response.data.message.description);
                closeModal()
                setLtigger('non-trigger')
                setLoading(false)
            }
            setLoading(false)
        } catch (error: any) {
            console.error("Error config:", error.config);
            setLoading(false)
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            const { header1 } = authorize();
            try {
                const response = await axios.get(`${baseURL}/sensor/getStaffsBeacon?organization=${organization}`, { headers: header1 })
                setOptions(response.data.data);
            } catch (error) {
                setOptions([]);
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [slotRegister]);


    const handleActiveModalOpen = async (organization: any) => {
        if (!organization?.deActivateTime || organization?.deActivateTime === null || organization?.deActivateTime === undefined) {
            setActiveMessage(`Deactivate ${organization?.staffName !== null ? organization?.staffName : organization?.visitorName}-${organization?.deviceId}?`);
            setActiveModal(!activeModal);
            setActiveDetail(organization);
        }
    };

    const handleAvtiveModalClo = () => {
        setActiveModal(!activeModal)
        setActiveDetail('')
        setActiveMessage('')
    }

    const handleActivatedModal = async (staff: any) => {
        setLoading(true)
        try {
            const idName = staff?.staffId ? staff?.staffId : staff?.visitorName;
            const response = await axios.post(`${baseURL}/sosAlarm/manualDeactivation?date=${forDate}&duration=${staff?.duration}&organization=${staff?.organization}&staffId=${idName}&startTime=${staff?.startTime}`)
            if (response.data.message.code === "MHC - 0200") {
                toast.success(response.data.message.description)
                getPatientDataByDate(dispatch, currentDate, organization, 0)
                handleAvtiveModalClo()
                triggerEffectInOtherTabs()
                setLoading(false)
            } else {
                toast.error(response.data.message.description)
                setLoading(false)
            }
        } catch (error: any) {
            console.error("API Error :", error)
            setLoading(false)
        }
    }

    return (
        <React.Fragment>
            <div style={{ overflowY: 'auto' }}>
                {Loading && <Loader />}
                {loading  && <Loader />}
                {staffLoading && <Loader />}
                <h5>SOS Configuration</h5>
                <div style={{ display: "flex", }} >
                    <div style={{ width: "18%", height: '87vh', backgroundColor: "#eaf2fa", borderRight: "1px groove #ccd2d8" }} >
                        <div className="row" style={{ height: '50px' }}>
                            <div className="mx-0 search-container d-flex align-items-center" >
                                <input
                                    type="text"
                                    placeholder="Search..."
                                    className="search form-control"
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>
                        </div>
                        <div style={{ width: '100%', height: '90%', overflowX: 'hidden', overflowY: 'scroll' }}>
                            {staffData && staffData.length > 0 ? staffData?.map((item: any, index: any) => (
                                <div key={item.id} className="row" style={{ height: '50px', border: '1px solid grey', cursor: 'pointer' }}
                                    onClick={() => { handleOpenModal(item) }}
                                >
                                    <div className="col-md-3 d-flex align-items-center justify-content-around">
                                        {
                                            item.profile && (item.profile.startsWith("http") || item.profile.startsWith("data:image")) ? (
                                                <img
                                                    src={item.profile}
                                                    alt="no image"
                                                    style={{ width: '30px', height: '30px', borderRadius: '15px' }}
                                                    onError={(e) => {
                                                        (e.target as HTMLImageElement).src = 'path/to/default/avatar.jpg';
                                                    }}
                                                />
                                            ) : (
                                                <Avatar
                                                    style={{
                                                        width: '30px',
                                                        height: '30px',
                                                        objectFit: 'cover',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }}
                                                    src={'path/to/default/avatar.jpg'} 
                                                />
                                            )
                                        }
                                    </div>
                                    <div className="col-md-9" style={{ padding: '0' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center' }}>
                                            <p style={{ margin: '0', marginBottom: '0px', fontSize: '13px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', }} title={`${item.name[0].given} ${item.name[0].family}`}> {item.name[0].given} {item.name[0].family} </p>
                                            <p style={{ margin: '0', fontSize: '13px' }}>{item.role}</p>
                                        </div>
                                    </div>
                                </div>
                            ))
                                : <div className="row" style={{ height: '70px', border: '1px solid grey', cursor: 'pointer' }}>
                                    <div className="ml-2">
                                        <p style={{ fontSize: '14px' }}>This staff is a Guest <Button label="Create Guest" style={{ backgroundColor: primarybg }} className="" onClick={() => {
                                            setOpenModal(true)
                                            setVisitorModal(true)

                                        }} ></Button></p>
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                    <div style={{ width: "81%", backgroundColor: "#f2f5f9", marginLeft: '2px' }} >
                        <div style={{ display: 'flex', justifyContent: "space-evenly", padding: "10px", width: "90%", position: "relative", left: "66px" }}>
                            {renderDateBoxes()}
                            <div className="inpMain">
                                <input
                                    type="date"
                                    value={selectedDate?.toISOString().split('T')[0]}
                                    onChange={handleDateChange}
                                />
                                <div style={{ border: "1px groove" }} />
                                <img style={{ position: "absolute", left: '12px', top: '21px', width: '32px', height: '22px' }} src={calendarMuiImage} />
                            </div>
                        </div>
                        <div className="d-flex justify-content-end my-1">
                            <Button label="Create Guest" style={{ backgroundColor: primarybg }} onClick={() => {
                                setOpenModal(true)
                                setVisitorModal(true)
                            }}></Button>
                        </div>
                        <div className="row " style={{ width: '99%', marginLeft: '5px' }}>
                            <Table className="table table-bordered" style={{ fontSize: '12px' }}>
                                <thead>
                                    <tr>
                                        <th scope="col" style={{ width: '13px', color: primarytext }} className="text-center table-data">S.No</th>
                                        <th scope="col" className="text-center table-data" style={{ color: primarytext }}>Staff Name</th>
                                        <th scope="col" className="text-center table-data" style={{ color: primarytext }}>Device ID</th>
                                        <th scope="col" className="text-center table-data" style={{ color: primarytext }}>Location</th>
                                        <th scope="col" className="text-center table-data" style={{ color: primarytext }}>Activated Time</th>
                                        <th scope="col" className="text-center table-data" style={{ color: primarytext }}>Valid Till</th>
                                        <th scope="col" className="text-center table-data" style={{ color: primarytext }}>De-Activated Time</th>
                                        <th scope="col" className="text-center table-data" style={{ color: primarytext }}>Assigned By</th>
                                        <th scope="col" className="text-center table-data" style={{ color: primarytext }}>Active Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {slotRegister?.length > 0 ? (
                                        slotRegister?.map((staff, index) => (
                                            <tr key={index}>
                                                <td style={{ textAlign: "left" }}>{currentPage * 8 + index + 1}</td>
                                                <td style={{ textAlign: "left" }}>{staff.staffName ? staff.staffName : staff.visitorName && staff.visitorName}</td>
                                                <td style={{ textAlign: "left" }}>{staff.deviceId}</td>
                                                <td style={{ textAlign: "left" }}>{staff.location}</td>
                                                <td style={{ textAlign: "left" }}>{staff.activateTime && moment(`${staff.activateTime.slice(0, 4)}-${staff.activateTime.slice(4, 6)}-${staff.activateTime.slice(6, 8)} ${staff.activateTime.slice(8, 10)}:${staff.activateTime.slice(10, 12)}:${staff.activateTime.slice(12, 14)}`).format('MM-DD-YYYY hh:mm:ss A')}</td>
                                                <td style={{ textAlign: "left" }}>{staff.duration}</td>
                                                <td style={{ textAlign: "left" }}>{staff.deActivateTime && moment(`${staff.deActivateTime.slice(0, 4)}-${staff.deActivateTime.slice(4, 6)}-${staff.deActivateTime.slice(6, 8)} ${staff.deActivateTime.slice(8, 10)}:${staff.deActivateTime.slice(10, 12)}:${staff.deActivateTime.slice(12, 14)}`).format('MM-DD-YYYY HH:mm:ss')}</td>
                                                <td style={{ textAlign: "left" }}>{staff.assignedByName}</td>
                                                <td style={{ textAlign: "center" }}>
                                                    <Tooltip
                                                        title={staff?.deActivateTime === null ? "Active" : ""}
                                                        arrow>
                                                        <div className="text-primary">
                                                            <FontAwesomeIcon
                                                                icon={faCircle}
                                                                style={{
                                                                    fontSize: "14px",
                                                                    cursor: staff?.deActivateTime !== null ? 'default' : 'pointer',
                                                                    color: staff?.deActivateTime !== null ? '#EF6868' : '#5FB477',
                                                                    display: "inline-block",
                                                                    textAlign: "center",
                                                                }}
                                                                onClick={() => {
                                                                    if (staff?.deActivateTime === null) {
                                                                        handleActiveModalOpen(staff);
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </Tooltip>

                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={9} className="text-center">There are no records...</td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </div>
                        <div className="pagination-container" >
                            <div className="d-flex justify-content-center">
                                <Paginator
                                    first={currentPage * 10}
                                    rows={10}
                                    totalRecords={totalElements}
                                    onPageChange={(e: any) => {
                                        setCurrentPage(e.page)
                                        getPatientDataByDate(dispatch, currentDate, organization, e.page)
                                    }}
                                    currentPageReportTemplate={`Page ${currentPage} of ${totalElements / 10}`}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={openModal} className={`${confirmModal && 'z-2'}`} style={{ transition: `${confirmModal && 'width 2s'}`, top: 50 }} onHide={closeModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {!visitorModal ? <>
                                {staff && staff?.name[0]?.given + " " + staff?.name[0].family}

                            </>

                                : <div>Guest Register</div>


                            }
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div >
                            {
                                visitorModal && <div>
                                    <div className='mb-2' >
                                        <TextField id="visitorname"
                                            label="Guest Name"
                                            variant="outlined"
                                            fullWidth
                                            value={visitorName}
                                            onChange={(e) => {
                                                setVisitorName(e.target.value)
                                                setNameError({ ...nameError, visitorName: false })
                                            }}
                                            error={!!nameError.visitorName}
                                            helperText={nameError.visitorName ? 'Guest Name is required' : ''}
                                            required
                                        />
                                    </div>
                                </div>
                            }
                            <div >
                                <p>Device ID</p>
                            </div>
                            <div className="row">
                                <div className='col-md-6' style={{ position: 'relative', paddingLeft: 0, padding: 0 }}>
                                    <List sx={{ pt: 0 }} style={{ margin: 0, padding: 0 }}>
                                        <ListItem disableGutters style={{ margin: 0, padding: 0 }}>
                                            <Select
                                                // multiple
                                                value={deviceId}
                                                onChange={(e) => {
                                                    console.log(e.target.value, 'e.traget')
                                                    setDeviceId(e.target.value)
                                                }}
                                                style={{ minHeight: '50px', width: '300px', marginLeft: '12px' }}
                                                IconComponent={() => (
                                                    <FaQrcode
                                                        className="position-absolute bottom-0 m-3" onClick={handleQrClick}
                                                        role='button'
                                                        style={{ fontSize: '1.2rem', color: '#000', right: '0', cursor: 'pointer', margin: 0, padding: 0 }}
                                                    />
                                                )}
                                                MenuProps={{
                                                    PaperProps: {
                                                        style: {
                                                            maxHeight: '300px',
                                                        },
                                                    },
                                                }}
                                            >
                                                {options?.length > 0 ? options?.map((option: any) => (
                                                    <MenuItem key={option.id} value={option.deviceId}
                                                    >
                                                        {option.deviceId}
                                                        <FontAwesomeIcon
                                                            icon={faCircle}
                                                            style={{
                                                                fontSize: "12px",
                                                                color: !option?.status ? '#5FB477' : '#EF6868',
                                                                display: "inline-block",
                                                                textAlign: "center",
                                                                marginLeft: '10px',
                                                                marginBottom: '1px'
                                                            }}
                                                        />
                                                    </MenuItem>
                                                )) :
                                                    <MenuItem>
                                                        No Device Data
                                                    </MenuItem>
                                                }
                                            </Select>
                                        </ListItem>
                                    </List>
                                </div>
                                <div className="col-md-6">
                                    <Autocomplete
                                        id={'organizationDetails'}
                                        options={dropdownData?.map((item: any) => item.value)}
                                        value={location}
                                        onChange={(e, v) => {
                                            handleChange(e, v)
                                            setNameError({ ...nameError, location: false })
                                        }}
                                        renderInput={(params) => <TextField {...params} label={'Location'} variant="outlined"
                                            error={!!nameError.location}
                                            helperText={nameError.location ? 'Location is required' : ''}
                                        />}
                                    />
                                </div>
                            </div>
                            <div className="row pt-2">
                                <div className="col-md-6 " style={{ overflow: 'auto' }}>
                                    <div className="py-1">
                                        Date and Time
                                    </div>
                                    <div className="input-group mb-3">
                                        
                                        <input type="text"
                                            className="form-control formcontrolcu"
                                            placeholder="Date"
                                            aria-label="Date"
                                            value={ldate}
                                            
                                            onChange={(e: any) => {
                                                setLdate(e.target.value)
                                            }}
                                            style={{ height: '56px', borderRight: '1px solid transparent', outline: 'transparent', }}

                                            readOnly
                                        />
                                        <input type="time"
                                            className="form-control  formcontrolcu1"
                                            placeholder="Username"
                                            aria-label="Username"
                                            value={startTime}
                                            onChange={(e) => {
                                                setStartTime(e.target.value)
                                            }}
                                            style={{ height: '56px', borderLeft: '1px solid transparent', outline: 'transparent' }}
                                            id="startTime"
                                            min="00:00"
                                            max="23:59"
                                        />

                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="py-3"></div>
                                    <Autocomplete
                                        id={'shiftDuration'}
                                        options={shiftDurationHour?.map((item: any) => item.value)}
                                        value={shiftDuration}
                                        onChange={(e: any, v) => {
                                            setShiftDuration(v)
                                            setNameError({ ...nameError, duration: false })
                                        }}
                                        renderInput={(params) => <TextField {...params} label={'Duration'} variant="outlined"
                                            error={!!nameError.duration}
                                            helperText={nameError.duration ? 'Duration is required' : ''}
                                        />}
                                    />
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer style={{ gap: '4px' }}>
                        <Button label={'Close'} variant="secondary" style={{ backgroundColor: '#94a0b7', fontSize: '12px' }} onClick={closeModal} >
                        </Button>
                        <Button label={'Save Changes'} style={{ backgroundColor: '#0f3995', fontSize: '12px' }} onClick={() => handleSubmit(false)} />
                    </Modal.Footer>
                </Modal>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Scanning</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <video ref={videoRef} style={{ display: scanning ? "block" : "none", width: '100%', height: '400px' }} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button label={'Close'} variant="secondary" onClick={handleClose} >
                        </Button>
                        <Button label={scanning ? "Stop Scanning" : "Start Scanning"} style={{ backgroundColor: '#0f3995' }} onClick={closeModalAndRec} />
                    </Modal.Footer>
                </Modal>
                <ErrorPopup
                    closePopup={closePopup}
                    errorMsg={errorMsg}
                    open={isOpen}
                />
                <LogoutConfirmationModal open={activeModal} handleConfirm={() => handleActivatedModal(activeDetail)} handleClose={() => handleAvtiveModalClo()} message={activeMessage} />
                <LogoutConfirmationModal open={confirmModal} handleConfirm={() => handleSubmit(true)} handleClose={() => handleConfirmModalClo()} message={confirmMessage} />


            </div>
        </React.Fragment>
    );
};

export default StaffConfigure;