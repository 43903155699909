import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllBedAssign
} from "../../slices/bedAssign/thunk";
import { getAllBed } from "../../slices/patientAssign/thunk";
import "./bedassign.css";
import { HttpLogin } from "../../utils/Http";
import { Autocomplete, TextField, DialogTitle, Dialog, DialogContent, DialogContentText, FormControlLabel, Checkbox, IconButton, styled, InputAdornment } from '@mui/material';
import { Button } from "primereact/button";
import axios from "axios";
import { baseURL, successCode } from "../../configuration/url";
import { Row, Col, CardBody, CardTitle, CardSubtitle, CardFooter, Card, Badge } from "reactstrap";
import { toast } from "react-toastify";
import { authorize } from "../../helpers/common";
import roomimg from "../../assets/images/room.svg";
import bedimg from "../../assets/images/bed.svg";
import LogoImg from "../../assets/images/profile.svg";
import { Close, Search } from "@mui/icons-material";
import Loader from "../../components/loader/Loader";
const BedAddAssign: React.FC = () => {
  const dispatch = useDispatch<any>();
  const { organization } = useSelector((state: any) => state.Login);
  const [bed, setBed] = useState(false)
  let inputNewData = {
    roomNoStart: "",
    roomNoEnd: "",
    oddOrEven: "",
    organization: "",
    bedNoList: [
      {
        roomNo: "",
        bedQuantity: 0
      }
    ]
  }

  let [inputFormData, setInputFormData] = useState(inputNewData);
  const findElementsTwice = (array: any) => {
    const result = array.filter((element: any, index: any, arr: any) => {
      const count = arr.filter((el: any) => el === element).length;
      return count === 2 && arr.indexOf(element) === index;
    });
    return result;
  };

  const OddEven = [
    { label: "All", value: "all" },
    { label: "Odd", value: "odd" },
    { label: "Even", value: "even" }
  ]

  let [bothOption, setBothOption] = useState<any[]>(OddEven);
  let [addBothOption, setAddBothOption] = useState<any[]>(OddEven.map((k: any) => { return k.label }));
  let [addBothValue, setAddBothValue] = useState("");
  const [loading, setloading] = useState<boolean>(false)
  const [patientAndBedAssign, setPatientAndBedAssign] = useState<any[]>([]);
  const findElementsThrice = (array: any) => {
    const result = array.filter((element: any, index: any, arr: any) => {
      const count = arr.filter((el: any) => el === element).length;
      return count === 3 && arr.indexOf(element) === index;
    });

    return result;
  };
  const fetchPatientsandBedAssign = async () => {
    const { header1 } = authorize();
    try {
      const response = await axios.get(
        `${baseURL}/Q15Bed/getByOrg/${organization}`, { headers: header1 }
      );
      if (response.data.data && Array.isArray(response.data.data)) {
        setPatientAndBedAssign(response.data.data);
      } else {
        console.error("Invalid data format for patients:", response.data);
      }
    } catch (error) {
      console.warn(error);
    }
  };
  useEffect(() => {
    getAllBedAssign(dispatch, organization);
    fetchPatientsandBedAssign();
    HttpLogin.axios().get("/api/Q15Bed/getByOrg/" + organization)
      .then((response) => {
        if (response.data.data !== undefined && response.data.data !== null) {
          if (response.data.data.length > 0) {
            let newBedAssign = response.data.data !== null && response.data.data !== undefined && response.data.data.map((k: any) => { return k.roomNo });
            var elementCounts = newBedAssign.reduce((count: any, item: any) => (count[item] = count[item] + 1 || 1, count), {});
            elementCounts = Object.entries(elementCounts).map(([key, value]) => ({
              roomNo: `${key}`,
              bedQuantity: parseInt(`${value}`)
            }));
            inputFormData.roomNoStart = elementCounts !== null && elementCounts !== undefined ? elementCounts[0].roomNo : "";
            inputFormData.roomNoEnd = elementCounts !== null && elementCounts !== undefined ? elementCounts[elementCounts.length - 1].roomNo : "";
            inputFormData.bedNoList = elementCounts !== null && elementCounts !== undefined ? elementCounts : inputNewData.bedNoList;
            setInputFormData({ ...inputFormData })
          }
        }
      })
  }, [dispatch, organization, bed]);

  const [showModal, setShowModal] = useState(false);
  const [showModaldialog, setShowdialog] = useState(false);
  const handleInputChange = (event: any) => {
    if (event.target.id === "roomNoStart") {
      inputFormData.roomNoStart = event.target.value;
      inputFormData.oddOrEven = "all";
      addBothValue = inputFormData.oddOrEven;
      setAddBothValue(addBothValue);
    } else if (event.target.id === "oddOrEven") {
      inputFormData.oddOrEven = event.target.value;
      addBothValue = event.target.value;
      setAddBothValue(addBothValue);
      let newValue = [];
      for (var i = parseInt(inputFormData.roomNoStart); i <= parseInt(inputFormData.roomNoEnd); i++) {
        let numberValue;
        numberValue = event.target.value === "odd" ? i % 2 !== 0 && i : event.target.value === "even" ? i % 2 === 0 && i : i % 1 === 0 && i;
        let newData = {
          roomNo: JSON.stringify(numberValue),
          bedQuantity: 1
        }
        newValue.push(newData);
      }
      newValue = newValue.filter((k: any) => k.roomNo !== 'false').map((l: any) => { return l });
      inputFormData.bedNoList = newValue;
    } else if (event.target.id === "roomNoEnd") {
      if (inputFormData.roomNoStart !== "") {
        const newEndRoom = parseInt(event.target.value);
        const currentEndRoom = parseInt(inputFormData.roomNoEnd);
        if (newEndRoom > currentEndRoom) {
          // Add rooms to the list if the end room is increased
          for (var i = currentEndRoom + 1; i <= newEndRoom; i++) {
            let newData = {
              roomNo: JSON.stringify(i),
              bedQuantity: 1
            };
            inputFormData.bedNoList.push(newData);
          }
        } else if (newEndRoom < currentEndRoom) {
          // Remove rooms from the list if the end room is decreased
          inputFormData.bedNoList = inputFormData.bedNoList.filter((room: any) => parseInt(room.roomNo) <= newEndRoom);
        }
        inputFormData.roomNoEnd = event.target.value;
      } else {
        alert("Please Enter Start Room Number");
      }
    }
    setInputFormData({ ...inputFormData });
  }

  useEffect(() => {
    getAllBed(dispatch, organization);
  }, [organization, bed]);

  const handleSaveBed = async () => {
    inputFormData.organization = organization;
    inputFormData.oddOrEven = inputFormData.oddOrEven !== "" ? inputFormData.oddOrEven : "all";
    addBothValue = inputFormData.oddOrEven;
    setAddBothValue(addBothValue);
    setloading(true)
    try {
      var url = "/api/Q15Bed/create";
      var obj = JSON.stringify(inputFormData);
      await HttpLogin.axios().post(url, obj, {
        headers: {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "*",
          "Access-Control-Allow-Headers": "*"
        }
      })
        .then(response => {
          if (
            response.data.message &&
            response.data.message.code === successCode
          ) {
            toast.success(response.data.message.description);
            setBed(!bed)
            getAllBed(dispatch, organization)
            setShowdialog(false)
            setloading(false)
          } else {
            toast.error(response.data.message.description);
            setloading(false)
          }
        })
    } catch (error) {
      alert("Room No and No. of Bed/s Already Exists");
      setloading(false)
    }
  };

  const handleClick = (selectedBed: any) => {
    if (selectedBed) {
      const bedAssignId = selectedBed.id || " ";
    } else {
      console.error("Invalid Data:", selectedBed);
    }
  };

  const [disabled, setDisabled] = useState<boolean>(false);
  const [disabled1, setDisabled1] = useState<boolean>(false);
  const [disabled2, setDisabled2] = useState<boolean>(false);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDisabled(e.target.checked);
  };

  const handleCheckbox1Change = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = [];
    for (let i = parseInt(inputFormData.roomNoStart); i <= parseInt(inputFormData.roomNoEnd); i++) {
      let numberValue;
      if (inputFormData.oddOrEven === "odd") {
        numberValue = i % 2 !== 0 ? i : false;
      } else if (inputFormData.oddOrEven === "even") {
        numberValue = i % 2 === 0 ? i : false;
      } else {
        numberValue = i;
      }

      if (numberValue) {
        let newData = {
          roomNo: numberValue.toString(),
          bedQuantity: 2
        };
        newValue.push(newData);
      }
    }

    // Create a map to keep track of room numbers and their bed quantities
    const roomMap = new Map();
    newValue.forEach(item => {
      roomMap.set(item.roomNo, item.bedQuantity);
    });

    // Remove any entry with an empty roomNo
    inputFormData.bedNoList = inputFormData.bedNoList.filter(item => item.roomNo !== '');

    // Update the existing list with the new values
    inputFormData.bedNoList.forEach(item => {
      if (roomMap.has(item.roomNo)) {
        item.bedQuantity = roomMap.get(item.roomNo);
        roomMap.delete(item.roomNo);
      }
    });

    // Add remaining new values to the list
    roomMap.forEach((bedQuantity, roomNo) => {
      inputFormData.bedNoList.push({ roomNo, bedQuantity });
    });

    // Sort the list to maintain the correct order
    inputFormData.bedNoList.sort((a, b) => parseInt(a.roomNo) - parseInt(b.roomNo));

    setDisabled1(e.target.checked);
    if (e.target.checked) {
      setDisabled2(false);
    }
  };


  const [searchTerm, setSearchTerm] = useState("");
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredBedList = patientAndBedAssign.filter((bedassign: any) => {
    // Assuming roomNo and bedNo are strings, you can customize the filtering logic based on your needs
    return bedassign.roomNo.toLowerCase().includes(searchTerm.toLowerCase())
  });
  const bedsByNumber = filteredBedList.reduce<{ [key: string]: any[] }>((acc, bedassign) => {
    const { roomNo, bedNo } = bedassign;

    if (!acc[roomNo]) {
      acc[roomNo] = [];
    }
    acc[roomNo].push(bedassign);
    return acc;
  }, {});
  const handleCheckbox2Change = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = [];
    for (let i = parseInt(inputFormData.roomNoStart); i <= parseInt(inputFormData.roomNoEnd); i++) {
      let numberValue;
      if (inputFormData.oddOrEven === "odd") {
        numberValue = i % 2 !== 0 ? i : false;
      } else if (inputFormData.oddOrEven === "even") {
        numberValue = i % 2 === 0 ? i : false;
      } else {
        numberValue = i;
      }

      if (numberValue) {
        let newData = {
          roomNo: numberValue.toString(),
          bedQuantity: 3
        };
        newValue.push(newData);
      }
    }

    // Create a map to keep track of room numbers and their bed quantities
    const roomMap = new Map();
    newValue.forEach(item => {
      roomMap.set(item.roomNo, item.bedQuantity);
    });

    // Remove any entry with an empty roomNo
    inputFormData.bedNoList = inputFormData.bedNoList.filter(item => item.roomNo !== '');

    // Update the existing list with the new values
    inputFormData.bedNoList.forEach(item => {
      if (roomMap.has(item.roomNo)) {
        item.bedQuantity = roomMap.get(item.roomNo);
        roomMap.delete(item.roomNo);
      }
    });

    // Add remaining new values to the list
    roomMap.forEach((bedQuantity, roomNo) => {
      inputFormData.bedNoList.push({ roomNo, bedQuantity });
    });

    // Sort the list to maintain the correct order
    inputFormData.bedNoList.sort((a, b) => parseInt(a.roomNo) - parseInt(b.roomNo));

    setDisabled2(e.target.checked);
    if (e.target.checked) {
      setDisabled1(false);
    }
  };

  return (
    <>
    {loading && <Loader/>}
    <div className="overflow-y-auto overflow-x-hidden">
        <div className="row d-flex flex-row pt-2" >
          <div className="col-md-4 ">
            <h5>Bed Master Configuration</h5>
          </div>
          <div className="col-md-3"></div>
          <div className="col-md-2">
            <div className="">
              <TextField
                label="Search Room Number"
                variant="outlined"
                value={searchTerm}
                onChange={handleSearchChange}
                InputProps={{
                  style: { height: '45px' },
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search style={{fontSize:'16px'}}/>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  style: { fontSize: '11px' },
                }}
                fullWidth
              />
            </div>
          </div>
          <div className="col-md-3 d-flex justify-content-around">
            <Button label="Old View" style={{ color: '#000', backgroundColor: '#94a0b7', fontSize: '11px', fontWeight: 'bold' }} onClick={() => { setShowModal(true) }}></Button>
            <Button label="Add Rooms" style={{ backgroundColor: '#0f3995', fontSize: '11px', fontWeight: 'bold' }} onClick={() => { setShowdialog(true) }}></Button>
          </div>
          <div className="col-md-3 ">
            <FormControlLabel
              control={<Checkbox checked={disabled} onChange={handleCheckboxChange} />}
              label={disabled ? "BedNo - Number" : "BedNo - Alphabet"}
            />
          </div>
        </div>
        <div className="form-control" style={{ borderStyle: "none" }}>
          <Dialog maxWidth={'sm'} PaperProps={{ sx: { position: 'center' } }}
            open={showModaldialog}
            onClose={() => setShowdialog(false)}
          >
            <DialogTitle >Create Room & Bed</DialogTitle>
            <IconButton
              aria-label="close"
              onClick={() => setShowdialog(false)}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Close />
            </IconButton>
            <DialogContentText >
              <DialogContent style={{ padding: '20px', background: '#F8FAFB' }}>
                <div className="row" >
                  <div className="col-md-6">
                    <TextField
                      id="roomNoStart"
                      name="roomNoStart"
                      label="Start Room No"
                      value={inputFormData.roomNoStart}
                      onChange={handleInputChange}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                  </div>
                  <div className="col-md-6">
                    <TextField
                      id="roomNoEnd"
                      name="roomNoEnd"
                      label="End Room No"
                      value={inputFormData.roomNoEnd}
                      onChange={handleInputChange}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                  </div>
                  <div className="col-md-12 mt-3">
                    <Autocomplete
                      id="natureOfReaction"
                      options={addBothOption}
                      value={addBothValue === "" ? "All" : addBothValue}
                      getOptionLabel={(option) => option}
                      onChange={(e, v) => {
                        let newAddOption = bothOption.filter((m: any) => m.label === v).map((l: any) => { return l.value })
                        addBothValue = v;
                        setAddBothValue(v);
                        inputFormData.oddOrEven = newAddOption[0];
                        let newValue = [];
                        for (var i = parseInt(inputFormData.roomNoStart); i <= parseInt(inputFormData.roomNoEnd); i++) {
                          let numberValue;
                          numberValue = newAddOption[0] === "odd" ? i % 2 !== 0 && i : newAddOption[0] === "even" ? i % 2 === 0 && i : i % 1 === 0 && i;
                          let newData = {
                            roomNo: JSON.stringify(numberValue),
                            bedQuantity: 1
                          }
                          newValue.push(newData);
                        }
                        newValue = newValue.filter((k: any) => k.roomNo !== 'false').map((l: any) => { return l });
                        inputFormData.bedNoList = newValue;
                        setDisabled1(false);
                        setDisabled2(false);
                        setInputFormData({ ...inputFormData });
                      }}
                      sx={{ width: "100%" }}
                      size="medium"
                      renderInput={params =>
                        <TextField
                          name="natureOfReaction"
                          {...params}
                          variant="outlined"
                          label="Room Series"
                          placeholder=""
                          margin="none"
                          size="medium"
                          fullWidth
                        />
                      }
                    />
                  </div>
                  <div className="col-md-6 mt-3">
                    <FormControlLabel
                      control={<Checkbox checked={disabled1} onChange={handleCheckbox1Change} />}
                      label="Double Bed"
                    />
                  </div>
                  <div className="col-md-6 mt-3">
                    <FormControlLabel
                      control={<Checkbox checked={disabled2} onChange={handleCheckbox2Change} />}
                      label="Triple Bed"
                    />
                  </div>
                  <div className="d-flex gap-3 mb-3 justify-content-center">


                  </div>
                  <div className="d-flex gap-3 mb-3 justify-content-center">
                    <Button label="Cancel" severity="secondary" style={{ color: '#000', backgroundColor: '#94a0b7', fontSize: '12px', fontWeight: 'bold' }} onClick={() => setShowdialog(false)}></Button>
                    <Button label="Save Changes" style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold' }} onClick={handleSaveBed}></Button>

                  </div>
                  <div className="col-md-1">

                  </div>
                  <div className="col-md-3 mt-1">
                  </div>
                </div>
              </DialogContent>
            </DialogContentText>
          </Dialog>
          <div className="container m15 p3" >
            <div className="grid-templetcontainer" >
              {Object.entries(bedsByNumber).map(([bedNo, assignments]) => (
                <Row className="grid-templet" key={bedNo} >
                  {assignments.map((bedassign, index, bed: any) => {
                    return (
                      bedassign.patientName !== null ?
                        <>
                          <Col key={bedassign.id} style={{ flex: 0, padding: 0 }}>
                            <div className="bed-assignment-box">
                              <Card
                                className="mb-3"
                                color="danger"
                                outline
                                style={{
                                  width: "92px",
                                  height: "70px",
                                  margin: "5px",
                                  justifyContent: "flex-start",
                                }}
                              >
                                <CardBody
                                  key={index}
                                  style={{ cursor: "pointer", padding: '0.6rem', display: "flex", alignItems: "center", justifyContent: "space-between" }}
                                >
                                  <CardTitle tag="h6">
                                    <img src={roomimg} style={{ width: '15px', height: '20px' }} />
                                    <span style={{ marginLeft: '3px', fontSize: (bedassign.roomNo).length > 2 ? '10px' : '12px', fontWeight: 'bold' }}>{bedassign.roomNo}</span>
                                  </CardTitle>
                                  <CardSubtitle tag="h6" className=" text-muted">
                                    <img src={bedimg} style={{ width: '15px', height: '20px' }} />
                                    <span style={{ marginLeft: '3px', fontSize: '12px', fontWeight: 'bold' }}>{disabled ? String.fromCharCode(64 + parseInt(bedassign.bedNo, 10)) : bedassign.bedNo}</span>
                                  </CardSubtitle>
                                </CardBody>
                                <CardFooter style={{
                                  padding: '7px', position: 'relative', display: 'flex', top: '-13px', height: '32px', fontSize: '10px', fontWeight: 'bold', lineHeight: 'normal', justifyContent: "space-evenly",
                                  background: bedassign.bedNo === '01' ? '#f8b19570' : (bedassign.bedNo === '02' ? '#c06c8470' : (bedassign.bedNo === '03' ? '#355c7d52' : '#ffffff'))
                                }}>
                                  <img src={LogoImg} style={{ width: '20px', height: '20px' }}></img><span className="truncate-hover" style={{ paddingLeft: '5px', lineHeight: 2 }}>{bedassign.patientName}</span>
                                </CardFooter>
                              </Card>
                            </div>
                          </Col>
                        </> : <>
                          <Col key={index} style={{ flex: 0, padding: 0 }}>
                            <div className="bed-assignment-box">
                              <Card
                                className="mb-3"
                                color="primary"
                                outline
                                style={{
                                  width: "92px",
                                  height: "70px",
                                  margin: "5px",
                                  justifyContent: "flex-start",
                                }}
                              >
                                <CardBody
                                  key={index}
                                  onClick={() => handleClick(bedassign)}
                                  style={{ cursor: "pointer", padding: '0.6rem', display: "flex", alignItems: "center", justifyContent: "space-between" }}
                                >
                                  <CardTitle tag="h6">
                                    <img src={roomimg} style={{ width: '15px', height: '20px' }} />
                                    <span style={{ marginLeft: '3px', fontSize: (bedassign.roomNo).length > 2 ? '10px' : '12px', fontWeight: 'bold' }}>{bedassign.roomNo}</span>
                                  </CardTitle>
                                  <CardSubtitle tag="h6" className="text-muted ">
                                    <img src={bedimg} style={{ width: '15px', height: '20px' }} />
                                    <span style={{ marginLeft: '3px', fontSize: '12px', fontWeight: 'bold' }}>{disabled ? String.fromCharCode(64 + parseInt(bedassign.bedNo, 10)) : bedassign.bedNo}</span>
                                  </CardSubtitle>
                                </CardBody>
                                <CardFooter style={{
                                  padding: '2px 0.6rem 5px 13px ', position: 'relative', top: '-13px', height: '32px',
                                  background: bedassign.bedNo === '01' ? '#f8b19570' : (bedassign.bedNo === '02' ? '#c06c8470' : (bedassign.bedNo === '03' ? '#355c7d52' : '#FFFFFF'))
                                }}>
                                  <Badge
                                    style={{ fontSize: '10px' }}
                                    color={bedassign.patientName ? "danger" : "success"}
                                    tag="h6"
                                  >
                                    {bedassign.patientName ? "Not Available" : "Available"}
                                  </Badge>
                                </CardFooter>
                              </Card>
                            </div>
                          </Col>
                        </>
                    )
                  })}
                </Row>
              ))}
            </div>
          </div>

          <Dialog maxWidth={'xl'} PaperProps={{ sx: { width: '65%', maxWidth: '65%', position: 'absolute', height: '95vh', top: '1px' } }}
            open={showModal}
            onClose={() => setShowModal(false)}
          >
            <DialogTitle>Edit/View Room & Bed</DialogTitle>
            <IconButton
              aria-label="close"
              onClick={() => setShowModal(false)}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Close />
            </IconButton>
            <DialogContentText >
              <DialogContent style={{ padding: '20px', background: '#F8FAFB' }}>
                <div className="p-col-12">
                  <div className="p-grid" >
                    <div id="removePadding" className="p-col-12 p-md-12"></div>
                    <div id="removePadding" className="p-col-12 p-md-12"></div>
                    <div id="removePadding" className="p-col-12 p-md-1" style={{ borderTop: "1px groove", borderLeft: "1px groove", textAlign: "center", fontSize: '12px', backgroundColor: "#dee2e638" }}>Room No</div>
                    <div id="removePadding" className="p-col-12 p-md-1" style={{ borderTop: "1px groove", borderRight: "1px groove", textAlign: "center", fontSize: '12px', backgroundColor: "#dee2e638" }}>No. of Bed/s</div>
                    <div id="removePadding" className="p-col-12 p-md-1" style={{ borderTop: "1px groove", borderLeft: "1px groove", textAlign: "center", fontSize: '12px', backgroundColor: "#dee2e638" }}>Room No</div>
                    <div id="removePadding" className="p-col-12 p-md-1" style={{ borderTop: "1px groove", borderRight: "1px groove", textAlign: "center", fontSize: '12px', backgroundColor: "#dee2e638" }}>No. of Bed/s</div>
                    <div id="removePadding" className="p-col-12 p-md-1" style={{ borderTop: "1px groove", borderLeft: "1px groove", textAlign: "center", fontSize: '12px', backgroundColor: "#dee2e638" }}>Room No</div>
                    <div id="removePadding" className="p-col-12 p-md-1" style={{ borderTop: "1px groove", borderRight: "1px groove", textAlign: "center", fontSize: '12px', backgroundColor: "#dee2e638" }}>No. of Bed/s</div>
                    <div id="removePadding" className="p-col-12 p-md-1" style={{ borderTop: "1px groove", borderLeft: "1px groove", textAlign: "center", fontSize: '12px', backgroundColor: "#dee2e638" }}>Room No</div>
                    <div id="removePadding" className="p-col-12 p-md-1" style={{ borderTop: "1px groove", borderRight: "1px groove", textAlign: "center", fontSize: '12px', backgroundColor: "#dee2e638" }}>No. of Bed/s</div>
                    <div id="removePadding" className="p-col-12 p-md-1" style={{ borderTop: "1px groove", borderLeft: "1px groove", textAlign: "center", fontSize: '12px', backgroundColor: "#dee2e638" }}>Room No</div>
                    <div id="removePadding" className="p-col-12 p-md-1" style={{ borderTop: "1px groove", borderRight: "1px groove", textAlign: "center", fontSize: '12px', backgroundColor: "#dee2e638" }}>No. of Bed/s</div>
                    <div id="removePadding" className="p-col-12 p-md-1" style={{ borderTop: "1px groove", borderLeft: "1px groove", textAlign: "center", fontSize: '12px', backgroundColor: "#dee2e638" }}>Room No</div>
                    <div id="removePadding" className="p-col-12 p-md-1" style={{ borderTop: "1px groove", borderRight: "1px groove", textAlign: "center", fontSize: '12px', backgroundColor: "#dee2e638" }}>No. of Bed/s</div>
                    {Array.isArray(inputFormData.bedNoList) && inputFormData.bedNoList.length > 1 && Object.keys(inputFormData.bedNoList).filter((m: any) => m.roomNo !== "false").map((bedassign: any, index: number) => (
                      <>
                        {inputFormData.bedNoList !== null && inputFormData.bedNoList !== undefined ?
                          <><div key={index} id="removePadding" className="p-col-12 p-md-1" style={{ border: "1px groove", textAlign: "center", fontSize: '12px' }}>{inputFormData.bedNoList[index].roomNo}</div>
                            <div key={index} id="removePadding" className="p-col-12 p-md-1" style={{ border: "1px groove", textAlign: "center", fontSize: '12px', display: "flex", justifyContent: "center" }}>
                              <input type="number" style={{ width: "80%", textAlign: 'center', height: '30px', border: "1px groove" }}
                                value={inputFormData.bedNoList[index].bedQuantity}
                                min='1'
                                max='3'
                                onChange={(e) => {
                                  if (inputFormData.bedNoList[index].bedQuantity !== 0 && inputFormData.bedNoList[index].bedQuantity !== undefined && inputFormData.bedNoList[index].bedQuantity !== null) {
                                    inputFormData.bedNoList[index].bedQuantity = parseInt(e.target.value);
                                  } else {
                                    inputFormData.bedNoList[index].bedQuantity = parseInt(e.target.value);
                                  }
                                  setInputFormData({ ...inputFormData });
                                }} />
                            </div></> : <><div id="removePadding" className="p-col-12 p-md-1" style={{ border: "1px groove", textAlign: "center" }}></div>
                            <div id="removePadding" className="p-col-12 p-md-1" style={{ border: "1px groove", textAlign: "center", display: "flex", justifyContent: "flex-start" }}>
                            </div></>}
                      </>
                    ))}
                  </div>
                </div>
              </DialogContent>
            </DialogContentText>
            <div className="d-flex gap-3 mb-3 justify-content-center">
              <Button label="Cancel" severity="secondary" style={{ color: '#000', backgroundColor: '#94a0b7', fontSize: '12px', fontWeight: 'bold' }}
                onClick={() => setShowModal(false)}></Button>
              <Button label="Save Changes" style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold' }} onClick={handleSaveBed}></Button>
            </div>
          </Dialog>
        </div>
    </div >
    </>
  );
};

export default BedAddAssign;