import React, { useState } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { baseURL, successCode } from '../../configuration/url';
import { DatePicker } from '@mui/x-date-pickers';
import { authorize, formatDateToYYYYMMDD } from '../../helpers/common';
import { CloseTwoTone } from '@mui/icons-material';
import { Button } from 'primereact/button';
import { useSelector } from 'react-redux';
import { grey, primarybg, primarytext } from '../../common/primary';
import { toast } from 'react-toastify';
const ReportView = () => {
    const [data, setData] = useState<any>([]);
    const [date, setDate] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const org = useSelector((state: any) => state.Login.organization)
    const handleSubmit = async () => {
        if (!date) {
            toast.warning("Please select a date.", {
            });
            return;
        }
        const { header1 } = authorize();
        try {
            const response = await axios.get(`${baseURL}/config/getByDateShow/${date}/${org}`, { headers: header1 });
            if (response.data.message.code === successCode) {
                setData(response.data.data);
            } else {
                setData([]);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const [selectedSlotData, setSelectedSlotData] = useState<any>(null)
    const [patient, setPatient] = useState('')
    const handleIconClick = (iconId: any, patient: any) => {
        const iconData: any = data.find((items: any) => items.patient === patient);
        setPatient(patient)
        setSelectedSlotData(iconData.slotData.find((k: any) => k.q15Slot === iconId))
        setOpenModal(true);
    };
    const handleCloseModal = () => {
        setOpenModal(false);
    };
    return (
        <div className='p-2'>
            <div className="row d-flex mt-2 justify-content-center align-items-center mb-4" style={{ width: '98%' }}>
                <div className='col-md-3'>
                    <DatePicker
                        label={'Enter Date'}
                        format='MM-DD-YYYY'
                        onChange={(date: any) => {
                            setDate(formatDateToYYYYMMDD(date))
                        }}
                    />
                </div>
                <div className="col-md-2">
                    <Button
                        label="Submit"
                        style={{ width: '80%', height: '55px', backgroundColor: primarybg, fontWeight: 'bold' }}
                        onClick={handleSubmit}
                    ></Button>
                </div>
                <div className="col-md-1"></div>
                <div className="col-md-2 d-flex align-items-center">
                    <h5 className="mb-0">Q15 Summary</h5>
                </div>
                <div className="col-md-4 d-flex justify-content-end gap-3 align-items-center">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <FontAwesomeIcon
                            icon={faCircle}
                            style={{ color: '#426cc3', marginRight: '5px' }}
                        />
                        <span>No Proximity</span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <FontAwesomeIcon
                            icon={faCircle}
                            style={{ color: '#ff7171', marginRight: '5px' }}
                        />
                        <span>Missing</span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <FontAwesomeIcon
                            icon={faCircle}
                            style={{ color: '#edb405', marginRight: '5px' }}
                        />
                        <span>Late</span>
                    </div>
                </div>
            </div>

            {data?.length > 0
                ? (
                    <TableContainer component={Paper} sx={{ width: '99%' }}>
                        <Table size="small" sx={{ borderSpacing: 0 }}>
                            <TableHead sx={{ backgroundColor: '#f6f5f599', borderTop: "1px groove #c0c1c2", borderBottom: "1px groove #c0c1c2" }}>
                                <TableRow sx={{ backgroundColor: "#f6f5f599", borderTop: "1px groove #c0c1c2", borderBottom: "1px groove #c0c1c2" }}>
                                    <TableCell sx={{ textAlign: 'center', color: primarytext, borderRight: "1px groove" }}>Patient</TableCell>
                                    {Array.from({ length: 24 }, (_, index) => {
                                        const startHour = index.toString().padStart(2, '0') + ':00';
                                        const endHour = ((index + 1) % 24).toString().padStart(2, '0') + ':00';
                                        return (
                                            <TableCell sx={{ fontSize: '7px', textAlign: 'center', padding: 0, color: primarytext, whiteSpace: "nowrap", borderRight: "1px groove" }} key={index}>
                                                {startHour}-{endHour}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data?.map((item: any) => (
                                    <TableRow sx={{ fontSize: '9px' }} key={item.id}>
                                        <TableCell sx={{ fontSize: '10px', textAlign: 'start', lineHeight: '1.2', borderRight: "1px groove" }}>
                                            <div className='d-flex justify-content-between'>
                                                <p>{item.patient}</p>
                                                <p style={{ color: '#000' }}>{item.bed}</p>
                                            </div>
                                        </TableCell>
                                        {[...Array(24)].map((_, index) => {
                                            const slotId = `${index.toString().padStart(2, '0')}`;

                                            return (
                                                <TableCell sx={{ fontSize: '9px', padding: 0, borderRight: "1px groove" }} key={index}>
                                                    <div className='d-flex' style={{ justifyContent: "center" }}>
                                                        {['A', 'B', 'C', 'D'].map(iconPrefix => {
                                                            const iconId = `${iconPrefix}${slotId}`;
                                                            const iconData: any = data.find((items: any) => items.patient === item.patient);
                                                            const slotData = iconData.slotData.find((k: any) => k.q15Slot === iconId)
                                                            const color = slotData?.proximityStatus ? '#426cc3' : slotData?.slotLateEntry ? '#edb405' : slotData?.slotMissed ? '#ff7171' : !slotData ? '#ff7171' : '#355c7d52'
                                                            return (
                                                                <FontAwesomeIcon
                                                                    key={iconId}
                                                                    icon={faCircle}
                                                                    style={{ marginRight: 1, color: color, cursor: 'pointer' }}
                                                                    onClick={() => {
                                                                        handleIconClick(iconId, iconData.patient,)
                                                                    }}
                                                                />
                                                            );
                                                        })
                                                        }
                                                    </div>
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                )
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )
                : (
                    <div className='d-flex justify-content-center align-items-center text-primary' style={{ borderTop: '0.5px solid #ccc' }}>
                        <p className='my-2'>No Data found for the selected Date </p>
                    </div>
                )
            }
            <Dialog open={openModal} onClose={handleCloseModal}>
                <div style={{ height: '350px', width: '300px' }}>
                    <DialogTitle sx={{ height: '45px' }}>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div>
                                <p style={{ fontWeight: 'bold', color: '#717171' }}>{patient}</p>
                            </div>
                            <IconButton
                                aria-label="close"
                                onClick={handleCloseModal}
                                sx={{
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <CloseTwoTone />
                            </IconButton>
                        </div>
                    </DialogTitle>
                    <hr />
                    <DialogContent>
                        <DialogContentText>
                            <div style={{ height: '190px' }}>
                                {selectedSlotData ? (
                                    <>
                                        <div className='row'>
                                            <div className="col-md-6">
                                                <div>
                                                    <p style={{ color: '#000', fontSize: '12px' }}><span style={{ display: 'flex', width: '100px', fontSize: '15px', textAlign: 'left', color: '#717171' }}>Q15 Time</span> {selectedSlotData.q15Time}</p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div>
                                                    <p style={{ color: '#000', fontSize: '12px' }}><span style={{ display: 'flex', width: '100px', fontSize: '15px', textAlign: 'left', color: '#717171' }}>Incharge</span> {selectedSlotData.incharge}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-md-6">
                                                <div>
                                                    <p style={{ color: '#000', fontSize: '12px' }}><span style={{ display: 'flex', width: '100px', fontSize: '15px', textAlign: 'left', color: '#717171' }}>Entered By</span> {selectedSlotData.enteredBy}</p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div>
                                                    <p style={{ color: '#000', fontSize: '12px' }}><span style={{ display: 'flex', width: '100px', fontSize: '15px', textAlign: 'left', color: '#717171' }}>Location</span> {selectedSlotData.location}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-md-6">
                                                <div>
                                                    <p style={{ color: '#000', fontSize: '12px' }}><span style={{ display: 'flex', width: '100px', fontSize: '15px', textAlign: 'left', color: '#717171' }}>Activity</span> {selectedSlotData.activity}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <p>No Data found for the slot</p>
                                )
                                }
                                <hr />
                            </div>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <div className="d-flex gap-3 justify-content-center align-items-center" style={{ height: '5px' }}>
                            <Button label="Close" severity="" style={{ marginRight: '12px', color: '#000', backgroundColor: '#D0D8E4', fontSize: '12px', fontWeight: 'bold' }}
                                onClick={handleCloseModal}></Button>
                        </div>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    );
};
export default ReportView;
