import React from 'react'
import { Badge, Card, CardBody, CardFooter, CardSubtitle, CardTitle, Col, Row } from 'reactstrap';
import roomimg from "../../assets/images/room.svg";
import bedimg from "../../assets/images/bed.svg";
import LogoImg from "../../assets/images/profile.svg";

interface PatientBedProps {
    admitD: true
    bedId: any
    handleBedClick: any
    bedsByNumber: any
}

const PatientBed: React.FC<PatientBedProps> = ({admitD, bedId, handleBedClick, bedsByNumber}) => {
  return (
    <>
    <div className="container m15" >
      <div className="grid-templetcontainer" style={{ justifyItems: "center" }} >
        {admitD && Object.entries(bedsByNumber).map(([bedNo, assignments]: any) => (
          <Row key={bedNo}
          >
            {assignments.map((bedassign: any, index: number) => (
              bedassign.patientName !== null ? <>
                <Col key={bedassign.id} style={{ flex: 0, padding: 0 }}>
                  <div className="bed-assignment-box">
                    <Card
                      className="mb-3"
                      color="danger"
                      outline
                      style={{
                        width: "80px",
                        height: "80px",
                        margin: "5px",
                        justifyContent: "flex-start",
                      }}
                    >
                      <CardBody
                        key={index}
                        style={{ cursor: "pointer", padding: '0.6rem', display: "flex", alignItems: "center", justifyContent: "space-between", width: "108%" }}
                      >
                        <CardTitle tag="h6">
                          <img src={roomimg} style={{ width: '15px', height: '20px' }} />
                          <span style={{ marginLeft: '3px', fontSize: (bedassign.roomNo).length > 2 ? '10px' : '12px', fontWeight: 'bold' }}>{bedassign.roomNo}</span>
                        </CardTitle>
                        <CardSubtitle tag="h6" className=" text-muted">
                          <img src={bedimg} style={{ width: '15px', height: '20px' }} />
                          <span style={{ marginLeft: '3px', fontSize: '12px', fontWeight: 'bold' }}>{bedassign.bedNo}</span>
                        </CardSubtitle>
                      </CardBody>
                      <CardFooter style={{
                        padding: '7px', position: 'relative', display: 'flex', top: '-13px', height: '33px', fontSize: '10px', fontWeight: 'bold', lineHeight: 'normal', justifyContent: "space-evenly",
                        background: bedNo === '01' ? '#f8b19570' : (bedNo === '02' ? '#c06c8470' : (bedNo === '03' ? '#355c7d52' : '#FFFFFF'))
                      }}>
                        <img src={LogoImg} style={{ width: '20px', height: '20px' }}></img><span className="truncate-hover" style={{ paddingLeft: '5px', lineHeight: 2 }}>{bedassign.patientName}</span>
                      </CardFooter>
                    </Card>
                  </div>
                </Col>
              </> : <>
                <Col key={index} style={{ flex: 0, padding: 0 }}>
                  <div className="bed-assignment-box">
                    <Card key={index}
                      className="mb-3"
                      color="primary"
                      outline
                      style={{
                        width: "80px",
                        height: "80px",
                        margin: "5px",
                        border: bedassign.id === bedId ? "groove" : "",
                        justifyContent: "flex-start",
                      }}
                    >
                      <CardBody
                        key={index}
                        onClick={() => handleBedClick(bedassign)}
                        style={{ cursor: "pointer", padding: '0.6rem', display: "flex", alignItems: "center", justifyContent: "space-between", width: "108%" }}
                      >
                        <CardTitle tag="h6">
                          <img src={roomimg} style={{ width: '15px', height: '20px' }} />
                          <span style={{ marginLeft: '3px', fontSize: (bedassign.roomNo).length > 2 ? '10px' : '12px', fontWeight: 'bold' }}>{bedassign.roomNo}</span>
                        </CardTitle>
                        <CardSubtitle tag="h6" className="text-muted ">
                          <img src={bedimg} style={{ width: '15px', height: '20px' }} />
                          <span style={{ marginLeft: '3px', fontSize: '12px', fontWeight: 'bold' }}>{bedassign.bedNo}</span>
                        </CardSubtitle>
                      </CardBody>

                      <CardFooter style={{
                        padding: '2px 0.6rem 5px 13px ', position: 'relative', top: '-13px', height: '33px',
                        background: bedNo === '01' ? '#f8b19570' : (bedNo === '02' ? '#c06c8470' : (bedNo === '03' ? '#355c7d52' : '#FFFFFF'))
                      }}>

                        <Badge
                          style={{ fontSize: '10px' }}
                          color={bedassign.pid ? "danger" : "success"}
                          tag="h6"
                        >
                          {bedassign.pid ? "Not Available" : "Available"}
                        </Badge>
                      </CardFooter>
                    </Card>
                  </div>
                </Col>
              </>
            ))}
          </Row>
        ))}
      </div>
    </div>    
    </>
  )
}

export default PatientBed