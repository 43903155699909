import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import "./beacon.css";
import { baseURL, successCode } from "../../configuration/url";
import { TbDeviceWatchPlus } from "react-icons/tb";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getAllBeacon, getAllGateWayByOrg, getAllSirenByOrg, updatedSensorDetails } from "../../slices/beaconDevices/thunk";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {
  TextField,
  Tooltip,
  Autocomplete
} from "@mui/material";
import Box from '@mui/material/Box';
import TabContext from '@mui/lab/TabContext';
import Tab from '@mui/material/Tab';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Button } from 'primereact/button';
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { FaArrowAltCircleLeft, FaQrcode, FaSearch } from "react-icons/fa";
import BeaconCreation from "./beaconCreation";
import { BrowserMultiFormatReader } from "@zxing/library";
import Trashimg from "./../../assets/images/VscTrash.png"
import editimg from "./../../assets/images/Vectorpencil.png"
import { authorize } from "../../helpers/common";
import { Paginator } from "primereact/paginator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import LogoutConfirmationModal from "../../components/LogoutModel";
import { Console } from "console";
import { grey, primarytext } from "../../common/primary";
import SirenCreation from "./sirenCreation";
import GatewayCreation from "./gatewayCreation";
import { getBeaconSuccess, getGateWaySuccess, getSirenSuccess } from "../../slices/beaconDevices/reducer";
import Loader from "../../components/loader/Loader";
interface DropdownItem {
  id: string;
  value: string;
  type: string;
}

interface Dropdown {
  id: string;
  dropdown: string;
  list: DropdownItem[];
}

const QRCodeScanner: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [dropdownData, setDropdownData] = useState<Dropdown[]>([]);
  const [dropdownData1, setDropdownData1] = useState<any>([])
  const [editModal, setEditModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [sirenCurrentPage, setSirenCurrentPage] = useState(0)
  const [gateWayCurrentPage, setGateWayCurrentPage] = useState(0)
  const dispatch = useDispatch<any>();
  const { organization } = useSelector((state: any) => state.Login);
  const orgId = params?.id ? params.id : organization;
  const { loading ,beaconData, totalElements, sirenData, sirenTotalElements, gateWayData, gateWayTotalElements } = useSelector((state: any) => state.Beacon);
  const [modal, setModal] = useState(false);
  const [newModal, setNewModal] = useState(false);
  const videoRef = useRef(null);
  const [deviceName, setDeviceName] = useState("");
  let [deviceType, setDeviceType] = useState("");
  const [modelNumber, setModelNumber] = useState("");
  const [uuid, setUuid] = useState("");
  const [deviceId, setDeviceId] = useState("");
  const codeReader = new BrowserMultiFormatReader();
  const [options, setOptions] = useState<any>([]);
  const [scanning, setScanning] = useState(false);
  const orgName = window.localStorage.getItem("OrgName");
  const [searchText, SetsearchText] = useState("");
  const [showTextField, setShowTextField] = useState(false);
  const [qrReader, setQrReader] = useState<any>('')
  const [test, setTest] = useState<string>('')
  const inputRef = useRef<HTMLInputElement>(null);
  const [sirenRegisterModal, setSirenRegisterModal] = useState<boolean>(false)
  const [gateWayRegisterModal, setGateWayRegisterModal] = useState<boolean>(false)
  // const [sirenData, setSirenData] = useState<any>()
  // const [gateWayData, setGateWayData] = useState<any>()
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  let [sensorData, setSensorData] = useState({
    id: "",
    uuid: "",
    deviceId: "",
    deviceName: "",
    BeaconType: "",
    modelNumber: '',
    orgId: orgId,
  });
  const [sirenEditData, setSirenEditData] = useState<any>()
  const [gateWayEditData, setGateWayEditData] = useState<any>()
  const [sirenDeleteModal, setSirenDeleteModal] = useState<boolean>(false)
  const [gateWayDeleteModal, setgateWayDeleteModal] = useState<boolean>(false)
  const [sirenDeviceID, setSirenDeviceID] = useState<any>()
  const [gateWayDeviceID, setgateWayDeviceID] = useState<any>()
  const [sirenSerachText, setSirenSerachText] = useState<string>('')
  const [gatewaySerachText, setGateWaySerachText] = useState<string>('')
  // const [loading, setLoading] = useState<boolean>(false)

  const handleQrReaderClick = () => {
    setShowTextField(true);
  };
  useEffect(() => {
    if (qrReader.length < 12) {
      setQrReader('')
      return
    };
    handleQrValueChange()
  }, [qrReader])
  const handleQrValueChange = () => {
    if (qrReader.length >= 12) {
      // setQrReader(qrReader);

      let newDeviceId = '';
      if (qrReader.length < 15) {
        const resultId = qrReader.match(/.{2}/g)?.join(':');
        newDeviceId = resultId;
      } else {
        const resultId = qrReader.match(/.{2}/g)?.join(':');
        newDeviceId = resultId.slice(6, 23);
        setDeviceName(qrReader.slice(24, 30));
      }

      setDeviceId(newDeviceId);
      handleShow1Close();
      setNewModal(true);
    }
  };


  useEffect(() => {
    if (showTextField && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showTextField]);

  useEffect(() => {
    if (scanning) {
      startScanning();
    } else {
      codeReader.reset();
    }

    return () => {
      codeReader.reset();
    };
  }, [scanning]);

  const startScanning = async () => {
    try {
      setScanning(true);
      const videoInputDevices = await codeReader.listVideoInputDevices();
      const selectedDeviceId = videoInputDevices[0].deviceId;
      const constraints = {
        video: { deviceId: selectedDeviceId },
      };
      codeReader.decodeFromVideoDevice(
        selectedDeviceId,
        videoRef.current,
        (result: any, err: any) => {
          if (result) {
            const result1 = result.getText();
            let newDeviceId = "";
            if (result1 !== "" && result1 !== undefined && result1 !== null) {
              if (result1.length < 15) {
                const resultId = result1.match(/.{2}/g).join(':');
                newDeviceId = resultId;
              } else {
                const resultId = result1.match(/.{2}/g).join(':');
                newDeviceId = resultId.slice(6, 23);
                setDeviceName(result1.slice(24, 30));
              }
              setShow(false);
              setScanning(false);
            }
            const scannedOption = options.find((option: any) => option.deviceId === newDeviceId);
            if (scannedOption === undefined) {
              setNewModal(true);
              setDeviceId(newDeviceId);
              const modal = document.getElementById("exampleModal");
              if (modal) {
                modal.classList.add("show");
                modal.style.display = "block";
              }
            } else {
              setDeviceId("");
              toast.error("Given Beacon Device was registered.");
            }
          }
          if (err && err.name === "NotFoundError") {
            console.error("No QR code found in the video feed.");
          }
          if (err) {
            console.error("Error during scanning:", err);
          }
        },
      );
    } catch (error) {
      console.error("Error starting the scanner:", error);
    }
  };


  const handleClose1 = () => {
    setShow(false)
    setScanning(false)
    codeReader.reset()
  };
  const handleShow1Close = () => {
    setShow1(false)
    setQrReader('')
    setShowTextField(false)
  }

  const handleDeviceNameChange = (e: any) => {
    setDeviceName(e.target.value);
  };

  const handleUUIDChange = (e: any) => {
    setUuid(e.target.value);
  };

  const closeModalAndRec = () => {
    setShow(false)
    setScanning(false)
    codeReader.reset()
  }

  const handleQrClick = () => {
    setShow1(false)
    setShow(true);
    setDeviceId("")
    setDeviceName("")
    setScanning(!scanning);
  }
  const handleQrClick1 = () => {
    setShow1(true)
  }

  const postAPI = async () => {
    // let convertedDId = deviceId;
    // convertedDId = convertedDId.replace(/(.{2})/g, "$1:");
    // convertedDId = convertedDId.slice(0, -1);
    const { header1 } = authorize();
    try {
      const res = await axios.post(`${baseURL}/sensor/register`, {
        deviceId,
        deviceName,
        uuid,
        orgId,
        deviceType,
        modelNumber
      }, { headers: header1 });
      if (res.data.message.code === successCode) {
        handleClose1();
        setNewModal(false)
        setDeviceId("");
        setDeviceName("");
        setUuid("");
        setDeviceType("");
        setModelNumber("");
        getAllBeacon(dispatch, orgId, currentPage, '');
      } else {
        handleClose1();
        setDeviceId("");
        setDeviceName("");
        setUuid("");
        setDeviceType("");
        setModelNumber("");
        // alert(res.data.message.description);
        toast.error(res.data.message.description)
      }
    } catch (error) {
      console.warn(error);
      handleClose1();
      setDeviceId("");
      setDeviceName("");
    }
  };

  const ScanModalClose = () => {
    setNewModal(false)
    handleClose1();
    setDeviceId("");
    setDeviceName("");
    setUuid("");
    setDeviceType("");
    setModelNumber("");
  }

  const toggle = () => {
    setModal(!modal);
  }

  useEffect(() => {
    const fetchDropdownData = async () => {
      const { header1 } = authorize();
      try {
        const response = await axios.get(`${baseURL}/dropdowns/getByDropdown?dropdown=Hospital Location`, { headers: header1 });
        if (response && response.data.message && response.data.message.code === successCode) {
          setDropdownData1(response.data.data[0].list);
        } else {
          setDropdownData1([]);
          console.error(
            "Error fetching dropdown data:",
            response.data.message.description
          );
        }
      } catch (error) {
        console.error("Error fetching dropdown data:", error);
      }
    };
    fetchDropdownData();
  }, []);

  useEffect(() => {
    const fetchDropdownData = async () => {
      const { header1 } = authorize();
      try {
        const response = await axios.get(`${baseURL}/dropdowns/getByDropdown?dropdown=BeaconType`, { headers: header1 });
        if (response.data.message.code === 'MHC - 0200') {
          setDropdownData(response.data.data[0].list);
        } else {
          console.error('Error fetching dropdown data:', response.data.message.description);
        }
      } catch (error) {
        console.error('Error fetching dropdown data:', error);
      }
    };
    const fetchData = async () => {
      const { header1 } = authorize();
      try {
        const response = await axios.get(`${baseURL}/sensor/getAllByorgId/${orgId}`, { headers: header1 });
        setOptions(response.data.data.content);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
    fetchDropdownData();
  }, []);
  const [open, setOpen] = useState(false)

  const handleDelete = (deviceName: any) => {
    setOpen(true);
    setDeviceName(deviceName);

  }
  const handleClo = () => {
    setOpen(false)
    setDeviceName('');
  }

  const handleSirenDelete = (item: any) => {
    setSirenDeleteModal(true)
    setSirenDeviceID(item)
  }
  const handleSirenClo = () => {
    setSirenDeleteModal(false)
    setSirenDeviceID('')
  }

  const handleGateWayDelete = (item: any) => {
    setgateWayDeleteModal(true)
    setgateWayDeviceID(item)
  }
  const handleGateWayClo = () => {
    setgateWayDeleteModal(false)
    setgateWayDeviceID('')
  }

  const deleteAPI = async (deviceName: any) => {
    // const del = window.confirm(`Do you want to delete this data?  ${deviceName}`);
    // if (del) {
    const { header1 } = authorize();
    try {
      const res = await axios.delete(
        `${baseURL}/sensor/deleteByDeviceName/${deviceName}`, { headers: header1 }
      );
      handleClo()
      toast.success("Your data has been deleted successfully.");
      getAllBeacon(dispatch, orgId, currentPage, '');
    } catch (error) {
      console.warn(error);
      // }
      // } else {
      //   toast.error("You declined the delete request.");
    }
  };

  const deleteSirenData = async (sirenID: string) => {
    try {
      const res = await axios.delete(`${baseURL}/siren/deleteSiren?id=${sirenID}`)
      toast.success("Your data has been deleted successfully.");
      handleSirenClo()
    } catch (error) {
      console.warn('API Error: ', error)
    }
  }

  const deleteGateWay = async (gateWayID: string) => {
    try {
      const res = await axios.delete(`${baseURL}/gateway/deleteGateway?id=${gateWayID}`)
      toast.success("Your data has been deleted successfully.");
      handleGateWayClo()
    } catch (error) {
      console.warn('API Error: ', error)
    }
  }

  useEffect(() => {
    getAllBeacon(dispatch, orgId, searchText ? 0 : currentPage, searchText ? searchText : '');
    return () => {
      dispatch(getBeaconSuccess([]))
      dispatch(getSirenSuccess([]))
      dispatch(getGateWaySuccess([]))
    }
  }, [dispatch, searchText]);

  useEffect(() => {
    getAllSirenByOrg(dispatch, orgId, sirenSerachText ? 0 : sirenCurrentPage, sirenSerachText ? sirenSerachText : '' )
  }, [dispatch,sirenSerachText,sirenDeleteModal,sirenRegisterModal])

  useEffect(() => {
    getAllGateWayByOrg(dispatch, orgId, gatewaySerachText ? 0 : gateWayCurrentPage, gatewaySerachText ? gatewaySerachText : '')
  }, [dispatch, gatewaySerachText, gateWayDeleteModal, gateWayRegisterModal ])

  const handleSave = async () => {
    const updateFields = {
      id: sensorData.id,
      uuid: sensorData.uuid,
      deviceId: sensorData.deviceId,
      deviceName: sensorData.deviceName,
      deviceType: sensorData.BeaconType,
      modelNumber: sensorData.modelNumber,
      orgId: orgId,
    };

    setSensorData((prevData) => ({
      ...prevData,
      ...updateFields,
    }));

    try {
      await updatedSensorDetails(
        dispatch,
        sensorData.id,
        updateFields,
        orgId
      );

      setEditModal(false);
      getAllBeacon(dispatch, orgId, currentPage, '')
    } catch (error) {
      console.error("Error updating sensor details:", error);
    }
  };

  const handleClick = (selectSensor: any) => {
    if (selectSensor) {
      setSensorData({
        id: selectSensor?.id,
        uuid: selectSensor?.uuid || "",
        deviceId: selectSensor?.deviceId || "",
        deviceName: selectSensor?.deviceName || "",
        BeaconType: selectSensor?.deviceType || "",
        modelNumber: selectSensor?.modelNumber || "",
        orgId: orgId,
      });
      setEditModal(true);
    } else {
      console.warn("Invalid data", selectSensor);
    }
  };

  const handleSearchChange = (e: any) => {
    SetsearchText(e.target.value);
  }

  const handleSirenSearchChange = (e:any) => {
    setSirenSerachText(e.target.value)
  }
  
  const handleGateWayChange = (e: any) => {
    setGateWaySerachText(e.target.value)
  }

  const handleChange1 = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSensorData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleModelChange = (event: any, newValue: any) => {
    let modelNumber = '';
    if (newValue === "W7") {
      modelNumber = "METTLER WRISTBAND BEACON";
    } else if (newValue === "W8") {
      modelNumber = "METTLER SOS BEACON";
    } else if (newValue === "KG02") {
      modelNumber = "METTLER BEACON GATEWAY";
    } else if (newValue === "WH15") {
      modelNumber = "METTLER HR WRISTBAND BEACON";
    }
    setDeviceType(modelNumber)
    setModelNumber(newValue)
  };
  const handleModelChange1 = (event: any, newValue: any) => {
    let modelNumber = '';
    if (newValue === "W7") {
      modelNumber = "METTLER WRISTBAND BEACON";
    } else if (newValue === "W8") {
      modelNumber = "METTLER SOS BEACON";
    } else if (newValue === "KG02") {
      modelNumber = "METTLER BEACON GATEWAY";
    } else if (newValue === "WH15") {
      modelNumber = "METTLER HR WRISTBAND BEACON";
    }

    setSensorData(prevState => ({
      ...prevState,
      BeaconType: modelNumber,
      modelNumber: newValue,
    }));
  };

  const handleDeviceTypeChange = (event: any) => {
    setDeviceType(event.target.value);
  };
  const [value, setValue] = React.useState('1');
  const handleChange = (event: any, newValue: any) => {
    setValue(newValue)
  };

  const handleSirenModal = () => {
    setSirenRegisterModal(!sirenRegisterModal)
  }
  const handleGatewayModal = () => {
    setGateWayRegisterModal(!gateWayRegisterModal)
  }
  return (
    <div className="container ">
      {loading && <Loader/>}
      <Box>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              {params?.id && (<FaArrowAltCircleLeft style={{ cursor: 'pointer', fontSize: '25px', marginTop: '15px', marginRight: '10px' }} onClick={() => navigate(-1)} />)}
              <Tab label="Beacon" value="1" />
              <Tab label="Siren" value="2" />
              <Tab label="GateWay" value="3" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <div className="m-0 p-0">
              <div className="row">
                <div className="col-md-3 d-flex align-items-center">
                  <h5 className="">Beacon/Device List</h5>
                </div>

                {params?.id ? (
                  <div className="col-md-3 d-flex justify-content-center align-items-center">
                    <h5>{orgName}</h5>
                  </div>
                ) : (
                  <div className="col-md-3 d-flex align-items-center"></div>
                )
                }
                <div className="col-md-6">
                  <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px", paddingBottom: "10px", paddingTop: "10px" }}>
                    {params.id && (<div >
                      <button
                        className="btn btn-outline-primary floflat-end d-flex gap-2 align-items-center"
                        style={{ fontSize: '10px', width: '100px', height: '30px' }}
                        onClick={handleQrClick1}
                      >
                        <FaQrcode
                          style={{ cursor: "pointer", fontSize: "17px" }}
                        />{" "}
                        Scan
                      </button>
                    </div>)}
                    {params?.id && (<div >
                      <button
                        className="btn btn-outline-primary floflat-end d-flex gap-2 align-items-center"
                        style={{ fontSize: '10px', width: '160px', height: '30px' }}
                        onClick={toggle}
                      >
                        <TbDeviceWatchPlus
                          style={{ cursor: "pointer", fontSize: "20px" }}
                        />{" "}
                        Register a New Device
                      </button>
                    </div>)}
                    <div >
                      <div className="mx-0 search-container d-flex align-items-center">
                        <input
                          type="text"
                          placeholder="Search..."
                          className="search form-control"
                          value={searchText}
                          onChange={handleSearchChange}
                          style={{ fontSize: "10px", width: '170px', height: '30px' }}
                        />
                        <FaSearch className="search-icon mt-1" style={{ fontSize: "10px" }} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div >
                <table className="table table-bordered" style={{ fontSize: '13px' }}>
                  <thead style={{ backgroundColor: grey }}>
                    <tr >
                      <th scope="col" className="text-center" style={{ fontSize: '13px', color: primarytext, backgroundColor: "#dee2e638" }}>S.No</th>
                      <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: "#dee2e638" }}>Device Serial Number</th>
                      <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: "#dee2e638" }}>Device ID</th>
                      <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: "#dee2e638" }}>Unique ID</th>
                      <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: "#dee2e638" }}>Model</th>
                      <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: "#dee2e638" }}>Device Type</th>
                      <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: "#dee2e638" }}>Status</th>
                      {params.id && (<th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: "#dee2e638" }}>Action</th>)}
                    </tr>
                  </thead>
                  <tbody className="tbody">
                    {beaconData.length > 0 ? beaconData.map((item: any, index: any) => (
                      <tr key={item.id}>
                        <td>{currentPage * 10 + index + 1}</td>
                        <td
                          style={{ cursor: "pointer" }}
                          onClick={params.id ? () => handleClick(item) : () => { }}>
                          {item.deviceName}
                        </td>
                        <td>{item.deviceId}</td>
                        <td>{item.uuid}</td>
                        <td>{item.modelNumber}</td>
                        <td>{(item.deviceType) ? item.deviceType : 'NA'}</td>
                        <td className="text-center">
                          <Tooltip title={!(item.status) ? "Available" : "Occupied"} arrow>
                            <FontAwesomeIcon
                              icon={faCircle}
                              style={{
                                fontSize: "13px",
                                cursor: "pointer",
                                color: !(item.status) ? '#5FB477' : '#EF6868',
                                display: "inline-block",
                                textAlign: "center",
                              }}
                            />
                          </Tooltip>
                        </td>
                        {params.id && (<td className="text-center d-flex justify-content-around align-items-center">
                          <Tooltip title="Edit" arrow>
                            <div onClick={() => handleClick(item)} style={{ cursor: "pointer" }}><img style={{ height: "15px", width: "15px" }} src={editimg} /></div>
                          </Tooltip>
                          <Tooltip title="Delete" arrow>
                            <div onClick={() => handleDelete(item.deviceName)} style={{ cursor: "pointer" }}><img style={{ height: "15px", width: "15px" }} src={Trashimg} /></div>
                          </Tooltip>
                        </td>)}
                      </tr>
                    )) :
                      <tr>
                        <td colSpan={7} className="text-center">
                          <h5>No Beacons found</h5>
                        </td>
                      </tr>
                    }
                  </tbody>
                </table>
                <BeaconCreation modal={modal} orgId1={params?.id ? orgId : null} toggle={toggle} currentPage={currentPage} />

              </div>
              <div className="pagination-container" >
                <div className="d-flex justify-content-center">
                  <Paginator
                    first={currentPage * 10}
                    rows={10}
                    totalRecords={totalElements}
                    onPageChange={(e: any) => {
                      setCurrentPage(e.page)
                      getAllBeacon(dispatch, orgId, e.page, searchText ? searchText : '')
                    }}
                    currentPageReportTemplate={`Page ${currentPage} of ${totalElements / 10}`}
                  />
                </div>
              </div>
              <Modal isOpen={editModal} centered size="lg" style={{ width: '600px' }}>
                <div className="d-flex justify-content-start align-items-center">
                  <div className="row">
                    <div className="col-md-12">
                      <ModalHeader toggle={() => setEditModal(false)}>
                        Device Details
                      </ModalHeader>
                    </div>
                    <ModalBody>
                      <div className="d-flex align-items-center justify-content-center vh-90">
                        <div className="row w-100">
                          <div className="container col-md-12 ">
                            <div className="row w-100" style={{ alignItems: "center", justifyContent: "center", marginTop: '10px' }}>
                              <div className="col-md-12 mb-2">
                                <TextField id="outlined-basic-1" label="Unique Id(UUID)" variant="outlined" fullWidth onChange={handleChange1} value={sensorData.uuid} name="uuid" />
                              </div>
                            </div>
                            <div className="row w-100" style={{ alignItems: "center", justifyContent: "center", marginTop: '10px' }}>
                              <div className="col-md-6 mb-2">
                                <TextField id="outlined-basic-1" label="Mac Address" variant="outlined" fullWidth onChange={handleChange1} value={sensorData.deviceId} name="deviceId" />
                              </div>
                              <div className="col-md-6 mb-2">
                                <TextField id="outlined-basic-1" label="Serial Number" variant="outlined" fullWidth onChange={handleChange1} value={sensorData.deviceName} name="deviceName" />
                              </div>
                            </div>
                            <div className="row w-100" style={{ alignItems: "center", justifyContent: "center", marginTop: '10px' }}>
                              <div className="col-md-6 mb-2">
                                <Autocomplete
                                  id='model'
                                  options={dropdownData?.map((item: any) => item.value)}
                                  value={sensorData.modelNumber}
                                  onChange={handleModelChange1}
                                  renderInput={(params) => <TextField {...params} label="Model" variant="outlined" />}
                                />
                              </div>
                              <div className="col-md-6 mb-2">
                                <TextField
                                  id="outlined-basic-1"
                                  label="Device Type"
                                  variant="outlined"
                                  fullWidth
                                  value={sensorData.BeaconType ? sensorData.BeaconType : 'N/A'}
                                  name="modelNumber"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ModalBody>
                  </div>
                </div>

                <ModalFooter className="">
                  <div className="d-flex gap-3 justify-content-center">
                    <Button label="Cancel" severity="secondary" style={{ color: '#000', backgroundColor: '#94a0b7', fontSize: '12px', fontWeight: 'bold' }} onClick={() => setEditModal(false)}></Button>
                    <Button label="Save Changes" style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold' }} onClick={handleSave}></Button>
                  </div>
                </ModalFooter>
              </Modal>
              <Modal centered isOpen={show} toggle={handleClose1}>
                <ModalHeader toggle={handleClose1}>Scanning</ModalHeader>
                <ModalBody>
                  <video ref={videoRef} style={{ display: scanning ? "block" : "none", width: '100%', height: '400px' }} />
                </ModalBody>
                <ModalFooter>
                  <div className="d-flex gap-3 justify-content-center">
                    <Button label="Cancel" severity="secondary" style={{ color: '#000', backgroundColor: '#94a0b7', fontSize: '12px', fontWeight: 'bold' }} onClick={handleClose1}></Button>
                    <Button label={scanning ? "Stop Scanning" : "Start Scanning"} style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold' }} onClick={closeModalAndRec}></Button>
                  </div>
                </ModalFooter>
              </Modal>
              <Modal centered isOpen={show1} toggle={handleShow1Close} >
                <ModalHeader toggle={handleShow1Close}>Select the scanning Option</ModalHeader>
                <ModalBody style={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                      label="Start Scan"
                      style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold', marginRight: 10 }}
                      onClick={handleQrClick}
                    >
                    </Button>
                    <Button
                      label="QR Reader"
                      style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold' }}
                      onClick={handleQrReaderClick}
                    >
                    </Button>
                  </div>
                  {showTextField && (
                    <div style={{ marginTop: 10 }}>
                      <TextField
                        id="qrInput"
                        label="Scan a QR using QR reader"
                        variant="outlined"
                        fullWidth
                        inputRef={inputRef}
                        value={test}
                        focused
                        // disabled
                        onKeyDown={() => setTest('')}
                        onKeyUp={() => setTest('')}
                        onChange={(e: any) =>{
                          setTest(e.target.value)
                          setQrReader(qrReader + e.target.value)
                        }}
                        
                      />
                    </div>
                  )}
                </ModalBody>
                <ModalFooter>
                  <div className="d-flex gap-3 justify-content-center"></div>
                </ModalFooter>
              </Modal>
              <Modal isOpen={newModal} centered size="lg" style={{ width: '600px' }}>
                <div className="d-flex justify-content-start align-items-center">
                  <div className="row">
                    <div className="col-md-12">
                      <ModalHeader toggle={() => setNewModal(false)}>
                        Add Device
                      </ModalHeader>
                    </div>
                    <ModalBody>
                      <div className="d-flex justify-content-center">
                        <div className="row w-100">
                          <div className="container col-md-12 ">
                            <div className="row w-100" style={{ alignItems: "center", justifyContent: "center", marginTop: '10px' }}>
                              <div className="col-md-12 mb-2">
                                <TextField
                                  id="outlined-basic-1"
                                  label="Unique Id (UUID)"
                                  variant="outlined"
                                  fullWidth
                                  onChange={handleUUIDChange}
                                  value={uuid}
                                />
                              </div>
                            </div>
                            <div className="row w-100" style={{ alignItems: "center", justifyContent: "center", marginTop: '10px' }}>
                              <div className="col-md-6 mb-2">
                                <TextField
                                  id="outlined-basic-2"
                                  label="MAC Address"
                                  variant="outlined"
                                  fullWidth
                                  value={deviceId}
                                />
                              </div>
                              <div className="col-md-6 mb-2">
                                <TextField
                                  id="outlined-basic-4"
                                  label="Serial Number"
                                  variant="outlined"
                                  fullWidth
                                  onChange={handleDeviceNameChange}
                                  value={deviceName}
                                />
                              </div>
                            </div>
                            <div className="row w-100" style={{ alignItems: "center", justifyContent: "center", marginTop: '10px' }}>
                              <div className="col-md-6 mb-2">
                                <Autocomplete
                                  id="Model"
                                  options={dropdownData?.map((item: any) => item.value)}
                                  value={modelNumber}
                                  onChange={handleModelChange}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                      label="Model"
                                      fullWidth
                                    />
                                  )}
                                />
                              </div>
                              <div className="col-md-6 mb-2">
                                <TextField
                                  id="outlined-basic-3"
                                  label="Device Type"
                                  variant="outlined"
                                  fullWidth
                                  value={deviceType}
                                  onChange={handleDeviceTypeChange}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ModalBody>
                  </div>
                </div>

                <ModalFooter className="">
                  <div className="d-flex gap-3 justify-content-center">
                    <Button label="Cancel" severity="secondary" style={{ color: '#000', backgroundColor: '#94a0b7', fontSize: '12px', fontWeight: 'bold' }} onClick={ScanModalClose}></Button>
                    <Button label="Save Changes" style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold' }} onClick={postAPI}></Button>
                  </div>
                </ModalFooter>
              </Modal>
              <LogoutConfirmationModal
                open={open}
                handleConfirm={() => deleteAPI(deviceName)}
                handleClose={() => handleClo()}
                message={`Do you want to delete this data?  ${deviceName}`}
              />
            </div>
          </TabPanel>
          <TabPanel value="2">
            <div style={{ width: '100%' }} className="m-0 p-0">
              <div className="row ">
                <div className="col-md-3 d-flex align-items-center"><h5>Siren Details</h5></div>

                {params?.id ? (
                  <div className="col-md-2 d-flex justify-content-center align-items-center">
                    <h5>{orgName}</h5>
                  </div>
                ) : (
                  <div className="col-md-2 d-flex align-items-center"></div>
                )
                }
                
                <div className="col-md-7">
                  <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px", paddingBottom: "10px", paddingTop: "10px" }}>
                    {params?.id && (<div >
                      <button
                        className="btn btn-outline-primary floflat-end d-flex gap-2 align-items-center"
                        style={{ fontSize: '10px', width: '100px', height: '30px' }}
                        onClick={() => {
                          setSirenEditData(null)
                          handleSirenModal()
                        }}
                      >
                        <TbDeviceWatchPlus
                          style={{ cursor: "pointer", fontSize: "20px" }}
                        />{" "}
                        Register
                      </button>
                    </div>)}
                    <div >
                      <div className="mx-0 search-container d-flex align-items-center">
                        <input
                          type="text"
                          placeholder="Search..."
                          className="search form-control"
                          value={sirenSerachText}
                          onChange={handleSirenSearchChange}
                          style={{ fontSize: "10px", width: '170px', height: '30px' }}
                        />
                        <FaSearch className="search-icon mt-1" style={{ fontSize: "10px" }} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <table className="table table-bordered" style={{ fontSize: '13px' }}>
                <thead style={{ backgroundColor: grey }}>
                  <tr >
                    <th scope="col" className="text-center" style={{ fontSize: '13px', color: primarytext, backgroundColor: "#dee2e638" }}>S.No</th>
                    <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: "#dee2e638" }}>ID</th>
                    <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: "#dee2e638" }}>Siren Name</th>
                    <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: "#dee2e638" }}>Location</th>
                    {params.id && (<th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: "#dee2e638" }}>Action</th>)}
                  </tr>
                </thead>
                <tbody className="tbody text-center">
                  {sirenData?.length > 0 ? sirenData?.map((item: any, index: any) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.virtualId}</td>
                      <td>{item.sirenName}</td>
                      <td>{item.location}</td>
                      {params.id && (<td className="text-center d-flex justify-content-around align-items-center">
                        <Tooltip title="Edit" arrow onClick={() => {
                          setSirenEditData(item)
                          handleSirenModal()
                        }}
                        >
                          <div style={{ cursor: "pointer" }}><img style={{ height: "15px", width: "15px" }} src={editimg} /></div>
                        </Tooltip>
                        <Tooltip title="Delete" arrow onClick={() => { handleSirenDelete(item) }}>
                          <div style={{ cursor: "pointer" }}><img style={{ height: "15px", width: "15px" }} src={Trashimg} /></div>
                        </Tooltip>
                      </td>)}
                    </tr>
                  )) :
                    <tr>
                      <td colSpan={7} className="text-center">
                        <h5>No Siren found</h5>
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
              <div className="pagination-container" >
                <div className="d-flex justify-content-center">
                  <Paginator
                    first={sirenCurrentPage * 10}
                    rows={10}
                    totalRecords={sirenTotalElements}
                    onPageChange={(e: any) => {
                      setCurrentPage(e.page)
                      getAllBeacon(dispatch, orgId, e.page, sirenSerachText ? sirenSerachText : '')
                    }}
                    currentPageReportTemplate={`Page ${sirenCurrentPage} of ${sirenTotalElements / 10}`}
                  />
                </div>
              </div>
              <SirenCreation isOpen={sirenRegisterModal} sirenData={sirenEditData} orgID={orgId} CloseModal={handleSirenModal} dropdownData={dropdownData1} />
              <LogoutConfirmationModal
                open={sirenDeleteModal}
                handleConfirm={() => deleteSirenData(sirenDeviceID?.id)}
                handleClose={() => handleSirenClo()}
                message={`Do you want to delete this data?  ${sirenDeviceID?.virtualId}`}
              />
            </div>
          </TabPanel>
          <TabPanel value="3">
            <div style={{ width: '100%' }}>
              <div className="row">
                <div className="col-md-3 d-flex align-items-center"><h5>GateWay Details</h5></div>
                {params?.id ? (
                  <div className="col-md-2 d-flex justify-content-center align-items-center">
                    <h5>{orgName}</h5>
                  </div>
                ) : (
                  <div className="col-md-2 d-flex align-items-center"></div>
                )
                }
                
                <div className="col-md-7">
                  <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px", paddingBottom: "10px", paddingTop: "10px" }}>
                    {params?.id && (<div >
                      <button
                        className="btn btn-outline-primary floflat-end d-flex gap-2 align-items-center"
                        style={{ fontSize: '10px', width: '100px', height: '30px' }}
                        onClick={() => {
                          setGateWayEditData(null)
                          handleGatewayModal()
                        }}
                      >
                        <TbDeviceWatchPlus
                          style={{ cursor: "pointer", fontSize: "20px" }}
                        />{" "}
                        Register
                      </button>
                    </div>)}
                    <div >
                      <div className="mx-0 search-container d-flex align-items-center">
                        <input
                          type="text"
                          placeholder="Search..."
                          className="search form-control"
                          value={gatewaySerachText}
                          onChange={handleGateWayChange}
                          style={{ fontSize: "10px", width: '170px', height: '30px' }}
                        />
                        <FaSearch className="search-icon mt-1" style={{ fontSize: "10px" }} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <table className="table table-bordered" style={{ fontSize: '13px' }}>
                <thead style={{ backgroundColor: grey }}>
                  <tr >
                    <th scope="col" className="text-center" style={{ fontSize: '13px', color: primarytext, backgroundColor: "#dee2e638" }}>S.No</th>
                    <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: "#dee2e638" }}>ID</th>
                    <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: "#dee2e638" }}>Gateway Name</th>
                    <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: "#dee2e638" }}>Location</th>
                    {params.id && (<th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: "#dee2e638" }}>Action</th>)}
                  </tr>
                </thead>
                <tbody className="tbody text-center">
                  {gateWayData?.length > 0 ? gateWayData?.map((item: any, index: any) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.gatewayId}</td>
                      <td>{item.gatewayName}</td>
                      <td>{item.location}</td>
                      {params.id && (<td className="text-center d-flex justify-content-around align-items-center">
                        <Tooltip title="Edit" arrow onClick={() => {
                          setGateWayEditData(item)
                          handleGatewayModal()
                        }}
                        >
                          <div style={{ cursor: "pointer" }}><img style={{ height: "15px", width: "15px" }} src={editimg} /></div>
                        </Tooltip>
                        <Tooltip title="Delete" arrow onClick={() => { handleGateWayDelete(item) }}>
                          <div style={{ cursor: "pointer" }}><img style={{ height: "15px", width: "15px" }} src={Trashimg} /></div>
                        </Tooltip>
                      </td>)}
                    </tr>
                  )) :
                    <tr>
                      <td colSpan={7} className="text-center">
                        <h5>No GateWay found</h5>
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
              
              <div className="pagination-container" >
                <div className="d-flex justify-content-center">
                  <Paginator
                    first={gateWayCurrentPage * 10}
                    rows={10}
                    totalRecords={gateWayTotalElements}
                    onPageChange={(e: any) => {
                      setCurrentPage(e.page)
                      getAllGateWayByOrg(dispatch, orgId, e.page, gatewaySerachText ? gatewaySerachText : '')
                    }}
                    currentPageReportTemplate={`Page ${gateWayCurrentPage} of ${gateWayTotalElements / 10}`}
                  />
                </div>
              </div>
              <GatewayCreation isOpen={gateWayRegisterModal} orgID={orgId} CloseModal={handleGatewayModal} dropdownData={dropdownData1} gateWayData={gateWayEditData} />
              <LogoutConfirmationModal
                open={gateWayDeleteModal}
                handleConfirm={() => deleteGateWay(gateWayDeviceID?.id)}
                handleClose={() => handleGateWayClo()}
                message={`Do you want to delete this data? ${gateWayDeviceID?.gatewayId}`}
              />
            </div>
          </TabPanel>
        </TabContext>
      </Box>

    </div>
  );
};

export default QRCodeScanner;