import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllOrgPatient,
  getAllBedAssign,
  getAllBed,
} from "../../slices/thunk";
import { FaSearch, } from "react-icons/fa";
import dischargeIcon from '../../assets/images/admit2.png';
import { DateValidationError } from '@mui/x-date-pickers';
import "react-toastify/dist/ReactToastify.css";
import { Paginator } from 'primereact/paginator'
import dayjs from "dayjs";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Tooltip
} from "@mui/material";
import { baseURL } from "../../configuration/url";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faPencil } from "@fortawesome/free-solid-svg-icons";
import { Button } from "primereact/button";
import axios from 'axios';
import { toast } from "react-toastify";
import PatientCreation from "../Patient/patientCreation";
import { BrowserMultiFormatReader } from "@zxing/library";
import { authorize } from '../../helpers/common';
import Loader from "../../components/loader/Loader";
import "../bedAssign/bedassign.css";
import { grey, primarybg, primarytext } from "../../common/primary";
import PatientEditModal from "./patientEditModal";
import PatientAdmitModal from "./patientModal";
import { getPatientgetAllOrgSuccess } from "../../slices/patient/reducer";

const Patient: React.FC = () => {
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
  }
  const dispatch = useDispatch<any>();
  const [admitD, setAdmitD] = useState<boolean>(false);
  const [reason, setReason] = useState('')
  const [selectPatientId, setSelectPatientId] = useState<string | null>(null);
  const [editModal, setEditModal] = useState(false);
  const { allOrgData, loading, totalElements } = useSelector((state: any) => state.Patient);
  const { organization } = useSelector((state: any) => state.Login);
  const [bedAssignDialog, setBedAssignDialog] = useState(false);
  let [bedSelected, setBedSelected] = useState<string | null>(null);
  const [bedClick, setBedClick] = useState(false)
  const [patientAndBedAssign, setPatientAndBedAssign] = useState<any[]>([]);
  const [bedId, setBedId] = useState<string | null>(null);
  const [options, setOptions] = useState<any>([]);
  const videoRef = useRef(null);
  const codeReader = new BrowserMultiFormatReader();
  const [scanning, setScanning] = useState(false);
  const [deviceId, setDeviceId] = useState('')
  const [currentPage, setCurrentPage] = useState(0);
  const [dateFieldError, setDateFieldError] = React.useState<string>('');
  const [error, setError] = React.useState<DateValidationError | null>(null);
  const [admitDate, setAdmitDate] = React.useState<dayjs.Dayjs | null>(null);
  const [search, setSearch] = useState('')
  let [formData, setFormData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    birthDate: "",
    ssn: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    postalCode: "",
    mrNumber: "",
    email: "",
    beaconDevice1: [],
    gender: "",
    country: "",
    profile: ""
  });
  useEffect(() => {
    getAllOrgPatient(dispatch, organization, 0, search ? search : '');
    fetchNewPatientData();
    return () => {
      dispatch(getPatientgetAllOrgSuccess([]))
    }
  }, [dispatch, organization, search]);
  useEffect(() => {
    const fetchData = async () => {
      const { header1 } = authorize();
      try {
        const response = await axios.get(`${baseURL}/sensor/getPatientsBeacon?organization=${organization}`, { headers: header1 });
        setOptions(response.data.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);
  const fetchNewPatientData = async () => {
    try {
      const response = await axios.get(
        `${baseURL}/patient/get/activePatientMob/${organization}`
      );

      if (response.data.data && Array.isArray(response.data.data)) {
      } else {
        console.error("Invalid data format for patients:", response.data);
      }
    } catch (error) {
      console.warn(error);
    }
  }
  const fetchPatientsandBedAssign = async () => {
    const { header1 } = authorize();
    try {
      const response = await axios.get(
        `${baseURL}/Q15Bed/getByOrg/${organization}`, { headers: header1 }
      );
      if (response.data.data && Array.isArray(response.data.data)) {
        setPatientAndBedAssign(response.data.data);
      } else {
        console.error("Invalid data format for patients:", response.data);
      }
    } catch (error) {
      console.warn(error);
    }
  };
  useEffect(() => {
    if (scanning) {
      startScanning();
    } else {
      codeReader.reset();
    }

    return () => {
      codeReader.reset();
    };
  }, [scanning]);

  const startScanning = async () => {
    try {
      setScanning(true);
      const videoInputDevices = await codeReader.listVideoInputDevices();
      const selectedDeviceId = videoInputDevices[0].deviceId;
      codeReader.decodeFromVideoDevice(
        selectedDeviceId,
        videoRef.current,
        (result: any, err: any) => {
          if (result) {
            let newDeviceId = "";
            const result1 = result.getText();
            if (result1 !== "" && result1 !== undefined && result1 !== null) {
              if (result1.length < 15) {
                const resultId = result1.match(/.{2}/g).join(':');
                newDeviceId = resultId;
              } else {
                const resultId = result1.match(/.{2}/g).join(':');
                newDeviceId = resultId.slice(6, 23);
              }
              setShow(false);
              setScanning(false);
            }
            const scannedOption = options.find((option: any) => option.deviceId === newDeviceId);
            if (scannedOption) {
              setDeviceId(scannedOption.deviceId)
              const modal = document.getElementById("exampleModal");
              if (modal) {
                modal.classList.add("show");
                modal.style.display = "block";
              }
            } else {
              setDeviceId("");
              toast.error("Scanned device ID not found in the options.");
            }
          }
          if (err && err.name === "NotFoundError") {
            console.error("No QR code found in the video feed.");
          }
          if (err) {
            console.error("Error during scanning:", err);
          }
        },
      );
    } catch (error) {
      console.error("Error starting the scanner:", error);
    }
  };

  const [show, setShow] = useState(false);

  const handleClose1 = () => {
    setShow(false)
    setScanning(false)
    codeReader.reset()
  };

  const handleQrClick = () => {
    if (options.length < 1) return;
    setShow(true);
    setScanning(!scanning);
  }

  useEffect(() => {
    getAllBedAssign(dispatch, organization);
    getAllBed(dispatch, organization);
  }, [dispatch, organization]);

  useEffect(() => {
    fetchPatientsandBedAssign();
  }, [admitD]);

  const handleBedClick = (selectedBed: any) => {
    setBedSelected(selectedBed.roomNo + "-" + selectedBed.bedNo);
    const bedAssignId = selectedBed.id || " ";
    setBedId(bedAssignId);
    setBedClick(!bedClick)
  };

  const handleDioCancel = () => {
    setBedSelected('')
    setReason('')
    setAdmitDate(null)
    setDeviceId('')
    setBedAssignDialog(false);
    setAdmitD(false);
    setBedClick(false);
    setDateFieldError('');
    setError(null);
  }

  const closeModalAndRec = () => {
    setShow(false)
    setScanning(false)
    codeReader.reset()
  }
  const bedsByNumber = patientAndBedAssign.reduce<{ [key: string]: any[] }>((acc, bedassign) => {
    const { roomNo } = bedassign;

    if (!acc[roomNo]) {
      acc[roomNo] = [];
    }
    acc[roomNo].push(bedassign);
    return acc;
  }, {});

  const [selectedPatientName, setSelectedPatientName] = useState('');
  const formatDateForInput = (dateString: any) => {
    if (!dateString || dateString.length !== 8) return '';
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);
    return `${year}-${month}-${day}`;
  };
  const handleClick = (selectedPatient: any) => {
    if (selectedPatient) {
      const fullName = `${selectedPatient.basicDetails[0].name[0].given || ''} ${selectedPatient.basicDetails[0].name[0].family || ''}`;
      setSelectedPatientName(fullName);
      const patientId = selectedPatient.id || "";
      setSelectPatientId(patientId);
      setDeviceId(selectedPatient.beaconDevice);
      const basicDetails = selectedPatient.basicDetails[0];
      const address = selectedPatient.contact[0]?.address[0];
      setFormData({
        firstName: basicDetails.name[0]?.given || "",
        middleName: basicDetails.name[0]?.use || "",
        lastName: basicDetails.name[0]?.family || "",
        birthDate: formatDateForInput(basicDetails.birthDate) || "",
        ssn: basicDetails.ssn || "",
        addressLine1: address?.addressLine1 || "",
        addressLine2: address?.addressLine2 || "",
        city: address?.city || "",
        state: address?.state || "",
        postalCode: address?.postalCode || "",
        mrNumber: basicDetails.mrNumber || "",
        email: selectedPatient.email || "",
        beaconDevice1: selectedPatient.beaconDevice1 || [],
        gender: basicDetails.gender || "",
        country: address?.country || "",
        profile: basicDetails.profile
      });
      setEditModal(true);
    } else {
      console.error("Invalid patient data:", selectedPatient);
    }
  };

  return (
    <div className="container m5 p3" style={{ width: '90%', paddingTop: "10px" }}>
      {loading && <Loader />}
      <div className="row" style={{ position: "relative" }}>
        <div className="col-md-8 d-flex align-items-center">
          <h5>All Patient List</h5>
        </div>
        <div className="col-md-4 d-flex justify-content-end align-items-center gap-2 mb-2">
          <div style={{ backgroundColor: primarybg, borderRadius: "4px", cursor: "pointer", fontSize: '30px', color: 'white', display: "flex", justifyContent: "center", alignItems: "center", width: "35px", height: "35px" }}>
            <i style={{ fontSize: "34px", fontWeight: "lighter" }} onClick={toggle} className="material-icons">add</i>
          </div>
          <div className="mx-0 search-container d-flex align-items-center">
            <input
              type="text"
              placeholder="Search..."
              className="search form-control"
              onChange={(e) => setSearch(e.target.value)}
            />
            <FaSearch className="search-icon mt-1" />
          </div>
        </div>
        <table className="table table-bordered" style={{ fontSize: '13px' }}>
          <thead style={{ backgroundColor: grey }}>
            <tr>
              <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey, width: '13px' }}>S.No</th>
              <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey }}>Patient Name</th>
              <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey }}>SSN</th>
              <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey }}>Date of Birth</th>
              <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey, width: '13px' }}>Status</th>
              <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey, width: '10%' }}>Action</th>
            </tr>
          </thead>
          <tbody>
            { allOrgData?.length > 0 ?
              allOrgData?.map((patient: any, index: number) => (
                <tr key={index}>
                  <td className="text text-center">{currentPage * 10 + index + 1}</td>
                  <td
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      handleClick(patient)
                    }
                    className="text"
                  >
                    {patient.basicDetails[0].name[0].given}{" "}
                    {patient.basicDetails[0].name[0].family}
                  </td>
                  <td className="text">{'***' + patient.basicDetails[0].ssn.slice(-4)}</td>
                  <td className="text">{(patient.basicDetails[0].birthDate)}</td>
                  <td className="text text-center">
                    <Tooltip title={(patient.active == 0) ? "InActive" : "Active"} arrow>
                      <FontAwesomeIcon
                        icon={faCircle}
                        style={{
                          fontSize: "13px",
                          cursor: "pointer",
                          color: (patient.active == 0) ? '#EF6868' : '#5FB477',
                          display: "inline-block",
                          textAlign: "center",
                        }}
                      />
                    </Tooltip>
                  </td>
                  <td className="text d-flex justify-content-around align-items-center">
                    <Tooltip title="Edit" arrow>
                      <FontAwesomeIcon
                        icon={faPencil}
                        className="text-primary"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          handleClick(patient)
                        }
                      />
                    </Tooltip>
                    {(patient.active == 0) &&
                      <Tooltip title="Admit " arrow>
                        <img
                          src={dischargeIcon}
                          alt="Discharge Icon"
                          className="text-danger"
                          style={{ cursor: "pointer", width: '18px', height: '20px' }}
                          onClick={() => {
                            setBedAssignDialog(true);
                            setSelectPatientId(patient.id);
                            setSelectedPatientName(`${patient.basicDetails[0].name[0].given || ''} ${patient.basicDetails[0].name[0].family || ''}`)
                          }}
                        />
                      </Tooltip>
                    }
                  </td>
                </tr>
              )) : 
              <tr>
                <td colSpan={7} className="text-center">
                  <div className="row justify-content-between">
                    <h5 className="col-md-7 text-end">No Patients found</h5>
                    <div className="col-md-3">
                      <Button label="Register New Patient" style={{ backgroundColor: '#0f3995', fontSize: '14px', fontWeight: 'bold' }} onClick={toggle}></Button>
                    </div>
                  </div>
                </td>
              </tr>
              }
          </tbody>
        </table>
      </div>
      <PatientCreation modal={modal} toggle={toggle} currentPage={currentPage} />
      <div className="pagination-container" >
        <div className="d-flex justify-content-center">
          <Paginator
            first={currentPage * 10}
            rows={10}
            totalRecords={totalElements}
            onPageChange={(e: any) => {
              setCurrentPage(e.page)
              getAllOrgPatient(dispatch, organization, e.page, search ? search : '')
            }}
            currentPageReportTemplate={`Page ${currentPage} of ${totalElements / 10}`}
          />
        </div>
      </div>
        <PatientEditModal 
          editModal={editModal} 
          setEditModal={setEditModal} 
          selectedPatientName={selectedPatientName} 
          formData={formData} setFormData={setFormData} 
          selectPatientId={selectPatientId} 
          admitDate={admitDate} 
          deviceId={deviceId} 
          currentPage={currentPage}      
      /> 
        <PatientAdmitModal
          bedAssignDialog={bedAssignDialog}
          handleDioCancel={handleDioCancel}
          selectedPatientName={selectedPatientName}
          error={error}
          setError={setError}
          admitDate={admitDate}
          setAdmitDate={setAdmitDate}
          dateFieldError={dateFieldError}
          setDateFieldError={setDateFieldError}
          reason={reason}
          setReason={setReason}
          deviceId={deviceId}
          setDeviceId={setDeviceId}
          handleQrClick={handleQrClick}
          options={options}
          bedSelected={bedSelected}
          admitD={admitD}
          setAdmitD={setAdmitD}
          bedId={bedId}
          handleBedClick={handleBedClick} 
          bedsByNumber={bedsByNumber}
          selectPatientId={selectPatientId}
          currentPage={currentPage}
        />
      <Dialog open={show} onClose={handleClose1}>
        <DialogTitle>Scanning</DialogTitle>
        <DialogContent>
          <video ref={videoRef} style={{ display: scanning ? "block" : "none", width: '100%', height: '400px' }} />
        </DialogContent>
        <div className="d-flex gap-3 justify-content-center">
          <Button label="Cancel" severity="secondary" style={{ color: '#000', backgroundColor: '#94a0b7', fontSize: '12px', fontWeight: 'bold' }} onClick={handleClose1}></Button>
          <Button label={scanning ? "Stop Scanning" : "Start Scanning"} style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold' }} onClick={closeModalAndRec}></Button>
        </div>
      </Dialog>
    </div>
  );
};

export default Patient;