import React, { useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import axios from 'axios';
import { Paginator } from 'primereact/paginator';
import Loader from "../../components/loader/Loader";
import Calendar from "../../components/calendar";
import { primarybg, primarytext } from "../../common/primary";
import calendarMuiImage from '../../assets/images/calendarMuiImage.svg';
import { baseURL } from "../../configuration/url";
import { useDispatch } from 'react-redux';

interface AlarmRecord {
  id: string;
  triggeredStaffId: string;
  triggeredTime: string;
  triggeredDate: string;
}

const AlarmRecordTable: React.FC = () => {
  const [alarmData, setAlarmData] = useState<AlarmRecord[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date()); 
  const [date, setDate] = useState<string>('');
  
  const recordsPerPage = 10;

  const formatDate = (date: any) => {
    const options = { day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };
  const renderDateBoxes = () => {
    const dateBoxes = [];
    for (let i = -3; i <= 3; i++) {
      const currentDate = new Date(selectedDate);
      currentDate.setDate(selectedDate.getDate() + i);
  
      // Make sure selectedDate is valid before accessing its properties
      if (isNaN(currentDate.getTime())) {
        console.error('Invalid selectedDate:', selectedDate);
        return []; // Handle this gracefully if selectedDate is invalid
      }
  
      const date = selectedDate.getDate().toString().padStart(2, '0');
      const year = selectedDate.getFullYear();
      const month = (selectedDate.getMonth() + 1).toString().padStart(2, '0');
      window.localStorage.setItem("getByDate", `${year}${month}${date}`);
  
      dateBoxes.push(
        <Calendar
          key={i}
          day={currentDate.toLocaleDateString('en-US', { weekday: 'short' })}
          date={formatDate(currentDate)}
          onClick={() => handleDate(currentDate)}
          isSelected={selectedDate?.toDateString() === currentDate.toDateString()}
        />
      );
    }
    return dateBoxes;
  };
  

  const fetchAlarmRecords = async (page: number, selectedDate: Date) => {
    setIsLoading(true);
    setError(null);

    try {
      const formattedDate = selectedDate.toISOString().split('T')[0].replace(/-/g, ''); 
      const response = await axios.get(
        `${baseURL}/alarm/getAlarmRecordByTriggeredDate/${formattedDate}?triggeredDate=${formattedDate}`
      );
      const data = response.data.data || [];
      setTotalRecords(data.length);
      setAlarmData(data.slice(page * recordsPerPage, (page + 1) * recordsPerPage));
    } catch (err: any) {
      setError(err.message || 'Failed to fetch data');
    } finally {
      setIsLoading(false);
    }
  };

  const dispatch = useDispatch<any>()
  useEffect(() => {
    fetchAlarmRecords(currentPage, selectedDate);
  }, [currentPage, selectedDate]);

  const handleDate = (date: Date) => {
    setSelectedDate(date);
    const options: Intl.DateTimeFormatOptions = { month: 'short', day: '2-digit', year: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);
    setDate(formattedDate);
  };

  const getDate = (dateTime: string) => {
    if (!dateTime) return 'N/A';
  
    const date = new Date(
      `${dateTime.slice(0, 4)}-${dateTime.slice(4, 6)}-${dateTime.slice(6, 8)}`
    );
  
    if (isNaN(date.getTime())) return 'Invalid date';
  
    const options: Intl.DateTimeFormatOptions = {
      month: 'short',
      day: '2-digit',
      year: '2-digit',
    };
  
    return date.toLocaleDateString('en-US', options);
  };
  
  const handleDateChange = (event: any) => {
    const newDate = new Date(event.target.value);
    if (isNaN(newDate.getTime())) {
      // Handle invalid date input (e.g., reset to current date or show an error)
      setSelectedDate(new Date());
      setDate('');
    } else {
      const options: Intl.DateTimeFormatOptions = { month: 'short', day: '2-digit', year: 'numeric' };
      const formattedDate = newDate.toLocaleDateString('en-US', options);
      setDate(formattedDate);
      setSelectedDate(newDate);
    }
  };
  
  const getTime = (dateTime: string) => {
    if (!dateTime) return 'N/A';
  
    const hours24 = parseInt(dateTime.slice(8, 10), 10);
    const minutes = dateTime.slice(10, 12);
    const seconds = dateTime.slice(12, 14);
  
    const hours12 = hours24 % 12 || 12;
    const period = hours24 >= 12 ? 'PM' : 'AM';
  
    return `${hours12}:${minutes}:${seconds} ${period}`;
  };
  

  return (
    <div className="row" style={{ width: '99%', marginLeft: '5px' }}>
      <h5>SOS Call</h5>

      <div style={{ display: 'flex', justifyContent: "space-evenly", padding: "10px", width: "90%", position: "relative", left: "66px" }}>
        {renderDateBoxes()}
        <div className="inpMain" style={{ position: 'relative' }}>
          <input
            type="date"
            value={selectedDate.toISOString().split('T')[0]}
            onChange={handleDateChange}
            style={{ paddingLeft: '30px' }}
          />
          <div style={{ border: "1px groove", position: 'absolute', top: '0px', left: '0px' }} />
          <img style={{ position: "absolute", left: '12px', top: '21px', width: '32px', height: '22px' }} src={calendarMuiImage} />
        </div>
      </div>

      {isLoading ? (
        <Loader />
      ) : error ? (
        <p style={{ color: 'red' }}>{error}</p>
      ) : (
        <>
          <Table className="table table-bordered" style={{ fontSize: '13px' }}>
            <thead>
              <tr>
                <th scope="col" className="text-center table-data" style={{ width: '13px', color: primarytext }}>S.No</th>
                <th scope="col" className="text-center table-data" style={{ color: primarytext }}>Date</th>
                <th scope="col" className="text-center table-data" style={{ color: primarytext }}> Trigered Time</th>
                <th scope="col" className="text-center table-data" style={{ color: primarytext }}>Staff Name</th>
              </tr>
            </thead>
            <tbody>
              {alarmData.length > 0 ? (
                alarmData.map((record, index) => (
                  <tr key={record.id}>
                    <td className="text-center">{currentPage * recordsPerPage + index + 1}</td>
                    <td className="text-center">{getDate(record.triggeredTime)}</td>
                    <td className="text-center">{getTime(record.triggeredTime)}</td>
                    <td className="text-center">{record.triggeredStaffId}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={4} className="text-center">No records found</td>
                </tr>
              )}
            </tbody>
          </Table>

          <div className="pagination-container">
            <div className="d-flex justify-content-center">
              <Paginator
                first={currentPage * recordsPerPage}
                rows={recordsPerPage}
                totalRecords={totalRecords}
                onPageChange={(e: any) => {
                  setCurrentPage(e.page);
                }}
                currentPageReportTemplate={`Page ${currentPage + 1} of ${Math.ceil(totalRecords / recordsPerPage)}`}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AlarmRecordTable;