import React, { useEffect, useRef, useState } from "react";
import Calendar from "../../components/calendar";
import './staffconfig.css'
import { useDispatch, useSelector } from "react-redux";
import { BrowserMultiFormatReader } from "@zxing/library";
import { toast } from 'react-toastify';
import axios from 'axios';
import { getAllRNIncharge, getAllSocialWorkers } from "../../slices/thunk";
import ErrorPopup from "../../components/errorPopup";
import { closeErrorPopup } from "../../slices/staffConfiguration/reducer";
import { getOrgByID } from "../../slices/organization/thunk";
import { saveOrganization } from "../../slices/login/reducer";
import { HttpLogin } from "../../utils/Http";
import { Button } from "primereact/button";
import Modal from 'react-bootstrap/Modal';
import { baseURL, successCode } from "../../configuration/url";
import { FormControl, Input, InputLabel, MenuItem, Select } from "@mui/material";
import calendarMuiImage from '../../assets/images/calendarMuiImage.svg';
import scanimg from '../../assets/images/scan.svg';
import { authorize } from "../../helpers/common";
import { Autocomplete, TextField, Tooltip, Menu } from "@mui/material";
import { secondarybt } from "../../common/primary";
import Loader from "../../components/loader/Loader";


const Q15StaffConfiguration = () => {
    const getCurrentFormattedDate = () => {
        const currentDate = new Date();
        const options: Intl.DateTimeFormatOptions = { month: 'short', day: '2-digit', year: 'numeric' };
        return currentDate.toLocaleDateString('en-US', options);
    };
    const dispatch = useDispatch<any>()
    const { loading, shiftData, isOpen, errorMsg } = useSelector((state: any) => state.PSConfig)
    const { shiftStartTime } = useSelector((state: any) => state.Org)
    const { organization } = useSelector((state: any) => state.Login)
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [date, setDate] = useState(getCurrentFormattedDate);
    let [shiftTimeName1] = useState<any>(0);
    const [options, setOptions] = useState<any>([]);
    const [saved, setSaved] = useState(false);
    const [Loading, setLoading] = useState<boolean>(false)
    const startTime = new Date(`2000-01-01T${shiftStartTime}`);
    const endTime = new Date(startTime.getTime() + 8 * 60 * 60 * 1000);
    const shiftAEndTime = `${endTime.getHours().toString().padStart(2, '0')}:${endTime.getMinutes().toString().padStart(2, '0')}`;
    const BEndTime = new Date(endTime.getTime() + 8 * 60 * 60 * 1000);
    const shiftBEndTime = `${BEndTime.getHours().toString().padStart(2, '0')}:${BEndTime.getMinutes().toString().padStart(2, '0')}`;
    const formatDate = (date: any) => {
        const options = { day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    };
    let [incharge, setIncharge] = useState<any>('');
    let [newIndex, setNewIndex] = useState<any>('');
    let [newEvent, setNewEvent] = useState<any>('');
    let [newRegister, setNewRegister] = useState<any>('');
    // Calculate time slots based on start time
    const slot1StartTime = shiftStartTime;
    const slot2StartTime = addHours(slot1StartTime, 2);
    const slot3StartTime = addHours(slot2StartTime, 2);
    const slot4StartTime = addHours(slot3StartTime, 2);
    // Calculate time slots based on end time
    const slot1EndTime = slot2StartTime;
    const slot2EndTime = slot3StartTime;
    const slot3EndTime = slot4StartTime;
    const slot4EndTime = shiftAEndTime;
    const { rnInchargeList, socialWorkerList } = useSelector((state: any) => state.PSConfig)
    const Bslot1StartTime = slot4EndTime;
    const Bslot2StartTime = addHours(Bslot1StartTime, 2);
    const Bslot3StartTime = addHours(Bslot2StartTime, 2);
    const Bslot4StartTime = addHours(Bslot3StartTime, 2);
    // Calculate time slots based on end time
    const Bslot1EndTime = Bslot2StartTime;
    const Bslot2EndTime = Bslot3StartTime;
    const Bslot3EndTime = Bslot4StartTime;
    const Bslot4EndTime = shiftBEndTime;

    const Cslot1StartTime = Bslot4EndTime;
    const Cslot2StartTime = addHours(Cslot1StartTime, 2);
    const Cslot3StartTime = addHours(Cslot2StartTime, 2);
    const Cslot4StartTime = addHours(Cslot3StartTime, 2);
    // Calculate time slots Cased on end time
    const Cslot1EndTime = Cslot2StartTime;
    const Cslot2EndTime = Cslot3StartTime;
    const Cslot3EndTime = Cslot4StartTime;
    const Cslot4EndTime = shiftStartTime;
    const [selectedTab, setSelectedTab] = useState('Shift-A');

    const handleDate = (date: any) => {
        setSelectedDate(date)
        const options: Intl.DateTimeFormatOptions = { month: 'short', day: '2-digit', year: 'numeric' };
        const formattedDate = date.toLocaleDateString('en-US', options);
        setDate(formattedDate)
    }

    const renderDateBoxes = () => {
        const dateBoxes = [];

        if (isNaN(selectedDate.getTime())) {
            console.error("Invalid selectedDate:", selectedDate);
            return []; 
        }
        for (let i = -3; i <=3; i++) {
            const currentDate = new Date(selectedDate);
            currentDate.setDate(selectedDate.getDate() + i);
            const date = selectedDate.getDate().toString().padStart(2, '0');
            const year = selectedDate.getFullYear();
            const month = (selectedDate.getMonth() + 1).toString().padStart(2, '0');
            window.localStorage.setItem("getByDate", `${year}${month}${date}`)
            dateBoxes.push(
                <Calendar
                    key={i}
                    day={currentDate.toLocaleDateString('en-US', { weekday: 'short' })}
                    date={formatDate(currentDate)}
                    onClick={() => handleDate(currentDate)}
                    isSelected={selectedDate?.toDateString() === currentDate.toDateString()}
                />
            );
        }
        return dateBoxes;
    };
    const closePopup = () => {
        dispatch(closeErrorPopup())
    }

    let addSlotRegister1 = [{
        roomRange: [],
        deviceId: "",
        staff: "",
        startRoomNo: "",
        endRoomNo: "",
        all: false
    }]
    let addSlotRegister2 = [{
        roomRange: [],
        deviceId: "",
        staff: "",
        startRoomNo: "",
        endRoomNo: "",
        all: false
    }]
    let addSlotRegister3 = [{
        roomRange: [],
        deviceId: "",
        staff: "",
        startRoomNo: "",
        endRoomNo: "",
        all: false
    }]
    let addSlotRegister4 = [{
        roomRange: [],
        deviceId: "",
        staff: "",
        startRoomNo: "",
        endRoomNo: "",
        all: false
    }]
    const videoRef = useRef(null);
    const codeReader = new BrowserMultiFormatReader();
    const [scanning, setScanning] = useState(false);

    useEffect(() => {
        const date = selectedDate.getDate().toString().padStart(2, '0');
        const year = selectedDate.getFullYear();
        const month = (selectedDate.getMonth() + 1).toString().padStart(2, '0');
        const { header1 } = authorize();
        HttpLogin.axios().get(`/api/PSConfig/getByDateAndOrganization/${year}${month}${date}/${organization}`, { headers: header1 })
            .then((response) => {
                if (response.data.message.code === 'MHC - 0200') {
                    let newData = response.data.data.shift.length > 0 ? response.data.data.shift.filter((l: any) => (l.shiftName === selectedTab))?.map((k: any) => { return k }) : [];
                    setIncharge(newData[0]?.rnIncharge);
                    setSlotRegister1(newData[0]?.schedule[0]?.bedStaff !== null && newData[0]?.schedule[0]?.bedStaff !== undefined ? newData[0].schedule[0].bedStaff : addSlotRegister1)
                    setSlotRegister2(newData[0]?.schedule[1]?.bedStaff !== null && newData[0]?.schedule[1]?.bedStaff !== undefined ? newData[0].schedule[1].bedStaff : addSlotRegister2)
                    setSlotRegister3(newData[0]?.schedule[2]?.bedStaff !== null && newData[0]?.schedule[2]?.bedStaff !== undefined ? newData[0].schedule[2].bedStaff : addSlotRegister3)
                    setSlotRegister4(newData[0]?.schedule[3]?.bedStaff !== null && newData[0]?.schedule[3]?.bedStaff !== undefined ? newData[0].schedule[3].bedStaff : addSlotRegister4)
                } else {
                    setIncharge("");
                    setSlotRegister1(addSlotRegister1);
                    setSlotRegister2(addSlotRegister2);
                    setSlotRegister3(addSlotRegister3);
                    setSlotRegister4(addSlotRegister4);
                }
            })

    }, [dispatch, selectedDate, selectedTab])

    const getShiftData = (shiftName: string) => {
        return shiftData?.find((shift: any) => shift?.shiftName === shiftName) || {};
    };

    const handleTabClick = (tabId: any) => {
        setSelectedTab(tabId);
        const date = selectedDate.getDate().toString().padStart(2, '0');
        const year = selectedDate.getFullYear();
        const month = (selectedDate.getMonth() + 1).toString().padStart(2, '0');
        const { header1 } = authorize();
        HttpLogin.axios().get(`/api/PSConfig/getByDateAndOrganization/${year}${month}${date}/${organization}`, { headers: header1 })
            .then((response) => {
                if (response.data.message.code === 'MHC - 0200') {
                    let newData = response.data.data.shift.length > 0 ? response.data.data.shift.filter((l: any) => (l.shiftName === tabId))?.map((k: any) => { return k }) : [];
                    setIncharge(newData[0]?.rnIncharge);
                    setSlotRegister1(newData[0]?.schedule[0]?.bedStaff !== null && newData[0]?.schedule[0]?.bedStaff !== undefined ? newData[0].schedule[0].bedStaff : addSlotRegister1)
                    setSlotRegister2(newData[0]?.schedule[1]?.bedStaff !== null && newData[0]?.schedule[1]?.bedStaff !== undefined ? newData[0].schedule[1].bedStaff : addSlotRegister2)
                    setSlotRegister3(newData[0]?.schedule[2]?.bedStaff !== null && newData[0]?.schedule[2]?.bedStaff !== undefined ? newData[0].schedule[2].bedStaff : addSlotRegister3)
                    setSlotRegister4(newData[0]?.schedule[3]?.bedStaff !== null && newData[0]?.schedule[3]?.bedStaff !== undefined ? newData[0].schedule[3].bedStaff : addSlotRegister4)
                }
            })
    }

    useEffect(() => {
        saveOrganization(dispatch)
        getAllRNIncharge(dispatch, 'Registered Nurse', organization)
        getAllSocialWorkers(dispatch, 'Social Worker', organization)
        getOrgByID(dispatch, organization)
    }, [dispatch, selectedDate])
    // Helper function to add hours to a given time
    function addHours(time: any, hours: any) {
        const baseTime = new Date(`2000-01-01T${time}`);
        const newTime = new Date(baseTime.getTime() + hours * 60 * 60 * 1000);
        return `${newTime.getHours().toString().padStart(2, '0')}:${newTime.getMinutes().toString().padStart(2, '0')}`;
    }

    let [slotRegister1, setSlotRegister1] = useState(new Array<any>(addSlotRegister1));
    let [slotRegister2, setSlotRegister2] = useState(new Array<any>(addSlotRegister2));
    let [slotRegister3, setSlotRegister3] = useState(new Array<any>(addSlotRegister3));
    let [slotRegister4, setSlotRegister4] = useState(new Array<any>(addSlotRegister4));
    let [allStaffData, setAllStaffData] = useState(new Array<any>());
    let [addStaffData, setAddStaffData] = useState(new Array<any>());

    const date1 = selectedDate.getDate().toString().padStart(2, '0');
    const year = selectedDate.getFullYear();
    const month = (selectedDate.getMonth() + 1).toString().padStart(2, '0');
    const forDate = `${year}${month}${date1}`

    const handleCancel = () => {
        setIncharge('');
        setSlotRegister1(addSlotRegister1);
        setSlotRegister2(addSlotRegister2);
        setSlotRegister3(addSlotRegister3);
        setSlotRegister4(addSlotRegister4);
    }

    useEffect(() => {
        setAllStaffData(socialWorkerList !== null && socialWorkerList !== undefined ? socialWorkerList : []);
        setAddStaffData(socialWorkerList !== null && socialWorkerList !== undefined ? socialWorkerList?.map((k: any) => { return k.name[0].given + " " + k.name[0].family }) : [])
    }, [dispatch])

    const handleCheck = (e: React.ChangeEvent<HTMLInputElement>, index: number, register: any, setRegister: any) => {
        let data = [...register];
        data[index] = { ...data[index], all: e.target.checked };
        setRegister(data);
    };
    const handleCheck1 = (e: React.ChangeEvent<HTMLInputElement>, index: number, register: any, setRegister: any) => {
        let data = [...register];
        data[index] = { ...data[index], all: e.target.checked };
        setRegister(data);
    };
    const handleCheck2 = (e: React.ChangeEvent<HTMLInputElement>, index: number, register: any, setRegister: any) => {
        let data = [...register];
        data[index] = { ...data[index], all: e.target.checked };
        setRegister(data);
    };
    const handleCheck3 = (e: React.ChangeEvent<HTMLInputElement>, index: number, register: any, setRegister: any) => {
        let data = [...register];
        data[index] = { ...data[index], all: e.target.checked };
        setRegister(data);
    };

    const [roomNumbers, setRoomNumbers] = useState(new Array<any>());
    const [selectedRoomNumbers, setSelectedRoomNumbers] = useState([]);

    useEffect(() => {
        const { header1 } = authorize();
        fetch(`${baseURL}/Q15Bed/getByOrg/${organization}`, { headers: header1 })
            .then(response => response.json())
            .then(data => {
                // console.log(data, ': data')
                const roomBedCombinations = data.data?.map((item: any) => {
                    let bedNo = item.bedNo;
                    // Convert bed numbers to alphabets
                    if (bedNo === '01') {
                        bedNo = 'A';
                    } else if (bedNo === '02') {
                        bedNo = 'B';
                    } else if (bedNo === '03') {
                        bedNo = 'C';
                    }
                    return `${item.roomNo} - ${bedNo}`;
                });
                setRoomNumbers(roomBedCombinations);
            })
            .catch(error => console.error('Error fetching room numbers:', error));
    }, []);

    const handleFieldRemove1 = (index: any) => {
        const list = [...slotRegister1];
        list.splice(index, 1);
        setSlotRegister1(list);
    };

    const handleFieldAdd1 = () => {
        setSlotRegister1([...slotRegister1,
        {
            roomRange: [],
            deviceId: "",
            staff: "",
            startRoomNo: "",
            endRoomNo: "",
            all: false
        }]);
    };
    const handleFieldRemove2 = (index: any) => {
        const list = [...slotRegister2];
        list.splice(index, 1);
        setSlotRegister2(list);
    };

    const handleFieldAdd2 = () => {
        setSlotRegister2([...slotRegister2,
        {
            roomRange: [],
            deviceId: "",
            staff: "",
            startRoomNo: "",
            endRoomNo: "",
            all: false
        }]);
    };

    const handleFieldRemove3 = (index: any) => {
        const list = [...slotRegister3];
        list.splice(index, 1);
        setSlotRegister3(list);
    };

    const handleAutocompleteChange = (event: any, value: any, index: any) => {
        if (value) {
            // Update slotRegister1 with the selected value's ID
            let data = [...slotRegister1];
            data[index].staff = value.id;
            setSlotRegister1(data);
        } else {
            // Handle case where no value is selected
            let data = [...slotRegister1];
            data[index].staff = '';
            setSlotRegister1(data);
        }
    };
    const handleAutocompleteChange2 = (event: any, value: any, index: any) => {
        if (value) {
            // Update slotRegister1 with the selected value's ID
            let data = [...slotRegister2];
            data[index].staff = value.id;
            setSlotRegister2(data);
        } else {
            // Handle case where no value is selected
            let data = [...slotRegister2];
            data[index].staff = '';
            setSlotRegister2(data);
        }
    };
    const handleAutocompleteChange3 = (event: any, value: any, index: any) => {
        if (value) {
            // Update slotRegister1 with the selected value's ID
            let data = [...slotRegister3];
            data[index].staff = value.id;
            setSlotRegister3(data);
        } else {
            // Handle case where no value is selected
            let data = [...slotRegister3];
            data[index].staff = '';
            setSlotRegister3(data);
        }
    };
    const handleAutocompleteChange4 = (event: any, value: any, index: any) => {
        if (value) {
            // Update slotRegister1 with the selected value's ID
            let data = [...slotRegister4];
            data[index].staff = value.id;
            setSlotRegister4(data);
        } else {
            // Handle case where no value is selected
            let data = [...slotRegister4];
            data[index].staff = '';
            setSlotRegister4(data);
        }
    };
    const handleFieldAdd3 = () => {
        setSlotRegister3([...slotRegister3,
        {
            roomRange: [],
            deviceId: "",
            staff: "",
            startRoomNo: "",
            endRoomNo: "",
            all: false
        }]);
    };

    const handleFieldRemove4 = (index: any) => {
        const list = [...slotRegister4];
        list.splice(index, 1);
        setSlotRegister4(list);
    };

    const handleFieldAdd4 = () => {
        setSlotRegister4([...slotRegister4,
        {
            roomRange: [],
            deviceId: "",
            staff: "",
            startRoomNo: "",
            endRoomNo: "",
            all: false
        }]);
    };

    const handleDateChange = (e: any) => {
        const { header1 } = authorize();
        const newDate = new Date(e.target.value);
    
        if (isNaN(newDate.getTime())) {
            setDate("");
            return;
        }
    
        try {
            const options: Intl.DateTimeFormatOptions = { month: 'short', day: '2-digit', year: 'numeric' };
            const formattedDate = newDate.toLocaleDateString('en-US', options);
            setDate(formattedDate);
            setSelectedDate(newDate);
            const date = newDate.getDate().toString().padStart(2, '0');
            const year = newDate.getFullYear();
            const month = (newDate.getMonth() + 1).toString().padStart(2, '0');
            HttpLogin.axios().get(`/api/PSConfig/getByDateAndOrganization/${year}${month}${date}/${organization}`, { headers: header1 })
                .then((response) => {
                    if (response.data.message.code === 'MHC - 0200') {
                        let newData = response.data.data.shift.length > 0 ? response.data.data.shift.filter((l: any) => (l.shiftName === selectedTab))?.map((k: any) => { return k }) : [];
                        setIncharge(newData[0]?.rnIncharge);
                        setSlotRegister1(newData[0]?.schedule[0]?.bedStaff !== null && newData[0]?.schedule[0]?.bedStaff !== undefined ? newData[0].schedule[0].bedStaff : addSlotRegister1)
                        setSlotRegister2(newData[0]?.schedule[1]?.bedStaff !== null && newData[0]?.schedule[1]?.bedStaff !== undefined ? newData[0].schedule[1].bedStaff : addSlotRegister2)
                        setSlotRegister3(newData[0]?.schedule[2]?.bedStaff !== null && newData[0]?.schedule[2]?.bedStaff !== undefined ? newData[0].schedule[2].bedStaff : addSlotRegister3)
                        setSlotRegister4(newData[0]?.schedule[3]?.bedStaff !== null && newData[0]?.schedule[3]?.bedStaff !== undefined ? newData[0].schedule[3].bedStaff : addSlotRegister4)
                    }
                })
        } catch (error) {
            alert(error)
        }
    };

    const handleQrClick = (index: any, newSlotRegister: any) => {
        setScanning(!scanning);
        setNewIndex(index);
        setNewRegister(newSlotRegister);
    }

    const handleSubmit = async () => {
        const { header1 } = authorize();
        if(!incharge) {
            return toast.error('Enter required fields')
        }
        try {
            let bodyData;
            const bodyA = {
                id: "", organization, date: forDate, createdAt: "", updatedAt: "", shift: [{ shiftName: "Shift-A", rnIncharge: incharge, startTime: slot1StartTime, endTime: slot4EndTime, schedule: [{ time: slot1StartTime + "-" + slot1EndTime, bedStaff: slotRegister1 !== null ? slotRegister1?.map((k) => ({ roomRange: [], staff: `${k.staff}`, startRoomNo: `${k.startRoomNo}`, endRoomNo: `${k.endRoomNo}`, all: k.all, deviceId: `${k.deviceId}` })) : [] }, { time: slot2StartTime + "-" + slot2EndTime, bedStaff: slotRegister2 !== null ? slotRegister2?.map((k) => ({ roomRange: [], staff: `${k.staff}`, startRoomNo: `${k.startRoomNo}`, endRoomNo: `${k.endRoomNo}`, all: k.all, deviceId: `${k.deviceId}` })) : [] }, { time: slot3StartTime + "-" + slot3EndTime, bedStaff: slotRegister3 !== null ? slotRegister3?.map((k) => ({ roomRange: [], staff: `${k.staff}`, startRoomNo: `${k.startRoomNo}`, endRoomNo: `${k.endRoomNo}`, all: k.all, deviceId: `${k.deviceId}` })) : [] }, { time: slot4StartTime + "-" + slot4EndTime, bedStaff: slotRegister4 !== null ? slotRegister4?.map((k) => ({ roomRange: [], staff: `${k.staff}`, startRoomNo: `${k.startRoomNo}`, endRoomNo: `${k.endRoomNo}`, all: k.all, deviceId: `${k.deviceId}` })) : [] }] }]
            }
            const bodyB = {
                id: "", organization, date: forDate, createdAt: "", updatedAt: "", shift: [{ shiftName: "Shift-B", rnIncharge: incharge, startTime: Bslot1StartTime, endTime: Bslot4EndTime, schedule: [{ time: Bslot1StartTime + "-" + Bslot1EndTime, bedStaff: slotRegister1 !== null ? slotRegister1?.map((k) => ({ roomRange: [], staff: `${k.staff}`, startRoomNo: `${k.startRoomNo}`, endRoomNo: `${k.endRoomNo}`, all: k.all, deviceId: `${k.deviceId}` })) : [] }, { time: Bslot2StartTime + "-" + Bslot2EndTime, bedStaff: slotRegister2 !== null ? slotRegister2?.map((k) => ({ roomRange: [], staff: `${k.staff}`, startRoomNo: `${k.startRoomNo}`, endRoomNo: `${k.endRoomNo}`, all: k.all, deviceId: `${k.deviceId}` })) : [] }, { time: Bslot3StartTime + "-" + Bslot3EndTime, bedStaff: slotRegister3 !== null ? slotRegister3?.map((k) => ({ roomRange: [], staff: `${k.staff}`, startRoomNo: `${k.startRoomNo}`, endRoomNo: `${k.endRoomNo}`, all: k.all, deviceId: `${k.deviceId}` })) : [] }, { time: Bslot4StartTime + "-" + Bslot4EndTime, bedStaff: slotRegister4 !== null ? slotRegister4?.map((k) => ({ roomRange: [], staff: `${k.staff}`, startRoomNo: `${k.startRoomNo}`, endRoomNo: `${k.endRoomNo}`, all: k.all, deviceId: `${k.deviceId}` })) : [] }] }]
            }
            const bodyC = {
                id: "", organization, date: forDate, createdAt: "", updatedAt: "", shift: [{ shiftName: "Shift-C", rnIncharge: incharge, startTime: Cslot1StartTime, endTime: Cslot4EndTime, schedule: [{ time: Cslot1StartTime + "-" + Cslot1EndTime, bedStaff: slotRegister1 !== null ? slotRegister1?.map((k) => ({ roomRange: [], staff: `${k.staff}`, startRoomNo: `${k.startRoomNo}`, endRoomNo: `${k.endRoomNo}`, all: k.all, deviceId: `${k.deviceId}` })) : [] }, { time: Cslot2StartTime + "-" + Cslot2EndTime, bedStaff: slotRegister2 !== null ? slotRegister2?.map((k) => ({ roomRange: [], staff: `${k.staff}`, startRoomNo: `${k.startRoomNo}`, endRoomNo: `${k.endRoomNo}`, all: k.all, deviceId: `${k.deviceId}` })) : [] }, { time: Cslot3StartTime + "-" + Cslot3EndTime, bedStaff: slotRegister3 !== null ? slotRegister3?.map((k) => ({ roomRange: [], staff: `${k.staff}`, startRoomNo: `${k.startRoomNo}`, endRoomNo: `${k.endRoomNo}`, all: k.all, deviceId: `${k.deviceId}` })) : [] }, { time: Cslot4StartTime + "-" + Cslot4EndTime, bedStaff: slotRegister4 !== null ? slotRegister4?.map((k) => ({ roomRange: [], staff: `${k.staff}`, startRoomNo: `${k.startRoomNo}`, endRoomNo: `${k.endRoomNo}`, all: k.all, deviceId: `${k.deviceId}` })) : [] }] }]
            }
            if (selectedTab === "Shift-A") { bodyData = { ...bodyA }; } else if (selectedTab === "Shift-B") bodyData = { ...bodyB }; else bodyData = { ...bodyC };
            const response = await axios.post(`${baseURL}/PSConfig/register`, bodyData, { headers: header1 });
            if (response.data.message.code === successCode) {
                setSaved(true);
                toast.success(response.data.message.description)

            } else {
                toast.error("Login failed: " + response.data.message.description);
            }
        } catch (error) {
            toast.error("An error occurred during register.");
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const { header1 } = authorize();
            try {
                const response = await axios.get(`${baseURL}/sensor/getAllByorgId/${organization}`, { headers: header1 });
                setOptions(response.data.data.content);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    return (
        <React.Fragment>
            {Loading && <Loader />}
            <h5>Q15-Staff Assignment</h5>
            <div style={{ display: 'flex', justifyContent: "space-evenly", padding: "10px", width: "90%", position: "relative", left: "66px" }}>
                {renderDateBoxes()}
                <div className="inpMain">
                    <input
                        type="date"
                        value={selectedDate.toISOString().split('T')[0]}
                        onChange={handleDateChange}
                    />
                    <div style={{ border: "1px groove" }} />
                    <img style={{ position: "absolute", left: '12px', top: '21px', width: '32px', height: '22px' }} src={calendarMuiImage} />
                </div>
            </div>
            <div style={{ display: "flex" }} >
                <div style={{ width: "15%", backgroundColor: "#eaf2fa", borderRight: "1px groove #ccd2d8" }} >
                    <div style={{ display: "flex", flexDirection: "column", height: "142px", justifyContent: "space-around", paddingTop: "20px", alignItems: "center" }}>
                        <button
                            className={`nav-link ${selectedTab === 'Shift-A' ? 'active' : ''}`}
                            id="Shift-A-tab"
                            type="button"
                            role="tab"
                            aria-controls="Shift-A"
                            aria-selected={selectedTab === 'Shift-A'}
                            onClick={() => handleTabClick('Shift-A')}
                            style={{ backgroundColor: selectedTab === 'Shift-A' ? '#cddcfa' : '', color: selectedTab === 'Shift-A' ? '' : 'black', width: "91%", height: "40px", borderRadius: "4px" }}
                        >
                            Shift-A
                        </button>
                        <button
                            className={`nav-link ${selectedTab === 'Shift-B' ? 'active' : ''}`}
                            id="Shift-B-tab"
                            type="button"
                            role="tab"
                            aria-controls="Shift-B"
                            aria-selected={selectedTab === 'Shift-B'}
                            onClick={() => handleTabClick('Shift-B')}
                            style={{ backgroundColor: selectedTab === 'Shift-B' ? '#cddcfa' : '', color: selectedTab === 'Shift-B' ? '' : 'black', width: "91%", height: "40px", borderRadius: "4px" }}
                        >
                            Shift-B
                        </button>
                        <button
                            className={`nav-link ${selectedTab === 'Shift-C' ? 'active' : ''}`}
                            id="Shift-C-tab"
                            type="button"
                            role="tab"
                            aria-controls="Shift-C"
                            aria-selected={selectedTab === 'Shift-C'}
                            onClick={() => handleTabClick('Shift-C')}
                            style={{ backgroundColor: selectedTab === 'Shift-C' ? '#cddcfa' : '', color: selectedTab === 'Shift-C' ? '' : 'black', width: "91%", height: "40px", borderRadius: "4px" }}
                        >
                            Shift-C
                        </button>
                    </div>
                </div>
                <div style={{ width: "83%", backgroundColor: "#f2f5f9" }} >
                    <div style={{ textAlign: "start", borderBottom: "1px groove #ccd2d8", height: "70px", lineHeight: 3.5, padding: "3px 0px 0px 10px" }}>
                        <span style={{ fontSize: "20px", fontWeight: 600 }}>{date} {selectedTab} Configuration</span>
                    </div>
                    <div id={`${selectedTab === 'Shift-A' ? 'Shift-A' : selectedTab === 'Shift-B' ? 'Shift-B' : selectedTab === 'Shift-C' ? 'Shift-C' : ''}`} role="tabpanel" aria-labelledby={`${selectedTab === 'Shift-A' ? 'Shift-A' : selectedTab === 'Shift-B' ? 'Shift-B' : selectedTab === 'Shift-C' ? 'Shift-C' : ''}-tab`}>
                        <div >
                            <div className="row flex-row" style={{ display: "flex", justifyContent: "center", gap: "15px" }}>
                                <div className="col-sm-2">
                                    <FormControl variant="filled" fullWidth>
                                        <InputLabel htmlFor="floatingStartTime">Start Time</InputLabel>
                                        <Input
                                            id="floatingStartTime"
                                            style={{ height: "37px", backgroundColor: "#f2f5f9" }}
                                            type="text"
                                            value={
                                                selectedTab === 'Shift-A' ? shiftStartTime :
                                                    selectedTab === 'Shift-B' ? shiftAEndTime :
                                                        selectedTab === 'Shift-C' ? shiftBEndTime :
                                                            shiftStartTime
                                            }
                                            disabled
                                            placeholder='Start Time'
                                        />
                                    </FormControl>
                                </div>
                                <div className="col-sm-2">
                                    <FormControl variant="filled" fullWidth>
                                        <InputLabel htmlFor="floatingEndTime">End Time</InputLabel>
                                        <Input
                                            id="floatingEndTime"
                                            style={{ height: "37px", backgroundColor: "#f2f5f9" }}
                                            type="text"
                                            value={
                                                selectedTab === 'Shift-A' ? shiftAEndTime :
                                                    selectedTab === 'Shift-B' ? shiftBEndTime :
                                                        selectedTab === 'Shift-C' ? shiftStartTime :
                                                            shiftAEndTime
                                            }
                                            disabled
                                            placeholder='End Time'
                                        />
                                    </FormControl>
                                </div>
                                <div className="col-sm-3 form-floating p-1">
                                    <Autocomplete
                                        style={{ height: "65px", backgroundColor: "#f2f5f9", paddingRight: '8px' }}
                                        fullWidth
                                        id="floatingSelect"
                                        onChange={(e, value) => setIncharge(value?.id || '')}
                                        value={rnInchargeList.find((item: any) => item.id === incharge) || null}
                                        options={rnInchargeList}
                                        getOptionLabel={(option) => option.name[0]?.given + ' ' + option.name[0]?.family}
                                        renderInput={(params) => <TextField {...params} label="Select RN Incharge" variant="standard" />}
                                    />
                                    {/* <FormControl variant="filled" fullWidth style={{ backgroundColor: '#fff' }}>
                                        <InputLabel htmlFor='floatingSelect'>Shift Incharge</InputLabel>
                                        <Select
                                            style={{ height: "65px", backgroundColor: "#f2f5f9", paddingRight: '8px' }}
                                            className="form-select"
                                            fullWidth
                                            id="floatingSelect"
                                            onChange={(e: any) => setIncharge(e.target.value)}
                                            value={incharge ? incharge : ""}
                                        >
                                            <MenuItem value="">Select RN Incharge</MenuItem>
                                            {rnInchargeList?.map((item: any) => (
                                                <MenuItem key={item.id} value={item.id}>
                                                    {item.name[0]?.given + ' ' + item.name[0]?.family}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl> */}
                                </div>
                            </div>
                            <div >
                                <div  >
                                    <div >
                                        {(slotRegister1?.map((addField, index) => (
                                            <div key={index} >
                                                <div style={{ display: "flex", paddingLeft: "134px", gap: "10px", flexDirection: "row", justifyContent: "space-between", width: "85%" }} >
                                                    {index === 0 && <>
                                                        <div style={{ display: "flex" }}>
                                                            <div style={{ lineHeight: 2.7, fontWeight: 600, fontFamily: "poppins", width: "40%" }}>  Slot 1 ---</div>
                                                            <input type="text" style={{ fontSize: '15px', borderStyle: "none" }} value={selectedTab === "Shift-A" ? slot1StartTime + "-" + slot1EndTime : selectedTab === "Shift-B" ? Bslot1StartTime + "-" + Bslot1EndTime : Cslot1StartTime + "-" + Cslot1EndTime} id="floatingEndTime" disabled placeholder='end time' />
                                                        </div>
                                                        {/* <div style={{ lineHeight: 2.7, fontWeight: 600, fontFamily: "poppins" }}>
                                                            Device ID
                                                        </div> */}
                                                    </>}
                                                </div>
                                                <div className="mb-3 col-md-3 form-floating p-1" style={{ display: 'flex', width: "100%", justifyContent: "center", gap: "10px" }}>
                                                    <div className="mb-3 col-md-3">
                                                        <Autocomplete
                                                            fullWidth
                                                            // id="floatingSelect"
                                                            options={socialWorkerList}
                                                            getOptionLabel={(option) => option?.name[0]?.given + ' ' + option?.name[0]?.family}
                                                            onChange={(event, value) => handleAutocompleteChange(event, value, index)}
                                                            value={socialWorkerList.find((item: any) => item.id === addField.staff) || null}
                                                            renderInput={(params) => <TextField {...params} label="Rounding Staff" variant="standard" />}
                                                        />
                                                        {/* <select className="form-select" id="floatingSelect" style={{ fontSize: '12px', width: "100%" }} onChange={(e: any) => {
                                                            let data = [...slotRegister1];
                                                            data[index].staff = e.target.value;
                                                            setSlotRegister1(data)
                                                        }} value={addField.staff}>
                                                            <option value="">-select-</option>
                                                            {
                                                                socialWorkerList?.map((item: any) => {
                                                                    return (
                                                                        <option value={item?.id}>{item?.name[0]?.given + ' ' + item?.name[0]?.family}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                        <label style={{ fontSize: '12px' }} htmlFor='floatingSelect'>Rounding Staff</label> */}
                                                    </div>
                                                    <div>
                                                        All Rooms
                                                        <input type="checkbox" id="checkbox" name="checkbox" checked={addField.all} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleCheck(e, index, slotRegister1, setSlotRegister1)} />
                                                    </div>
                                                    <div className="form-floating mb-3 col-md-2 p-1">
                                                        <select className="form-select" id="floatingSelect" disabled={addField.all} style={{ fontSize: '12px', width: "100%" }} onChange={(e: any) => {
                                                            let data = [...slotRegister1];
                                                            data[index].startRoomNo = e.target.value;
                                                            setSlotRegister1(data)
                                                        }} value={addField.startRoomNo}>
                                                            <option value="">-select-</option>
                                                            {
                                                                roomNumbers?.map((item: any) => {
                                                                    return (
                                                                        <option value={item}>{item}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                        <label style={{ fontSize: '12px' }} htmlFor='floatingSelect'>Start Room</label>
                                                    </div>
                                                    <div className="form-floating mb-3 col-md-2 p-1">
                                                        <select className="form-select" id="floatingSelect" disabled={addField.all} style={{ fontSize: '12px', width: "100%" }} onChange={(e: any) => {
                                                            let data = [...slotRegister1];
                                                            data[index].endRoomNo = e.target.value;
                                                            setSlotRegister1(data)
                                                        }} value={addField.endRoomNo}>
                                                            <option value="">-select-</option>
                                                            {
                                                                roomNumbers?.map((item: any) => {
                                                                    return (
                                                                        <option value={item}>{item}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                        <label style={{ fontSize: '12px' }} htmlFor='floatingSelect'>End Room</label>
                                                    </div>
                                                    <div className="mb-2 col-md-2 p-0">
                                                        {/* <select
                                                            className="form-select"
                                                            id="floatingSelect"
                                                            style={{
                                                                fontSize: '12px',
                                                                width: '100%',
                                                                backgroundImage: 'none',
                                                                paddingTop: '8px'
                                                            }}
                                                            onChange={(e: any) => {
                                                                let data = [...slotRegister1];
                                                                data[index].deviceId = e.target.value;
                                                                setSlotRegister1(data);
                                                            }}
                                                            value={addField.deviceId}
                                                            disabled // Disable if a device ID is selected
                                                        >
                                                            <option value="">-select-</option>
                                                            {options?.map((item: any) => (
                                                                <option key={item.id} value={item.deviceId}>
                                                                    {item.deviceId}
                                                                </option>
                                                            ))}
                                                        </select> */}
                                                        <div style={{ fontFamily: "poppins", fontSize: '10px', fontWeight: 600 }}><strong>Device ID : </strong><br/>{addField?.deviceId ? addField?.deviceId : 'N/A'}</div>
                                                        <div style={{ fontFamily: "poppins", fontSize: '10px', fontWeight: 600 }}><strong>Location : </strong><br/>{addField?.location ? addField?.location : 'N/A'}</div>
                                                    </div>
                                                    <div style={{ position: 'relative', left: '5px', top: "4px" }} role='button'>{slotRegister1?.map((addField, index) => (
                                                        slotRegister1.length - 1 === index &&
                                                        <div>
                                                            <i style={{ backgroundColor: secondarybt, borderRadius: "4px" }} onClick={handleFieldAdd1} className="material-icons">add</i>
                                                        </div>

                                                    ))}{slotRegister1.length !== 1 && (
                                                        <div>
                                                            <i style={{ backgroundColor: secondarybt, borderRadius: "4px" }} onClick={() => handleFieldRemove1(index)} className="material-icons">remove</i>
                                                        </div>
                                                    )}
                                                    </div>
                                                </div>


                                            </div>

                                        )))}
                                    </div>
                                </div>
                                <div  >
                                    <div >
                                        {(slotRegister2?.map((addField, index) => (
                                            <div key={index} >
                                                <div style={{ display: "flex", paddingLeft: "134px", gap: "10px", flexDirection: "row-reverse", justifyContent: "flex-end" }} >
                                                    {index === 0 && <>
                                                        <div>
                                                            <input type="text" style={{ fontSize: '15px', borderStyle: "none" }} value={selectedTab === "Shift-A" ? slot2StartTime + "-" + slot2EndTime : selectedTab === "Shift-B" ? Bslot2StartTime + "-" + Bslot2EndTime : Cslot2StartTime + "-" + Cslot2EndTime} id="floatingEndTime" disabled placeholder='end time' />
                                                        </div>
                                                        <div style={{ lineHeight: 2.7, fontWeight: 600, fontFamily: "poppins" }}>
                                                            Slot 2 ---
                                                        </div>
                                                    </>}
                                                </div>
                                                <div style={{ display: 'flex', width: "100%", justifyContent: "center", gap: "10px" }}>
                                                    <div className="mb-3 col-md-3 form-floating p-1" >
                                                        <Autocomplete
                                                            fullWidth
                                                            // id="floatingSelect"
                                                            options={socialWorkerList}
                                                            getOptionLabel={(option) => option?.name[0]?.given + ' ' + option?.name[0]?.family}
                                                            onChange={(event, value) => handleAutocompleteChange2(event, value, index)}
                                                            value={socialWorkerList.find((item: any) => item.id === addField.staff) || null}
                                                            renderInput={(params) => <TextField {...params} label="Rounding Staff" variant="standard" />}
                                                        />
                                                        {/* <select className="form-select" id="floatingSelect" style={{ fontSize: '12px', width: "100%" }} onChange={(e: any) => {
                                                            let data = [...slotRegister2];
                                                            data[index].staff = e.target.value;
                                                            setSlotRegister2(data)
                                                        }} value={addField.staff}>
                                                            <option value="">-select-</option>
                                                            {
                                                                socialWorkerList?.map((item: any) => {
                                                                    return (
                                                                        <option value={item?.id}>{item?.name[0]?.given + ' ' + item?.name[0]?.family}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                        <label style={{ fontSize: '12px' }} htmlFor='floatingSelect'>Rounding Staff</label> */}
                                                    </div>
                                                    <div>
                                                        All Rooms
                                                        <input type="checkbox" id="checkbox" name="checkbox" checked={addField.all} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleCheck1(e, index, slotRegister2, setSlotRegister2)} />
                                                    </div>
                                                    <div className="form-floating mb-3 col-md-2 p-1" >
                                                        <select className="form-select" id="floatingSelect" disabled={addField.all} style={{ fontSize: '12px', width: "100%" }} onChange={(e: any) => {
                                                            let data = [...slotRegister2];
                                                            data[index].startRoomNo = e.target.value;
                                                            setSlotRegister2(data)
                                                        }} value={addField.startRoomNo}>
                                                            <option value="">-select-</option>
                                                            {
                                                                roomNumbers?.map((item: any) => {
                                                                    return (
                                                                        <option value={item}>{item}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                        <label style={{ fontSize: '12px' }} htmlFor='floatingSelect'>Start Room</label>
                                                    </div>
                                                    <div className="form-floating mb-3 col-md-2 p-1">
                                                        <select className="form-select" id="floatingSelect" disabled={addField.all} style={{ fontSize: '12px', width: "100%" }} onChange={(e: any) => {
                                                            let data = [...slotRegister2];
                                                            data[index].endRoomNo = e.target.value;
                                                            setSlotRegister2(data)
                                                        }} value={addField.endRoomNo}>
                                                            <option value="">-select-</option>
                                                            {
                                                                roomNumbers?.map((item: any) => {
                                                                    return (
                                                                        <option value={item}>{item}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                        <label style={{ fontSize: '12px' }} htmlFor='floatingSelect'>End Room</label>
                                                    </div>
                                                    <div className="form-floating mb-3 col-md-2 p-1" >
                                                        {/* <select className="form-select" id="floatingSelect" style={{ fontSize: '12px', width: "100%", backgroundImage: 'none', paddingTop: '8px' }} onChange={(e: any) => {
                                                            let data = [...slotRegister2];
                                                            data[index].deviceId = e.target.value;
                                                            setSlotRegister2(data);
                                                        }} value={addField.deviceId} disabled>

                                                            <option value="">-select-</option>
                                                            {
                                                                options?.map((item: any) => {
                                                                    return (
                                                                        <option key={item.id} value={item.deviceId}>{item.deviceId}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select> */}
                                                        <div style={{ fontFamily: "poppins", fontSize: '10px', fontWeight: 600 }}><strong>Device ID : </strong><br/>{addField?.deviceId ? addField?.deviceId : 'N/A'}</div>
                                                        <div style={{ fontFamily: "poppins", fontSize: '10px', fontWeight: 600 }}><strong>Location : </strong><br/>{addField?.location ? addField?.location : 'N/A'}</div>
                                                        
                                                    </div>

                                                    <div style={{ position: 'relative', left: '5px', top: "4px" }} role='button'>{slotRegister2?.map((addField, index) => (
                                                        slotRegister2.length - 1 === index &&
                                                        <div>
                                                            <i style={{ backgroundColor: "#d7dee5", borderRadius: "4px" }} onClick={handleFieldAdd2} className="material-icons">add</i>
                                                        </div>

                                                    ))}{slotRegister2.length !== 1 && (
                                                        <div>
                                                            <i style={{ backgroundColor: "#d7dee5", borderRadius: "4px" }} onClick={() => handleFieldRemove2(index)} className="material-icons">remove</i>

                                                        </div>

                                                    )}</div>
                                                </div>
                                            </div>

                                        )))}
                                    </div>
                                </div>
                            </div>
                            <div >
                                <div  >
                                    <div >
                                        {(slotRegister3?.map((addField, index) => (
                                            <div key={index} >
                                                <div style={{ display: "flex", paddingLeft: "134px", gap: "10px", flexDirection: "row-reverse", justifyContent: "flex-end" }}>
                                                    {index === 0 && <>
                                                        <div>
                                                            <input type="text" style={{ fontSize: '15px', borderStyle: "none" }} value={selectedTab === "Shift-A" ? slot3StartTime + "-" + slot3EndTime : selectedTab === "Shift-B" ? Bslot3StartTime + "-" + Bslot3EndTime : Cslot3StartTime + "-" + Cslot3EndTime} id="floatingEndTime" disabled placeholder='end time' />
                                                        </div>
                                                        <div style={{ lineHeight: 2.7, fontWeight: 600, fontFamily: "poppins" }} >Slot 3 ---</div>
                                                    </>}
                                                </div>
                                                <div style={{ display: 'flex', width: "100%", justifyContent: "center", gap: "10px" }}>
                                                    <div className="mb-3 col-md-3 form-floating p-1" >
                                                        <Autocomplete
                                                            fullWidth
                                                            // id="floatingSelect"
                                                            options={socialWorkerList}
                                                            getOptionLabel={(option) => option?.name[0]?.given + ' ' + option?.name[0]?.family}
                                                            onChange={(event, value) => handleAutocompleteChange3(event, value, index)}
                                                            value={socialWorkerList.find((item: any) => item.id === addField.staff) || null}
                                                            renderInput={(params) => <TextField {...params} label="Rounding Staff" variant="standard" />}
                                                        />
                                                        {/* <select className="form-select" id="floatingSelect" style={{ fontSize: '12px', width: "100%" }} onChange={(e: any) => {
                                                            let data = [...slotRegister3];
                                                            data[index].staff = e.target.value;
                                                            setSlotRegister3(data)
                                                        }} value={addField.staff}>
                                                            <option value="">-select-</option>
                                                            {
                                                                socialWorkerList?.map((item: any) => {
                                                                    return (
                                                                        <option value={item?.id}>{item?.name[0]?.given + ' ' + item?.name[0]?.family}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                        <label style={{ fontSize: '12px' }} htmlFor='floatingSelect'>Rounding Staff</label> */}
                                                    </div>
                                                    <div>
                                                        All Rooms
                                                        <input type="checkbox" id="checkbox" name="checkbox" checked={addField.all} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleCheck2(e, index, slotRegister3, setSlotRegister3)} />
                                                    </div>
                                                    <div className="form-floating mb-3 col-md-2 p-1">
                                                        <select className="form-select" id="floatingSelect" disabled={addField.all} style={{ fontSize: '12px', width: "100%" }} onChange={(e: any) => {
                                                            let data = [...slotRegister3];
                                                            data[index].startRoomNo = e.target.value;
                                                            setSlotRegister3(data)
                                                        }} value={addField.startRoomNo}>
                                                            <option value="">-select-</option>
                                                            {
                                                                roomNumbers?.map((item: any) => {
                                                                    return (
                                                                        <option value={item}>{item}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                        <label style={{ fontSize: '12px' }} htmlFor='floatingSelect'>Start Room</label>
                                                    </div>
                                                    <div className="form-floating mb-3 col-md-2 p-1">
                                                        <select className="form-select" id="floatingSelect" disabled={addField.all} style={{ fontSize: '12px', width: "100%" }} onChange={(e: any) => {
                                                            let data = [...slotRegister3];
                                                            data[index].endRoomNo = e.target.value;
                                                            setSlotRegister3(data)
                                                        }} value={addField.endRoomNo}>
                                                            <option value="">-select-</option>
                                                            {
                                                                roomNumbers?.map((item: any) => {
                                                                    return (
                                                                        <option value={item}>{item}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                        <label style={{ fontSize: '12px' }} htmlFor='floatingSelect'>End Room</label>
                                                    </div>
                                                    <div className="mb-3 col-md-2 p-1">
                                                        {/* <select className="form-select" id="floatingSelect" style={{ fontSize: '12px', width: "100%", backgroundImage: 'none', paddingTop: '8px' }} onChange={(e: any) => {
                                                            let data = [...slotRegister3];
                                                            data[index].deviceId = e.target.value;
                                                            setSlotRegister3(data);
                                                        }} value={addField.deviceId}
                                                            disabled
                                                        >
                                                            <option value="">-select-</option>
                                                            {
                                                                options?.map((item: any) => {
                                                                    return (
                                                                        <option key={item.id} value={item.deviceId}>{item.deviceId}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select> */}
                                                        <div style={{ fontFamily: "poppins", fontSize: '10px', fontWeight: 600 }}><strong>Device ID : </strong><br/>{addField?.deviceId ? addField?.deviceId : 'N/A'}</div>
                                                        <div style={{ fontFamily: "poppins", fontSize: '10px', fontWeight: 600 }}><strong>Location : </strong><br/>{addField?.location ? addField?.location : 'N/A'}</div>
                                                    </div>
                                                    <div style={{ position: 'relative', left: '5px', top: "4px" }} role='button'>{slotRegister3?.map((addField, index) => (
                                                        slotRegister3.length - 1 === index &&
                                                        <div>
                                                            <i style={{ backgroundColor: "#d7dee5", borderRadius: "4px" }} onClick={handleFieldAdd3} className="material-icons">add</i>
                                                        </div>

                                                    ))}{slotRegister3.length !== 1 && (
                                                        <div >
                                                            <i style={{ backgroundColor: "#d7dee5", borderRadius: "4px" }} onClick={() => handleFieldRemove3(index)} className="material-icons">remove</i>

                                                        </div>

                                                    )}</div>
                                                </div>
                                            </div>

                                        )))}
                                    </div>
                                </div>
                                <div>
                                    <div >
                                        {(slotRegister4?.map((addField, index) => (
                                            <div key={index} >
                                                <div style={{ display: "flex", paddingLeft: "134px", gap: "10px", flexDirection: "row-reverse", justifyContent: "flex-end" }} >
                                                    {index === 0 && <>
                                                        <div>
                                                            <input type="text" style={{ fontSize: '15px', borderStyle: "none" }} value={selectedTab === "Shift-A" ? slot4StartTime + "-" + slot4EndTime : selectedTab === "Shift-B" ? Bslot4StartTime + "-" + Bslot4EndTime : Cslot4StartTime + "-" + Cslot4EndTime} id="floatingEndTime" disabled placeholder='end time' />
                                                        </div>
                                                        <div style={{ lineHeight: 2.7, fontWeight: 600, fontFamily: "poppins" }}>Slot 4 ---</div></>}
                                                </div>
                                                <div style={{ display: 'flex', width: "100%", justifyContent: "center", gap: "10px" }}>
                                                    <div className="mb-3 col-md-3 form-floating p-1">
                                                        <Autocomplete
                                                            fullWidth
                                                            // id="floatingSelect"
                                                            options={socialWorkerList}
                                                            getOptionLabel={(option) => option?.name[0]?.given + ' ' + option?.name[0]?.family}
                                                            onChange={(event, value) => handleAutocompleteChange4(event, value, index)}
                                                            value={socialWorkerList.find((item: any) => item.id === addField.staff) || null}
                                                            renderInput={(params) => <TextField {...params} label="Rounding Staff" variant="standard" />}
                                                        />
                                                        {/* <select className="form-select" id="floatingSelect" style={{ fontSize: '12px', width: "100%" }} onChange={(e: any) => {
                                                            let data = [...slotRegister4];
                                                            data[index].staff = e.target.value;
                                                            setSlotRegister4(data)
                                                        }} value={addField.staff}>
                                                            <option value="">-select-</option>
                                                            {
                                                                socialWorkerList?.map((item: any) => {
                                                                    return (
                                                                        <option value={item?.id}>{item?.name[0]?.given + ' ' + item?.name[0]?.family}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                        <label style={{ fontSize: '12px' }} htmlFor='floatingSelect'>Rounding Staff</label> */}
                                                    </div>
                                                    <div>
                                                        All Rooms
                                                        <input type="checkbox" id="checkbox" name="checkbox" checked={addField.all} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleCheck3(e, index, slotRegister4, setSlotRegister4)} />
                                                    </div>
                                                    <div className="form-floating mb-3 col-md-2 p-1" >
                                                        <select className="form-select" id="floatingSelect" disabled={addField.all} style={{ fontSize: '12px', width: "100%" }} onChange={(e: any) => {
                                                            let data = [...slotRegister4];
                                                            data[index].startRoomNo = e.target.value;
                                                            setSlotRegister4(data)
                                                        }} value={addField.startRoomNo}>
                                                            <option value="">-select-</option>
                                                            {
                                                                roomNumbers?.map((item: any) => {
                                                                    return (
                                                                        <option value={item}>{item}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                        <label style={{ fontSize: '12px' }} htmlFor='floatingSelect'>Start Room</label>
                                                    </div>
                                                    <div className="form-floating mb-3 col-md-2 p-1" >
                                                        <select className="form-select" id="floatingSelect" disabled={addField.all} style={{ fontSize: '12px', width: "100%" }} onChange={(e: any) => {
                                                            let data = [...slotRegister4];
                                                            data[index].endRoomNo = e.target.value;
                                                            setSlotRegister4(data)
                                                        }} value={addField.endRoomNo}>
                                                            <option value="">-select-</option>
                                                            {
                                                                roomNumbers?.map((item: any) => {
                                                                    return (
                                                                        <option value={item}>{item}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                        <label style={{ fontSize: '12px' }} htmlFor='floatingSelect'>End Room</label>
                                                    </div>
                                                    <div className="form-floating mb-3 col-md-2 p-1">
                                                        {/* <select className="form-select" id="floatingSelect" style={{ fontSize: '12px', width: "100%", backgroundImage: 'none', paddingTop: '8px' }} onChange={(e: any) => {
                                                            let data = [...slotRegister4];
                                                            data[index].deviceId = e.target.value;
                                                            setSlotRegister4(data);
                                                        }} value={addField.deviceId} disabled>
                                                            <option value="">-select-</option>
                                                            {
                                                                options?.map((item: any) => {
                                                                    return (
                                                                        <option key={item.id} value={item.deviceId}>{item.deviceId}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select> */}
                                                        <div style={{ fontFamily: "poppins", fontSize: '10px', fontWeight: 600 }}><strong>Device ID : </strong><br/>{addField?.deviceId ? addField?.deviceId : 'N/A'}</div>
                                                        <div style={{ fontFamily: "poppins", fontSize: '10px', fontWeight: 600 }}><strong>Location : </strong><br/>{addField?.location ? addField?.location : 'N/A'}</div>
                                                        
                                                    </div>
                                                    <div style={{ position: 'relative', left: '5px', top: "4px" }} role='button'>{slotRegister4?.map((addField, index) => (
                                                        slotRegister4.length - 1 === index &&
                                                        <div>
                                                            <i style={{ backgroundColor: "#d7dee5", borderRadius: "4px" }} onClick={handleFieldAdd4} className="material-icons">add</i>
                                                        </div>

                                                    ))}{slotRegister4.length !== 1 && (
                                                        <div >
                                                            <i style={{ backgroundColor: "#d7dee5", borderRadius: "4px" }} onClick={() => handleFieldRemove4(index)} className="material-icons">remove</i>

                                                        </div>

                                                    )}
                                                    </div>
                                                </div>
                                            </div>

                                        )))}
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px", paddingBottom: "10px", paddingTop: "10px", borderTop: "2px groove", backgroundColor: "white", paddingRight: "20px" }} >
                                <Button label="Cancel" severity="secondary" style={{ color: '#000', backgroundColor: '#94a0b7', fontSize: '12px', fontWeight: 'bold', borderStyle: "none" }} onClick={handleCancel}></Button>
                                <Button label="Save Changes" style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold', borderStyle: "none" }} onClick={handleSubmit}></Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ErrorPopup
                closePopup={closePopup}
                errorMsg={errorMsg}
                open={isOpen}
            />
        </React.Fragment>
    );
};

export default Q15StaffConfiguration;