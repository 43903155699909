import axios from "axios"
import { endLoading, getPatientgetSuccess, getPSConfigSuccess, getRNInchargeListSuccess, getSocialWorkerListSuceess, getTotalElementSuccess, startLoading } from "./reducer"
import { baseURL, successCode } from '../../configuration/url'
import { authorize } from "../../helpers/common"

export const getPSConfigByDate = async (dispatch: any, date: any, organization: any) => {
    dispatch(startLoading())
    const { header1 } = authorize();
    try {
        const response = await axios.get(`${baseURL}/PSConfig/getByDate/${date}/${organization}`,{headers: header1})
        if (response.data.message.code === successCode) {
            dispatch(getPSConfigSuccess(response.data.data.shift))
        } else {
            dispatch(endLoading())
            dispatch(getPSConfigSuccess([]))

        }
    } catch (error) {
        dispatch(endLoading())
        dispatch(getPSConfigSuccess([]))
    }
}

export const getAllRNIncharge = async (dispatch: any, role: string, orgId: string) => {
    dispatch(startLoading())
    const { header1 } = authorize();
    try {
        const response = await axios.get(`${baseURL}/staff/role/${role}/${orgId}`,{headers: header1})
        if (response.data.message.code === successCode) {
            dispatch(endLoading())
        dispatch(getRNInchargeListSuccess(response.data.data))
        } else {
        dispatch(endLoading())
        dispatch(getRNInchargeListSuccess([]))
        }
    } catch (error) {
        dispatch(endLoading())
        dispatch(getRNInchargeListSuccess([]))
    }
}

export const getAllSocialWorkers = async (dispatch: any, role: string, orgId: string) => {
    dispatch(startLoading())
    const { header1 } = authorize();
    try {
        const response = await axios.get(`${baseURL}/staff/role/${role}/${orgId}`,{headers: header1})
        if (response.data.message.code === successCode) {
            dispatch(endLoading())
        dispatch(getSocialWorkerListSuceess(response.data.data))
        } else {
            dispatch(endLoading())
            dispatch(getSocialWorkerListSuceess([]))
        }
    } catch (error) {
        dispatch(endLoading())
        dispatch(getSocialWorkerListSuceess([]))
    }
}

export const getPatientDataByDate = async (dispatch: any, date: any, orgId: string, pageNo: number) => {
    const { header1 } = authorize()
    dispatch(startLoading())
    try {
        const response = await axios.get(`${baseURL}/sosAlarm/sosAlarmByDateAndOrganization?date=${date}&organization=${orgId}&page=${pageNo}&size=8`, {headers: header1})
        if (response.data.message.code === successCode) {
            dispatch(getPatientgetSuccess(response.data.data.content))
            dispatch(getTotalElementSuccess(response.data.data.totalElements))
            dispatch(endLoading())
        } else {
            dispatch(getPatientgetSuccess([]))
            dispatch(getTotalElementSuccess(0))
            console.error('An error occurred while fetching records.');
            dispatch(endLoading())
        }
    } catch (error: any) {
        dispatch(endLoading())
        console.error("API Error:", error)
    }
}