import { Autocomplete, TextField } from '@mui/material';
import { Button } from 'primereact/button';
import React, { useEffect, useState } from 'react';
import {
  Modal, ModalFooter, ModalBody, ModalHeader
} from "reactstrap";
import { HttpLogin } from '../../utils/Http';
import { baseURL, successCode } from '../../configuration/url';
import { useDispatch, useSelector } from 'react-redux';
import { authorize } from '../../helpers/common';
import { toast } from 'react-toastify';
import axios from 'axios';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import moment from 'moment';
import Loader from "../../components/loader/Loader";
interface AddOneOneProps {
  IsOpen: any;
  Close: any;
  errors: any;
  setErrors: any;
  setPatientDate: any;
  selectedDate: any;
  selectedTab: any;
  shifts: any;
  forDate: any;
  patientDropdown: any;
}

const AddOneToOne: React.FC<AddOneOneProps> = ({
  IsOpen, Close, errors, setErrors, selectedDate,
  setPatientDate, selectedTab, shifts, forDate, patientDropdown
}) => {
  const { organization } = useSelector((state: any) => state.Login);
  const { staffDropdown } = useSelector((state: any) => state.Staff);

  const initFormData = {
    date: "",
    endTime: "",
    id: "",
    oneToOneObserver: "",
    oneToOneObserverName: "",
    organization: "",
    patientId: "",
    patientName: "",
    patientIncharge: "",
    patientInchargeName: "",
    prescribedby: "",
    prescribedbyName: "",
    startTime: ""
  };

  const [Addpatient, setAddpatient] = useState(initFormData);
  const dispatch = useDispatch<any>();
  const [Loading, setLoading] = useState<boolean>(false)

  const handleInputChangeadd = (event: any) => {
    const { id, value } = event;
    setAddpatient({ ...Addpatient, [id]: moment(value?.$d).format('HH:mm') });
    setErrors({ ...errors, [id]: !moment(value?.$d).format('HH:mm') });
  };

  const handleAutocompleteChange = (id: string, value: any, name: string) => {
    setAddpatient({
      ...Addpatient,
      [id]: value ? value.id : null,
      [name]: value ? `${value.name}` : ''
    });
    setErrors({ ...errors, [id]: !value });
  };

  const handleClose = () => {
    Close();
    setErrors({});
    setAddpatient(initFormData);
  };

  const fetchPatientData = async () => {
    setLoading(true);
    const date = selectedDate.getDate().toString().padStart(2, '0');
    const year = selectedDate.getFullYear();
    const month = (selectedDate.getMonth() + 1).toString().padStart(2, '0');
    const { header1 } = authorize();

    HttpLogin.axios().get(`${baseURL}/oneToOne/oneToOnePatientList?date=${year}${month}${date}&organization=${organization}&shiftName=${selectedTab}`, { headers: header1 })
      .then((response) => {
        if (response.data.message.code === successCode) {
          setPatientDate(response.data.data);
          setLoading(false);
        } else {
          setPatientDate([""]);
        }
      });
  };

  useEffect(() => {
    fetchPatientData();
  }, []);

  const handleSaveClick = async (event: any) => {
    event.preventDefault();
    try {
      let newErrors = {
        patientId: !Addpatient.patientId,
        startTime: !Addpatient.startTime,
        endTime: !Addpatient.endTime,
        prescribedby: !Addpatient.prescribedby,
        patientIncharge: !Addpatient.patientIncharge,
      };
      setErrors(newErrors);
      const hasErrors = Object.values(newErrors).some(error => error);
      if (hasErrors) {
        return;
      }

      const { header1 } = authorize();
      const bodyData = {
        date: forDate,
        id: "",
        organization,
        pid: Addpatient.patientId,
        shift: shifts?.map((k: any) => ({
          record: k.shift[0].record?.map((l: any) => ({
            distance: "",
            endTime: Addpatient.endTime,
            note: [{ note: "", time: "" }],
            staffId: Addpatient.prescribedby,
            startTime: Addpatient.startTime,
          })),
          shiftIncharge: Addpatient.patientIncharge,
          shiftName: "Shift-A",
          shiftTime: "",
        })),
      };

      switch (selectedTab) {
        case "Shift-A":
          bodyData.shift.forEach((shift: any) => shift.shiftName = "Shift-A");
          break;
        case "Shift-B":
          bodyData.shift.forEach((shift: any) => shift.shiftName = "Shift-B");
          break;
        case "Shift-C":
        default:
          bodyData.shift.forEach((shift: any) => shift.shiftName = "Shift-C");
          break;
      }

      const response = await axios.post(`${baseURL}/oneToOne/register`, bodyData, { headers: header1 });
      if (response.data.message.code === successCode) {
        dispatch({ type: 'SET_FORM_VALUES', payload: response.data.data });
        toast.success(response.data.message.description);
        handleClose();
        fetchPatientData();
      } else {
        toast.error("Login failed: " + response.data.message.description);

      }
    } catch (error) {
      toast.error("An error occurred during register.");
    }
  };

  return (
    <div>
                      {Loading && <Loader/>}
      <Modal isOpen={IsOpen} centered size="lg">
        <div className="d-flex align-items-center justify-content-center vh-90">
          <div className="row w-100">
            <div className="container col-md-12">
              <ModalHeader toggle={handleClose}>Add 1:1 Routine</ModalHeader>
              <ModalBody
                style={{
                  maxHeight: "65vh",
                  overflowY: "auto",
                }}
              >
                <div className="row ">
                  <div className="col-12">
                    {/* <label>Select Patient Name</label> */}
                    <Autocomplete
                      id="patientId"
                      options={patientDropdown}
                      getOptionLabel={(option) => `${option.name}`}
                      value={
                        patientDropdown?.find(
                          (patient: any) => patient.id === Addpatient.patientId
                        ) || null
                      }
                      onChange={(event, value) => {
                        setAddpatient({ ...Addpatient, patientId: value ? value.id : null });
                        setErrors({ ...errors, patientId: false });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Patient Name"
                          variant="outlined"
                          label='Select Patient Name'
                          error={!!errors.patientId}
                          helperText={errors.patientId ? "Patient name is required" : ""}
                        />
                      )}
                    />
                  </div>
                  <div className='row pt-3'>
                    <div className='col-md-3'>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          value={Addpatient.startTime}
                          onChange={(value) => handleInputChangeadd({ id: "startTime", value })}
                          ampm={false}
                          format='HH:mm'
                          label='StartTime'
                          slotProps={{
                            textField: {
                              id: "startTime",
                              placeholder: " ",
                              style: {
                            
                                height: "57px",
                              },
                              error: !!errors.startTime,
                              helperText: errors.startTime || "",
                            },
                          }}
                        />
                      </LocalizationProvider>
                    </div>
                    <div className='col-md-3'>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          value={Addpatient.endTime}
                          onChange={(value) => { handleInputChangeadd({ id: "endTime", value }) }}
                          ampm={false}
                          format='HH:mm'
                          label='End Time'
                          slotProps={{
                            textField: {
                              id: "endTime",
                              placeholder: " ",
                              style: {
                                height: "57px",
                              },
                              error: !!errors.endTime,
                              helperText: errors.endTime || "",
                            },
                          }}
                        />
                      </LocalizationProvider>
                    </div>
                    <div className='col-md-6'>
                      <Autocomplete
                        id="prescribedby"
                        options={staffDropdown}
                        getOptionLabel={(option) => `${option.name}`}
                        value={
                          staffDropdown?.find(
                            (staff: any) => staff.id === Addpatient.prescribedby
                          ) || null
                        }
                        onChange={(event, value) =>
                          handleAutocompleteChange("prescribedby", value, "prescribedbyName")
                        }
                        style={{ width: '107%' }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select Staff Name"
                            variant="outlined"
                            label='Prescribed By'
                            error={!!errors.prescribedby}
                            helperText={errors.prescribedby ? "Prescribed by is required" : ""}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 mt-4 ">
                    <Autocomplete
                      id="patientIncharge"
                      options={staffDropdown}
                      getOptionLabel={(option) => `${option.name}`}
                      value={
                        staffDropdown?.find(
                          (staff: any) => staff.id === Addpatient.patientIncharge
                        ) || null
                      }
                      onChange={(event, value) =>
                        handleAutocompleteChange("patientIncharge", value, "patientInchargeName")
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Patient Incharge"
                          variant="outlined"
                          placeholder="Select Staff Name"
                          InputLabelProps={{
                            shrink: !!params.inputProps.value,
                          }}
                          error={!!errors.patientIncharge}
                          helperText={
                            errors.patientIncharge ? "Patient incharge is required" : ""
                          }
                        />
                      )}
                    />

                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <div className="d-flex gap-3">
                  <Button
                    label="Cancel"
                    severity="secondary"
                    style={{
                      color: "#000",
                      backgroundColor: "#94a0b7",
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}
                    onClick={handleClose}
                  ></Button>
                  <Button
                    label="Save Changes"
                    style={{
                      backgroundColor: "#0f3995",
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}
                    onClick={handleSaveClick}
                  ></Button>
                </div>
              </ModalFooter>
            </div>
          </div>
        </div>
      </Modal>

    </div>
  );
};

export default AddOneToOne;