import React, { useEffect, useRef, useState } from "react";
import LogoImg from "../../assets/images/mettlerTitle.png";
import Sos from "../../assets/images/sos.png";
import siren from "../../assets/images/siren-on1.png";
import Triggered from "../../assets/images/sosTriggered.png";
import { Avatar, Box, Dialog, DialogContent, DialogContentText, DialogTitle, IconButton, Modal, Tooltip } from "@mui/material";
import Card from "../../components/card";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { baseURL, successCode } from "../../configuration/url";
import { FaBed, FaTimesCircle } from "react-icons/fa";
import { RiAlarmWarningFill } from "react-icons/ri";
import "./patientDisplay.css";
import Slide from "./customslider";
import { grey, primarybg } from "../../common/primary";
import { setCurrentDateTime } from "../../slices/LargeScreen/reducer";
import { getApi, getDevices } from "../../slices/LargeScreen/thunk";
import { getAllStaffLS } from "../../slices/thunk";
import { FiMinusCircle } from "react-icons/fi";
import { GoPlusCircle } from "react-icons/go";
import moment from "moment";
import { CloseTwoTone } from "@mui/icons-material";
const PatientDisplay = () => {
  const fullscreenRef = useRef<HTMLDivElement>(null);
  const { organization, jwt } = useSelector((state: any) => state.Login);
  const { devices, orgTimeZone, patientData, orgName } = useSelector((state: any) => state.LargeScreen)
  const { staffData1 } = useSelector((state: any) => state.Staff);
  const staffData = staffData1?.staffData;
  // const [currentDateTime, setCurrentDateTime] = useState('');
  // const [orgName, setOrgName] = useState('')
  // const [orgTimeZone, setOrgTimeZone] = useState('')
  const [open, setOpen] = useState(false);
  const handleTrigger = () => setOpen(true);
  const [modalopen, setModalopen] = useState<boolean>(false)
  const {currentDateTime} = useSelector((state: any) => state.LargeScreen)
  const formattedDate = moment(currentDateTime, 'MM/DD/YYYY, hh:mm:ss A').format('YYYYMMDD');
  const [stafHistory, setStaffHistory] = useState<any>()
  const [historyModal, setHistoryModal] = useState<boolean>(false)
  const [LorgName, setLorgName] = useState('')
  const handleOnclick = (staff: any) => {
    console.log(staff, 'stafffffff')
    setStaffHistory(staff)
    setHistoryModal(true)
  }
  const handleCloseModal = () => {
    setStaffHistory('')
    setHistoryModal(false)
  } 
  useEffect(() => {
    sessionStorage.setItem('jwt', jwt)
    sessionStorage.setItem('authStaff', 'verified')
  }, [])

  useEffect(() => {
    getAllStaffLS(dispatch, formattedDate, organization)
  }, [organization])

  const handleFullscreen = (event: any) => {
    const elem = fullscreenRef.current;
    if (elem && !open) {
      const requestFullscreen =
        elem.requestFullscreen ||
        (elem as any).mozRequestFullScreen ||
        (elem as any).webkitRequestFullscreen ||
        (elem as any).msRequestFullscreen;
      if (requestFullscreen) {
        requestFullscreen.call(elem);
      } else {
        console.error("Fullscreen is not supported");
      }
    }
  };
  // const [patientData, setPatientData] = useState([]);
  const [alarmHistory, setAlarmHistory] = useState([]);
  const [alarm, setAlarm] = useState(false);
  const [toggleValue, setToggleValue] = useState(false);
  // const [alarmstatus, setAlarmStatus] = useState<boolean>(false)
  const [fullAlarm, setFullAlaram] = useState<string>('0')

  // useEffect(() => {
  //   // const getApi = async () => {
  //   //   const response = await axios.get(
  //   //     `${baseURL}/patient/getPatientDataForSlide?organization=${organization}`
  //   //   );
  //   //   if (response.data.message.code === successCode) {
  //   //     setPatientData(response.data.data);
  //   //     setOrgName(response.data.data[0].organization)
  //   //     localStorage.setItem('LOrgname',response.data.data[0].organization)
  //   //     localStorage.setItem('LTimezone',response.data.data[0].timeZone)
  //   //     setOrgTimeZone(response.data.data[0].timeZone)
  //   //   } else {
  //   //     setPatientData([]);
  //   //   }
  //   // };
  //   getApi(dispatch, organization);
  // }, [organization])
  const [available, setAvailable] = useState<any>("");
  const [staffName, setStaffName] = useState<any>("");
  const [occupied, setOccupied] = useState<any>("");
  const [location, setLocation] = useState<any>("");
  // const [devices, setDevices] = useState([])
  const dispatch = useDispatch<any>()

    useEffect(() => {
    // const getDevices = async () => {
    //   const response = await axios.get(`${baseURL}/siren/getAllDevices?organization=${organization}`)
    //   if (response.data.success) {
    //     setDevices(response.data.result.devices)
    //   } else (
    //     setDevices([])
    //   )
    // }
    getDevices(dispatch, organization)
  }, [organization])

  useEffect(() => {
    const getbedCount = async () => {
      const response = await axios.get(
        `${baseURL}/Q15Bed/getByAvailable/${organization}`
      );
      const res = await axios.get(
        `${baseURL}/Q15Bed/getByOccupied/${organization}`
      );
      setAvailable(response.data.data.length);
      setOccupied(res.data.data.length);
    };
    getbedCount();
  }, [organization]);
  // useEffect(() => {
  //   const getAlarm = async () => {
  //     try {
  //       const response = await axios.get(`${baseURL}/alarm/get?organization=${organization}`);
  //       if (response.data && response.data.alarm) {
  //         setAlarm(response.data.alarm);
  //         setStaffName(response.data.staffName)
  //         setToggleValue(response.data.alarm);
  //         getHistory()
  //       } else {
  //         setAlarm(response.data.alarm);
  //         setStaffName('')
  //         setToggleValue(response.data.alarm);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching alarm:", error);
  //     }
  //   };
  //   getAlarm();
  //   const intervalId = setInterval(getAlarm, 2000);
  //   return () => clearInterval(intervalId);
  // }, []);
  
  // useEffect(() => {
  //   if (open) return
  //   const getGateway = async () => {
  //     if (open) {
  //       clearInterval(intervalId);
  //       console.log('1')
  //     }
  //     if (staffData && staffData.length > 0) {
  //       const Devices = staffData
  //         .map((staff: any) => staff.sosDevice)
  //         .filter((device: string | undefined) => device && device.trim() !== "");
  //       if (Devices.length > 0) {
  //         const devicesFormatted = `${Devices.join(",")}`;
  //         try {
  //           const response = await axios.get(`${baseURL}/alarm/getSosDevicePressedStaffAndLocation?deviceIds=${devicesFormatted}&organization=${organization}`);
            
  //           if (response?.data.message.code === successCode) {
  //             if(response.data.data.length > 0){
  //               setStaffName(response.data.data[0].staffName || "");
  //               setLocation(response.data.data[0].location || "");
  //               handleTrigger()
  //             } 
  //           } 
  //         } catch (error) {
  //           console.error(`Error with device(s) ${devicesFormatted}:`, error);
  //         }
  //       } else {
  //         console.log('No valid devices found');
  //       }
  //     } else {
  //       console.log('staffData is empty or undefined');
  //     }
  //   };

  //   getGateway();
  //   const intervalId = setInterval(getGateway, 2000);
  //   return () => clearInterval(intervalId);
  // }, [open]);
  const handleDevices = () => {
    if (staffData && staffData.length > 0) {
      const Devices = staffData
        .map((staff: any) => staff.sosDevice)
        .filter((device: string | undefined) => device && device.trim() !== "");
      if (Devices.length > 0) {
        return`${Devices.join(",")}`;
      }
    }
    return []
  }

  useEffect(() => {  
  const getGateway = async () => {
    if (staffName.length > 0 ) {
      clearInterval(intervalId)
      return
    }    
    if (staffData && staffData.length > 0) {
        const devicesFormatted = handleDevices()
      if (devicesFormatted.length > 0) {
        try {
          // const response = await axios.get(`${baseURL}/alarm/getSosDevicePressedStaffAndLocation?deviceIds=${devicesFormatted}&organization=${organization}`);
          const response = await axios.post(`${baseURL}/siren/siren-on_singleAPI?deviceId=${devicesFormatted}&organization=${organization}`)
          if (response?.data?.message?.code === successCode) {
            if (response.data.data) {
              setStaffName(response.data.data || "");
              setToggleValue(true);
              getHistory();
              setModalopen(true)
              setAlarm(true)
              setLocation(response.data.data[0].location || "");              
            }
          } else {
            setModalopen(false)
            setToggleValue(false);
            setStaffName("");
            setLocation("");
            setAlarm(false)
          }
        } catch (error) {
          console.error(`Error with device(s) ${devicesFormatted}:`, error);
        }
      } else {
        console.log('No valid devices found');
      }
    } else {
      console.log('staffData is empty or undefined');
    }
  }

  // getGateway();

  const intervalId = setInterval(getGateway, 2000);

  
  return () => clearInterval(intervalId);

}, [open, staffData, organization, staffName]);
  const getHistory = async () => {
    const response = await axios.get(
      `${baseURL}/alarm/getAlarmRecord?full=${fullAlarm}&organization=${organization}`
    );
    if (response.data.message.code === successCode) {
      setAlarmHistory(response.data.data);
    } else {
      setAlarmHistory([]);
    }
  };
  useEffect(() => {
    getHistory();
  }, [organization, fullAlarm]);
  const highRate = patientData?.filter(
    (patient: any) =>
      parseInt(patient.heartRate) > 100 || parseInt(patient.heartRate) < 60
  );
  const lowRate = patientData?.filter(
    (patient: any) =>
      parseInt(patient.heartRate) >= 60 && parseInt(patient.heartRate) <= 100
  );

  const hlength = highRate?.length;
  const llength = lowRate?.length;
  const plength = hlength + llength;
 
  const handleToggleChange = async (
    event: any
  ) => {
    const newValue = event?.target?.checked;
    setToggleValue(newValue);
    try {
      let devicesid: string[] = [];

      staffName?.forEach((item: any) => {
        if (Array.isArray(item.triggeredSirenIds)) {
          devicesid.push(...item.triggeredSirenIds);
        }
      });
      
      const devicesidString = devicesid.join(',')
      const response = await axios.post(`${baseURL}/siren/siren-off?sirenIds=${devicesidString}`)
      const resp = await axios.delete(`${baseURL}/beacon/deleteKBeaconDevice?deviceId=${devicesidString}`)
      if (response.status === 200 || resp.data.message.code === successCode) {
        setModalopen(false)
        setStaffName([])
        // handleClose();
        setOpen(false)
        setAlarm(false)
      } else {
        setModalopen(false)
        setStaffName([])
        // handleClose();
        setOpen(false);
        setAlarm(false)
      }
      
    } catch (error) {
      console.error("Error toggling switch:", error);
    }
  };
  // const handleToggleChangeWrapper = async (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   const newValue :any = event.target.checked;
  //   await handleToggleChange(event, newValue);
  //   try {
  //     const responseData = await axios.post('http://47.32.254.89:7000/api/alarm/off');
  //     if (responseData.data.success) {

  //     } else {
  //       console.error('response else from tua', responseData.data)
  //     }
  //   } catch (error) {
  //     console.error('Error:', error);
  //   }
  // };

  const handleClose = () => {
    setOpen(false);
    const value = !toggleValue;
    handleToggleChange(value);
  };
  const slides = [];
  const slideSize = 4; // Number of items per slide
  for (let i = 0; i < highRate?.length; i += slideSize) {
    const slideData = highRate?.slice(i, i + slideSize);
    const slide = (
      <div key={i}>
        <div style={{ display: "grid",  gridTemplateColumns: "25% 25% 25% 25%", height: "160px", width: "100%" }}>
          {slideData.map((item: any, index: any) => (
            <div key={index} className="mb-4 g-2" style={{ display: "flex", justifyContent: "flex-end"}} >
              <Card
                patientName={item.patientName}
                room={item.roomNo}
                bed={item.bedNo}
                heartRate={item.heartRate}
              />
            </div>
          ))}
        </div>
      </div>
    );
    slides.push(slide);
  }
  const lslides = [];
  const itemsPerSlide = 12;
  for (let i = 0; i < lowRate?.length; i += itemsPerSlide) {
    const slideData = lowRate?.slice(i, i + itemsPerSlide);
    const slide = (
      <div key={i}>
        <div style={{ display: "flex", flexDirection: "column", height: "480px" }}>
          {[0, 1, 2].map((rowIndex) => (
            <div key={rowIndex} style={{ display: "grid", gridTemplateColumns: "25% 25% 25% 25%", height: "33%", width: "100%" }}>
              {slideData.slice(rowIndex * 4, (rowIndex + 1) * 4).map((item: any, index: any) => (
                <div key={index} className="mb-4 g-2" style={{ flex: "1", display: "flex", justifyContent: "flex-end" }} >
                  <Card
                    // style={{width:"69%"}}
                    patientName={item.patientName}
                    room={item.roomNo}
                    bed={item.bedNo}
                    heartRate={item.heartRate}
                  />
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    );
    lslides.push(slide);
  }

  return (
    <div ref={fullscreenRef}>
      {/* <div
        className="row justify-content-around align-items-center"
        style={{ height: "50px", backgroundColor: primarybg }}
        onClick={handleFullscreen}
      >
        <div className="col-md-4 d-flex flex-column align-items-center">
          <p style={{ color: "#fff", fontWeight: "normal", margin: "0 0 0 10px" }}>
            Organization: {orgName}
          </p>
          <p style={{ color: "#fff", fontWeight: "normal", margin: "0 0 0 10px" }}>
            Date/Time: {currentDateTime}
          </p>
        </div>
        <div className="col-md-4 d-flex justify-content-center align-items-center">
          <img src={LogoImg} alt="Logo" className="logo-img" />
        </div>
        <div className="col-md-3"></div>
        <div className="col-md-1 d-flex align-items-center">
          <div className="">
            <Box>
              <Tooltip title="Account settings">
                <IconButton size="small">
                  <Avatar
                    sx={{ width: 32, height: 32, backgroundColor: "#9F9FA2" }}
                  ></Avatar>
                </IconButton>
              </Tooltip>
            </Box>
          </div>
        </div>
      </div> */}
      <div className="row mx-0" style={{marginTop:'60px', height: '92vh'}}>
        <div className="col-md-9" style={{ backgroundColor: "#0A2A7C" }}>
          <div style={{ paddingTop: 5 }}>
            <p style={{ color: "#fff", marginLeft: "30px" }}>
              Showing 1 to {hlength} of {plength} Patients
            </p>
          </div>
          <div >
            <Slide staffData={null}>
              {slides}
            </Slide>
          </div>
          <div style={{ paddingTop: 5 }}>
            <p style={{ color: "#fff", marginLeft: "30px" }}>
              Showing 1 to {llength} of {plength} Patients
            </p>
          </div>
          <div >
            <Slide staffData={null}>
              {lslides}
            </Slide>
          </div>
        </div>
        <div className="col-md-3 d-flex flex-column align-items-center" style={{ backgroundColor: "#4E6BB2" }}>
          <div className="row ">
            <div className="d-flex justify-content-center mt-2">
              {alarm ? (
                <img
                  src={Triggered}
                  alt="Logo"
                  className="logo-img"
                  style={{ width: "120px" }}
                  onClick={handleTrigger}
                />
              ) : (
                <img
                  src={Sos}
                  alt="Logo"
                  className="logo-img"
                  style={{ width: "120px" }}
                />
              )}
            </div>
          </div>
          <div className="row mt-2" style={{
            width: "80%",
            borderRadius: "5px",
            background:
              "linear-gradient(-166deg, rgb(158 214 255 / 18%) 0%, rgb(255 223 246 / 20%) 100%)",
            borderColor: "white",
            borderWidth: 0.5,
            borderStyle: "solid",
          }}>
            <div className="row">
              <div className="col-md-1"></div>
              <div className="col-md-10">
                <p
                  style={{
                    color: "white",
                    fontSize: "14px",
                    fontWeight: "bold",
                    fontStyle: "revert-layer",
                    borderRadius: "5px",
                    alignItems: 'center',
                    justifyContent: "start",
                    textAlign: "center",
                    paddingTop: "4px",
                    marginBottom: '2px', 
                  }}
                >
                  SOS calls
                </p>
                <hr
                  style={{
                    marginTop: '0px',
                    marginBottom: '8px',
                    borderTopColor: "#ffffff",
                    borderTopWidth: 0.5,
                  }}
                />
                </div>
            </div>
            <div className="hidden-scrollbar" style={{height: fullAlarm === '0' && alarmHistory?.length > 0  ? '118px' : '200px', overflowY: fullAlarm === '1' ? 'scroll' : 'hidden'}} >
              {/* {!loading && <img src="https://cdn.pixabay.com/animation/2023/11/30/10/11/10-11-02-622_512.gif" style={{}}/>} */}
              {alarmHistory?.length > 0 ? 
                alarmHistory?.map((item: any, index: any) => (
                  <div
                    key={item.id}
                    className="mb-2 justify-content-around align-items-center"
                    style={{
                      width: "100%",
                      height: "30px",
                      display: "flex",
                      flexDirection: "column",
                      borderRadius: 5,
                      backgroundColor: "#fff",
                      // paddingLeft: '2px',
                      // paddingRight: '2px',
                      cursor: 'pointer'
                      
                    }}
                    onClick={() => {
                      handleOnclick(item)
                    }}
                  >
                  <span
                    style={{
                      fontWeight: "bold",
                      // marginTop: "12px",
                      fontSize: "10px",
                    }}
                  >
                    {item?.triggeredStaffId ? item?.triggeredStaffId : item?.visitorName}
                  </span>
                    <span style={{ 
                      fontWeight: "bold", 
                      // marginTop: "12px", 
                      fontSize: "10px" 
                    }}>
                      {moment(item.triggeredTime, 'YYYYMMDDHHmmss').format('MMM DD, YYYY hh:mm A')}
                    </span>
                  </div>
                )) :

              <>
                  <div className="" style={{ color: 'white', display: 'flex', justifyContent: 'center'}}>
                    No Data Found
                  </div>            
              </>


              }
            </div>
            <div className="d-flex justify-content-end" style={{paddingBottom: '5px'}} >
                {fullAlarm  === '0'
                  ? 
                  <GoPlusCircle style={{color: '#ffffff', cursor: 'pointer'}} onClick={() => {
                    setAlarmHistory([])
                    setFullAlaram('1')
                  }} 
                  />
                  : 
                  <FiMinusCircle style={{color: '#ffffff', cursor: 'pointer'}} onClick={() => {
                    setAlarmHistory([])
                    setFullAlaram('0')
                  }} 
                  />                
                }
              </div>
          </div>
          <div className="row mt-2" style={{
            width: "80%",
            borderRadius: "5px",
            background:
              "linear-gradient(-166deg, rgb(158 214 255 / 18%) 0%, rgb(255 223 246 / 20%) 100%)",
            borderColor: "white",
            borderWidth: 0.5,
            borderStyle: "solid",
          }}>
            <div className="row">
              <div className="col-md-1"></div>
              <div className="col-md-10">
                <p
                  style={{
                    color: "white",
                    fontSize: "14px",
                    fontWeight: "bold",
                    fontStyle: "revert-layer",
                    borderRadius: "5px",
                    textAlign: "center",
                    alignItems: 'center',
                    justifyContent: "start",
                    paddingTop: "4px",
                    marginBottom: '2px',
                  }}
                >
                  Alarm device status
                </p><hr
                  style={{
                    marginTop: '0px',
                    marginBottom: '8px',
                    borderTopColor: "#ffffff",
                    borderTopWidth: 0.5,
                  }}
                /></div>
              <div className="col-md-1"></div>
            </div>
            <div style={{ display: "grid", gridTemplateColumns: "auto auto auto auto", marginBottom: "15px", gap: "4px" }} >
              {devices?.length > 0 ? devices.map((item: any, index: any) => 
                {if (item.data.msg !== 'token invalid') {
                  return (
                    <div key={index} style={{ backgroundColor: '#fff', alignItems: "flex-start", height: "40px", display: "flex", justifyContent: "center", padding: "0px 0px 0px 4px", borderRadius: "4px", flexDirection: "column" }}>
                      <img
                        src={siren}
                        alt={`Siren ${index + 1}`}
                        className="logo-img"
                        style={{ width: "15px", height: "15px" }}
                      />
                      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <p style={{ color: "#000", margin: 0, fontSize: '9px' }}>{item?.data?.result?.devices[0]?.name}</p>
                        <p style={{ color: item?.data?.result?.devices[0]?.online ? "green" : "red", margin: 0, fontSize: '9px' }}>{item?.data?.result && item?.data?.result?.devices[0]?.online ? 'Online' : 'Offline'}</p>
                      </div>
                    </div>
                  )

                } else {
                  return(<div>
                    <p style={{ color: "white", margin: 0, fontSize: '14px' }}>
                      No devices
                    </p>
                  </div>)
                }}
              
              )
                : <div>
                    <p style={{ color: "white", margin: 0, fontSize: '14px' }}>
                      No devices
                    </p>
                </div>
              
              }
            </div>
          </div>
          <div className="row mt-2 mb-2" style={{
            width: "80%",
            borderRadius: "5px",
            background:
              "linear-gradient(-166deg, rgb(158 214 255 / 18%) 0%, rgb(255 223 246 / 20%) 100%)",
            borderColor: "white",
            borderWidth: 0.5,
            borderStyle: "solid",
          }}>
            <div className="row">
              <div className="col-md-1"></div>
              <div className="col-md-10">
              <p
                  style={{
                    color: "white",
                    fontSize: "14px",
                    fontWeight: "bold",
                    borderRadius: "5px",
                    textAlign: "center",
                    alignItems: 'center',
                    justifyContent: "start",
                    paddingTop: "4px",
                    marginBottom: '2px',
                  }}
                >
                  SOS count
                </p><hr
                  style={{
                    marginTop: '0px',
                    marginBottom: '8px',
                    borderTopColor: "#ffffff",
                    borderTopWidth: 0.5,
                  }}
                /></div>
            </div>
            <div className="row px-3">
              <div
                className="d-flex align-items-start justify-content-center"
                style={{ flexDirection: "column", width: "65%" }}
              >
                <p
                  style={{
                    marginBottom: "0rem",
                    color: "white",
                    fontWeight: "500", 
                    fontSize:'14px'
                  }}
                >
                  Available Beds
                </p>
                <p style={{ color: "#2CDB72", fontWeight: "500", fontSize:'14px'}}>
                  {available}
                </p>
              </div>
              <IconButton
                style={{
                  width: "30%",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <FaBed size={"25px"} color="#2CDB72" />
              </IconButton>
            </div>
            <div className="row px-3">
              <div
                className="d-flex align-items-start justify-content-center"
                style={{ flexDirection: "column", width: "65%" }}
              >
                <p
                  style={{
                    marginBottom: "0rem",
                    color: "white",
                    fontWeight: "500",
                    fontSize:'14px'
                  }}
                >
                  Occupied Beds
                </p>
                <p style={{ color: "#CCD9A8", fontWeight: "500", fontSize:'14px' }}>
                  {occupied}
                </p>
              </div>
              <IconButton
                style={{
                  width: "30%",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <FaBed size={"25px"} color="#CCD9A8" />
              </IconButton>
            </div>
          </div>
        </div>
      </div>
      {toggleValue && modalopen && staffName?.length > 0 && (
        <Modal
          open={toggleValue}
          onClose={handleClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
          }}
        >
          <div
            style={{
              backgroundColor: "black",
              width: 600,
              height: 350,
              borderRadius: 8,
              padding: 10,
              display: "flex",
              flexDirection: "column",
              borderBottom: "30px solid #F04349",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                marginBottom: 10,
              }}
            >
              <IconButton style={{ color: "white" }} onClick={handleClose}>
                <FaTimesCircle size={17} />
              </IconButton>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <RiAlarmWarningFill style={{ color: "red", fontSize: 50 }} />
            </div>
            <p
              style={{
                fontFamily: "Poppins",
                fontSize: 20,
                fontWeight: "bold",
                textAlign: "center",
                color: "#fff",
              }}
            >
              Emergency
            </p>
            {Array.isArray(staffName) && staffName?.map((item: any) => (<p
              style={{
                fontFamily: "Poppins",
                fontSize: 14,
                textAlign: "center",
                color: "white",
              }}
            >
              {item?.staffName ? item?.staffName : item?.visitorName ? item?.visitorName :'N/A'} called for help - Go to   Location: {item?.location}
            </p>))
            }
            
            <div
              style={{
                display: "flex",
                justifyContent: 'center',
              }}
            >
              <label className="switch">
                <input
                  type="checkbox"
                  checked={toggleValue}
                  onChange={handleToggleChange}
                />
                <span className="slider round"></span>
              </label>
            </div>
          </div>
        </Modal>
      )}
      <Dialog open={historyModal} onClose={handleCloseModal}>
                <div style={{ height: '350px', width: '350px', backgroundColor: "black",fontFamily: "Poppins" }}>
                    <DialogTitle sx={{ height: '45px' }}>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div>
                                <p style={{ fontWeight: 'bold', color: 'red',fontFamily: "Poppins" }}>{LorgName}</p>
                            </div>
                            <IconButton
                                aria-label="close"
                                onClick={handleCloseModal}  
                                sx={{
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <CloseTwoTone />
                            </IconButton>
                        </div>
                    </DialogTitle>
                    <hr style={{color: 'white'}} />
                    <DialogContent>
                        <DialogContentText>
                            <div style={{ height: '190px', color: 'white', fontFamily: "Poppins" }}>
                              <p style={{color: 'white'}}>Staff SOS Call Details: </p>
                              <p style={{fontSize: '13px'}}><strong style={{color: 'gray'}}>{stafHistory?.visitorName ? 'Visitor Name: ' : 'Staff Name: '}</strong>{stafHistory?.triggeredStaffId ? stafHistory?.triggeredStaffId: stafHistory?.visitorName}</p>
                              <p style={{fontSize: '13px'}}><strong style={{color: 'gray'}}>Location: </strong>{stafHistory?.location && stafHistory?.location}</p>
                              <p style={{fontSize: '13px'}}><strong style={{color: 'gray'}}>Date: </strong>{stafHistory?.triggeredTime && moment(stafHistory?.triggeredTime, 'YYYYMMDDHHmmss').format('MM/DD/YYYY')}</p>
                              <p style={{fontSize: '13px'}}><strong style={{color: 'gray'}}>Time: </strong>{stafHistory?.triggeredTime && moment(stafHistory?.triggeredTime, 'YYYYMMDDHHmmss').format('hh:mm A')}</p>

                            </div>
                        </DialogContentText>
                    </DialogContent>
                </div>
            </Dialog>
    </div>
  );
};

export default PatientDisplay;